import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";

import { useGetSession } from "../../../../../../../hooks/session.hooks";
import { useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import {
	CreateSurvey,
	ModuleGroupingTypes,
	PageModuleType,
	SessionPanelLayoutsTypes,
	Survey,
	SurveyQuestion,
	SurveyType,
} from "../../../../../../../types/working-model";
import { toMap } from "../../../../../../../utils/utils";
import Icon, { COLORS, ICONS } from "../../../../../../general-ui/icon";
import TextInput, { Validation } from "../../../../../../general-ui/text-input/text";
import { TypographyItem } from "../../../../../../general-ui/typography-item/typography-item";
import WaitingIndicator from "../../../../../../general-ui/waiting-indicator/waiting-indicator";
import PanelBlock from "../../../../homepage/navigation/panel/panel-block";
import ToggleOption from "../../../../../../general-ui/switch/toggle-option";
import { useFinishNavigate, usePageModule } from "../../panel/hooks/panel.hooks";
import { INITIAL_SURVEY } from "../constants/engage";
import { EngagementErrorsTexts, ErrorsTypes } from "../types/engage";
import { useAddQuestion, useSaveSurvey, useSetWorkingSurveyTransKey } from "../hooks/engage.hooks";
import { setSurveyTitleHandler, toggleAllowRetakeHandler } from "../utils/engage-create.utils";
import { clearErrorsHandler, setErrorHandler, validateQuestion, validateSurvey } from "../utils/engage-validate.utils";
import CreateEngageContent from "./create-engage-content";
import StaggerChildren from "../../../../../../general-ui/animated/stagger-children";
import { useGetAdminUrl } from "../../../../../../../utils/admin-routing-utils";
import { SessionPanelMap } from "../../panel/session-panel-route-map";

interface CreateSurveyPanelProps {
	survey_type: SurveyType;
}

export default function CreateEngagePanel({ survey_type }: CreateSurveyPanelProps): JSX.Element {
	const initialSurvey: CreateSurvey = useMemo(() => {
		return {
			...INITIAL_SURVEY,
			survey_type,
			passing_threshold: 1,
			show_results: survey_type === SurveyType.poll,
		};
	}, [survey_type]);

	const pageModule = usePageModule();
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);

	const [workingSurvey, setWorkingSurvey] = useState<Survey | CreateSurvey | null>(pageModule?.modules?.[0] ?? initialSurvey);
	const [initiate, setInitiate] = useState(!pageModule?.modules?.[0]);
	const [workingQuestion, setWorkingQuestion] = useState<SurveyQuestion | null>(null);
	const [validationErrors, setValidationErrors] = useState<{ [key: string]: string } | null>(null);
	const [saving, setSaving] = useState(false);
	const { language } = useParams<{ language: string }>();
	const finish = useFinishNavigate();
	const adminPath = useGetAdminUrl();

	useEffect(() => {
		if (pageModule?.modules?.length) {
			setWorkingSurvey(pageModule.modules[0] as Survey);
		}
	}, [pageModule?.modules]);

	const extraModule = useMemo(() => {
		return workingSession?.module_grouping?.find((module) => module.type === ModuleGroupingTypes.Engage);
	}, [workingSession?.module_grouping]);

	const session = useGetSession();
	const modules = useMemo(() => {
		if (!extraModule || !session) return [];

		const moduleIds = extraModule.modules;
		const moduleMap = toMap('id', session.modules);

		return moduleIds.map(moduleId => moduleMap.get(moduleId));
	}, [extraModule, session]);

	const page_module = useMemo(() => {
		return modules?.find(module => module?.type === PageModuleType.survey);
	}, [modules]);

	const isQuiz = workingSurvey?.survey_type === SurveyType.quiz;

	// Checks if all the fields are added in except for "Not Saved"
	const disableSave = !!Object
		.keys(validationErrors || {})
		.filter(error =>
			validationErrors?.[error] !== EngagementErrorsTexts.NotSavedQuestion).length;


	const setError = setErrorHandler(setValidationErrors);
	const clearErrors = clearErrorsHandler(setValidationErrors);

	const addQuestion = useAddQuestion({
		initiate,
		isQuiz,
		workingQuestion,
		setWorkingQuestion,
		setWorkingSurvey,
		setError,
	});

	useEffect(() => {
		if (initiate) {
			const createQuestion = async () => {
				await addQuestion();
				setInitiate(false);
			};

			createQuestion();
		}
		// addQuestion removed from deps to prevent creation more then one question
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initiate]);

	const validateQuestionHandler = useCallback(() => {
		return validateQuestion({ question: workingQuestion, setError, isQuiz });
	}, [isQuiz, setError, workingQuestion]);

	const validateSurveyHandler = useCallback(() => {
		return validateSurvey({ survey: workingSurvey, setError });
	}, [setError, workingSurvey]);

	const setWorkingQuizCorrectThreshold = useCallback(({ target: { valueAsNumber } }: React.ChangeEvent<HTMLInputElement>) => {
		setWorkingSurvey((survey) => {
			if (!survey) return null;

			return {
				...survey,
				passing_threshold: valueAsNumber
			};
		});
	}, []);

	const setWorkingSurveyTransKey = useSetWorkingSurveyTransKey(setWorkingSurvey);
	const setWorkingSurveyTitle = setSurveyTitleHandler({ clearErrors, setWorkingSurveyTransKey });

	const toggleAllowRetake = toggleAllowRetakeHandler(setWorkingSurvey);

	const saveSurvey = useSaveSurvey({
		page_module,
		workingSurvey,
		isQuiz,
		validateQuestionHandler,
		validateSurveyHandler,
		setSaving,
		setWorkingSurvey
	});

	const handleCancel = useCallback(() => {
		setWorkingSurvey(null);
		setWorkingQuestion(null);
		finish(adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.Engage] }));
	}, [adminPath, finish]);

	return (
		<div className="session-panel create-survey">
			<StaggerChildren className="session-panel-content">
				<TextInput
					id="survey-title"
					label={'Title*'}
					placeholder={`Type ${survey_type} title`}
					valid={validationErrors?.[ErrorsTypes.SurveyTitle] ? Validation.error : Validation.normal}
					onChange={setWorkingSurveyTitle}
					key={"survey-title"}
					value={(workingSurvey?.name?.[language] as string) ?? workingSurvey?.name?.base ?? ''}
					isAdmin
				/>
				{validationErrors?.[ErrorsTypes.SurveyTitle] ? (
					<span key="session-error" className="session-panel-field-error admin-typography-label-2">
						{validationErrors?.[ErrorsTypes.SurveyTitle]}
					</span>
				) : <Fragment key="session-error" />}

				{workingSurvey?.survey_type === SurveyType.quiz ? (
					<TextInput
						key="correct-threshold"
						className="correct-threshold"
						label="Number correct required to pass"
						placeholder="Enter a number"
						onChange={setWorkingQuizCorrectThreshold}
						min={1}
						max={workingSurvey?.questions.length ?? 100}
						value={String(workingSurvey.passing_threshold ?? '1')}
						step={1}
						valid={workingSurvey.passing_threshold && workingSurvey.passing_threshold <= workingSurvey.questions.length ? Validation.normal : Validation.error}
						numeric
						isAdmin
					/>
				) : <Fragment key="correct-threshold" />}

				<PanelBlock key="panel-block">
					<ToggleOption
						id="allow-retakes"
						title="Allow retakes"
						tooltip="Allow retakes tooltip text"
						switchName="allowRetakes"
						toggleValue={!!workingSurvey?.allow_retake}
						onSwitchToggle={toggleAllowRetake}
					/>
				</PanelBlock>

				<TypographyItem key="label" tagName="p" className="session-panel-block-heading admin-typography-subtitle-1">
					Content
				</TypographyItem>

				{workingSurvey ? (
					<CreateEngageContent
						survey_type={survey_type}
						validationErrors={validationErrors}
						workingSurvey={workingSurvey}
						workingQuestion={workingQuestion}
						setWorkingQuestion={setWorkingQuestion}
						setWorkingSurvey={setWorkingSurvey}
						clearErrors={clearErrors}
						validateQuestionHandler={validateQuestionHandler}
						key="create-engage-content"
					/>
				) : <Fragment key="create-engage-content" />}

				<button key='session-panel-action' className="session-panel-add-action text-only-primary no-padding no-style" onClick={addQuestion}>
					<span>+ Add</span>
				</button>
			</StaggerChildren>

			<div className="session-panel-footer visible">
				<button disabled={saving} onClick={handleCancel}>Cancel</button>
				<button className="lemonade" disabled={saving || disableSave} onClick={saveSurvey}>
					{saving ? <WaitingIndicator /> : "Save"}
				</button>
			</div>
		</div>
	);
}
