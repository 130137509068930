import { handle } from "redux-pack";
import { Action } from "../../../types/actions";
import { Document, Template, UstreamVideos, WistiaVideos } from "../../../types/working-model";
import {
	ADD_DOCUMENTS,
	CHECK_ALL,
	CHECK_CONTENT,
	CLEAR_CHECKS,
	FILTER_CONTENT,
	GET_DOCUMENTS,
	RENAME_DOCUMENT,
	SEARCH_CONTENT,
	SORT_CONTENT,
	GET_USTREAM_VIDEOS,
	SWITCH_VIEW,
	REFRESH_DOCUMENT_THUMBNAIL,
	DELETE_DOCUMENT,
	REMOVE_DOCUMENT_FROM_LIST,
	CLEAR_DOCUMENTS_ERROR,
	GET_WISTIA_VIDEOS,
	GET_CHANNEL_TEMPLATES,
	ADD_CONTENT_TEMPLATE,
	UPDATE_CONTENT_TEMPLATE,
	DELETE_CUSTOM_THEMES,
	CLEAR_FAILED_UPLOAD_STATUS,
	TOGGLE_NAVIGATION,
	NAVIGATION_VISIBILITY
} from '../../actions/admin/content-actions';
import { DELETE_COLOR_PACKS, DELETE_FONT_PACKS } from "../../actions/admin/create-event";
import {
	FILE_TYPES,
	filterDocsByFileType,
	isDocumentCategory
} from "../../utils/content-utils";

export type listOrGrid = "grid" | "list";
export interface errorMessageObj {
	contentType: number, // use FILE_TYPES
	cannotDelete: number[]
}
export interface AdminContentState {
	fileType: number;
	sortBy: string;
	isUploading: boolean;
	searchContent: string;
	selectAll: boolean;
	checkedSet: Set<number | string>; // content ids as numbers
	ustreamVideos: UstreamVideos;
	ustreamVideosLoading: boolean;
	documents: Document[];
	filteredDocs: Document[];
	documentsLoading: boolean;
	documentsError: string | errorMessageObj;
	view: listOrGrid;
	wistiaVideos: WistiaVideos | null;
	wistiaVideosLoading: boolean;
	templates: Template[];
	deletingTemplates: boolean;
	uploadFailed?: string | null;
	isNavigationOpen: boolean;
	isNavigationVisible: boolean;
}

const initialState: AdminContentState = {
	checkedSet: new Set(),
	deletingTemplates: false,
	documents: [],
	documentsError: "",
	documentsLoading: false,
	fileType: FILE_TYPES.ALL_DOCS,
	filteredDocs: [],
	isNavigationOpen: false,
	isNavigationVisible: false,
	isUploading: false,
	searchContent: "",
	selectAll: false,
	sortBy: "",
	templates: [],
	uploadFailed: null,
	ustreamVideos: {
		videos: [],
		paging: {
			next: "",
			page_count: 0,
			item_count: 0
		}
	},
	ustreamVideosLoading: false,
	view: "grid",
	wistiaVideos: null,
	wistiaVideosLoading: false,
};

export default function ContentReducer(
	state: AdminContentState = initialState,
	action: Action
): AdminContentState {
	const { checkedSet, documents, filteredDocs, fileType } = state;
	const { payload, type } = action;
	switch (type) {
		case SWITCH_VIEW: {
			return { ...state, view: payload };
		}
		case CHECK_CONTENT: {
			//If id exists in the Set, delete. Otherwise add that id.
			const copySet: Set<number | string> = new Set(checkedSet);
			copySet.has(payload) ? copySet.delete(payload) : copySet.add(payload);
			return { ...state, checkedSet: copySet };
		}
		case CLEAR_CHECKS: {
			return { ...state, checkedSet: new Set(), selectAll: false };
		}
		case CHECK_ALL: {
			const newSet = new Set<number>();
			payload.forEach((id: number) => newSet.add(id));
			return { ...state, checkedSet: newSet, selectAll: true };
		}
		case FILTER_CONTENT: {
			const isImageOrDoc =
				payload === FILE_TYPES.IMAGES || isDocumentCategory(payload);
			return {
				...state,
				fileType: payload,
				checkedSet: new Set(),
				selectAll: false,
				filteredDocs: isImageOrDoc
					? filterDocsByFileType(documents, payload)
					: filteredDocs
			};
		}
		//Sorting and Searching logic are in content main
		case SORT_CONTENT: {
			return { ...state, sortBy: payload };
		}
		case SEARCH_CONTENT: {
			return { ...state, searchContent: payload };
		}
		//Documents and Images
		case GET_DOCUMENTS: {
			return handle(state, action, {
				success: state => ({
					...state,
					documents: payload,
					filteredDocs: filterDocsByFileType(payload, fileType),
					documentsError: ""
				}),
				start: state => ({ ...state, documentsLoading: true }),
				finish: state => ({ ...state, documentsLoading: false })
			});
		}
		case RENAME_DOCUMENT: {
			return handle(state, action, {
				success: state => {
					const updatedDocs = [...documents];
					for (const doc of updatedDocs) {
						if (doc?.document === payload?.document) {
							doc.display_name = payload.display_name;
							break;
						}
					}
					const filterDocs = filterDocsByFileType(updatedDocs, fileType);
					return {
						...state,
						documents: updatedDocs,
						filteredDocs: filterDocs,
						documentsError: ""
					};
				}
			});
		}
		case ADD_DOCUMENTS: {
			return handle(state, action, {
				success: state => {
					const newDocs = [...payload, ...documents];
					const filterDocs = filterDocsByFileType(newDocs, fileType);
					return {
						...state,
						documents: newDocs,
						filteredDocs: filterDocs,
						uploadFailed: null,
					};
				},
				start: state => ({ ...state, isUploading: true, uploadFailed: null }),
				finish: state => ({ ...state, isUploading: false }),
				failure: state => {
					return {
						...state,
						uploadFailed: 'Some or all of your uploads failed. Please wait a moment and try again.'
					};
				}
			});
		}
		case REMOVE_DOCUMENT_FROM_LIST: {
			const docToRemove = action.payload;
			return {
				...state,
				filteredDocs: state.filteredDocs.filter(doc => doc.document !== docToRemove),
				documents: state.documents.filter(doc => doc.document !== docToRemove)
			};
		}
		case DELETE_DOCUMENT: {
			return handle(state, action, {
				success: state => {
					const {
						cannotDelete,
						deletedDocuments
					}: {
						cannotDelete: number[];
						deletedDocuments: number[];
					} = payload;

					let updatedDocs = [...documents];
					const copySet = new Set(checkedSet);

					if (deletedDocuments?.length) {
						deletedDocuments.forEach(doc => copySet.delete(doc));

						if (deletedDocuments?.length === 1) {
							const docIndex = updatedDocs.findIndex(
								doc => doc.document === deletedDocuments[0]
							);
							if (docIndex >= 0) { updatedDocs.splice(docIndex, 1); }
						} else {
							updatedDocs = updatedDocs.filter(doc => {
								return !deletedDocuments.includes(doc.document);
							});
						}
					}

					const filterDocs = filterDocsByFileType(updatedDocs, fileType);

					const errorMessage = cannotDelete?.length
						? filteredDocs
							.filter(doc => cannotDelete.includes(doc.document))
							.map(doc => doc.display_name.base)
							.join(", ")
						: "";

					return {
						...state,
						documents: updatedDocs,
						filteredDocs: filterDocs,
						documentsError: errorMessage,
						checkedSet: copySet
					};
				}
			});
		}
		case REFRESH_DOCUMENT_THUMBNAIL: {
			const updatedDocs = [...documents];
			for (const doc of updatedDocs) {
				if (doc?.document === payload?.document) {
					// do not override a user-set thumbnail, only set if the thumbnail is null
					doc.thumbnail = doc.thumbnail || payload.image;
					doc.thumbnail_status = 2;
					doc.generated_thumbnail = payload.image;
					break;
				}
			}
			const filterDocs = filterDocsByFileType(updatedDocs, fileType);
			return {
				...state,
				documents: updatedDocs,
				filteredDocs: filterDocs
			};
		}
		case CLEAR_DOCUMENTS_ERROR: {
			return {
				...state,
				documentsError: ""
			};
		}
		case CLEAR_FAILED_UPLOAD_STATUS: {
			return {
				...state,
				uploadFailed: null,
			};
		}
		//Videos
		case GET_USTREAM_VIDEOS: {
			return handle(state, action, {
				success: state => ({
					...state,
					ustreamVideos: payload,
					ustreamVideosError: false
				}),
				start: state => ({ ...state, ustreamVideosLoading: true }),
				finish: state => ({ ...state, ustreamVideosLoading: false })
			});
		}
		case DELETE_COLOR_PACKS: {
			return handle(state, action, {
				success: state => {
					const {
						cannotDelete,
						deletedColors //color_pack state & error messaging managed in create-event reducer
					}: {
						cannotDelete: number[];
						deletedColors: number[];
					} = payload;

					const copySet = new Set(checkedSet);
					deletedColors.forEach(color => copySet.delete(color));

					const errorMessage = cannotDelete.length
						? {
							contentType: FILE_TYPES.THEME_PACKS,
							cannotDelete
						}
						: "";

					return {
						...state,
						checkedSet: copySet,
						documentsError: errorMessage
					};
				}
			});
		}
		case DELETE_FONT_PACKS: {
			return handle(state, action, {
				success: state => {
					const {
						cannotDelete,
						deletedFonts //colo_pack state & error messaging managed in create-event reducer
					}: {
						cannotDelete: number[];
						deletedFonts: number[];
					} = payload;

					const copySet = new Set(checkedSet);
					deletedFonts.forEach(font => copySet.delete(font));
					const errorMessage = cannotDelete.length
						? {
							contentType: FILE_TYPES.FONTS,
							cannotDelete
						}
						: "";

					return {
						...state,
						checkedSet: copySet,
						documentsError: errorMessage
					};
				}
			});
		}
		case GET_WISTIA_VIDEOS: {
			return handle(state, action, {
				success: state => ({ ...state, wistiaVideos: action.payload }),
				start: state => ({ ...state, wistiaVideosLoading: true }),
				finish: state => ({ ...state, wistiaVideosLoading: false })
			});
		}
		case GET_CHANNEL_TEMPLATES: {
			return handle(state, action, {
				success: state => ({ ...state, templates: action.payload }),
				start: state => ({ ...state, loadingTemplates: true }),
				finish: state => ({ ...state, loadingTemplates: false })
			});
		}
		case ADD_CONTENT_TEMPLATE: {
			return {
				...state,
				templates: [...state.templates, action.payload],
			};
		}
		case UPDATE_CONTENT_TEMPLATE: {
			return {
				...state,
				templates: state.templates.map(template => {
					if (template.template === action.payload.template) {
						return action.payload;
					}
					return template;
				})
			};
		}
		case DELETE_CUSTOM_THEMES: {
			return handle(state, action, {
				success: state => {
					const templatesToDelete = action.payload as number[];
					return {
						...state,
						templates: state.templates.filter(template => {
							if (templatesToDelete.includes(template.template)) {
								return false;
							}
							return true;
						})
					};
				},
				start: state => ({ ...state, deletingTemplates: true }),
				finish: state => ({ ...state, deletingTemplates: false })
			});
		}
		case TOGGLE_NAVIGATION: {
			return {
				...state,
				isNavigationOpen: action.payload,
			};
		}
		case NAVIGATION_VISIBILITY: {
			return {
				...state,
				isNavigationVisible: action.payload,
			};
		}

		default:
			return state;
	}
}
