import { AppThunk } from 'store/reducers/use-typed-selector';
import { useHistory } from 'react-router-dom';
import { batch } from 'react-redux';

import {
	authCode,
	authLogin,
	ForgotPassword,
	GetAccessTokenFromCode,
	GetAccessTokenFromRefreshToken,
	GetAccountFromStoredSession,
	GetAuthTypeForPresenter,
	GetChannels,
	GetChannelsFromStoredSession,
	IRedirectOptions,
	ReformatOAuthToken,
	RefreshOAuthToken,
	RefreshToken,
	SetAdminPassword,
	tempPhone,
	UpdateChannel,
	UpdateOnboarding,
	UpdatePassword,
} from '../../connection/auth';
import { AsyncAction, DispatchedAction, DispatchedAsyncAction } from '../../types/actions';
import { AuthConsumer, Channel, ChannelAuthIntegrationOnly, ChannelFeatures, PresenterAuthType, Profile, ReducedUser } from '../../types/working-model';
import { eCDNTypes, KollectiveConfiguration } from '../../types/ecdn';
import { getQueryParams } from 'utils/utils';
import { removeStorageItem, setStorageItem } from 'utils/local-storage';
import blAuth from 'connection/bl-auth/bl-auth';

export const SIGN_IN = 'SIGN_IN';
type SignInAction = DispatchedAsyncAction<typeof SIGN_IN, {
	token?: string;
	auth_consumer?: AuthConsumer;
	two_factor_required?: boolean;
	temp_profile?: string;
	phone_hash?: string;
	request_phone?: boolean;
	message?: string;
}>;
export function signIn(
	email: string,
	password: string,
	two_factor_stored: string | null,
	authConsumer: AuthConsumer,
	allowNoChannels = false,
): SignInAction {
	return {
		type: SIGN_IN,
		promise: authLogin(email, password, two_factor_stored, authConsumer, allowNoChannels),
	};
}

export const SET_TEMP_NUMBER = 'SET_TEMP_NUMBER';
type SetTempNumberAction = DispatchedAsyncAction<typeof SET_TEMP_NUMBER, { phone_hash: string }>;
export function setTempNumber(
	number: string,
	temp_profile: string
): SetTempNumberAction {
	return {
		type: SET_TEMP_NUMBER,
		promise: tempPhone(number, temp_profile)
	};
}

export const CONFIRM_CODE = 'CONFIRM_CODE';
type ConfirmCodeAction = DispatchedAsyncAction<typeof CONFIRM_CODE, { token: string, storage_token: string | null }>;
export function confirmCode(
	temp_profile: string,
	code: string,
	should_confirm: boolean,
	request_storage: boolean
): ConfirmCodeAction {
	return {
		type: CONFIRM_CODE,
		promise: authCode(temp_profile, code, should_confirm, request_storage),
	};
}

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
type ForgotPasswordAction = DispatchedAsyncAction<typeof FORGOT_PASSWORD, void>;
export function forgotPassword(email: string, hostname: string, redirectOptions?: IRedirectOptions): ForgotPasswordAction {
	return {
		type: FORGOT_PASSWORD,
		promise: ForgotPassword(email, hostname, redirectOptions),
	};
}

export const UPDATE_PASSWORD_LOADING = "UPDATE_PASSWORD_LOADING";
type UpdatePasswordLoadingAction = DispatchedAction<typeof UPDATE_PASSWORD_LOADING, boolean>;
export function updatePasswordLoading(loading: boolean): UpdatePasswordLoadingAction {
	return {
		payload: loading,
		type: UPDATE_PASSWORD_LOADING,
	};
}

export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";
type UpdatePasswordErrorAction = DispatchedAction<typeof UPDATE_PASSWORD_ERROR, string>;
export function updatePasswordError(error: string): UpdatePasswordErrorAction {
	return {
		payload: error,
		type: UPDATE_PASSWORD_ERROR,
	};
}

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export function updatePassword(password: string, code: string, history: ReturnType<typeof useHistory>): AppThunk {
	return async function(dispatch): Promise<void> {
		try {
			batch(() => {
				dispatch(updatePasswordLoading(true));
				dispatch(updatePasswordError(""));
			});
			const result = await UpdatePassword(password, code);
			// call sign in
			// if we don't have an email, return them to the email form
			if (!result?.email) {
				return history.push('/authentication/sign-in');
			}

			// check url for oauth params
			const queryParams = getQueryParams(history.location.search);

			// if they exist, set them in storage under their normal names
			if (queryParams.redirect) {

				const authFlowMapping: Record<string, string> = {
					redirect: 'auth_oauth_redirect',
					noRedirect: 'auth_oauth_no_redirect',
					consumer: 'auth_oauth_consumer',
					oauthSSOToken: 'auth_oauth_sso_token',
					oauthFlowTokenForRedirect: 'auth_oauth_flow_token_for_redirect',
				};

				// set ouath keys and redirect back to sign in
				Object.entries(queryParams).forEach(([key, value]) => {
					if (value) {
						const storageKey = authFlowMapping[key];
						const time = key === 'oauthFlowTokenForRedirect' ? 23 : .05;
						if (storageKey) {
							setStorageItem(storageKey, value, time);
						}
					}
				});

				removeStorageItem('forgot_flow_auth_oauth_redirect');
				removeStorageItem('forgot_flow_auth_oauth_no_redirect');
				removeStorageItem('forgot_flow_auth_oauth_consumer');
				removeStorageItem('forgot_flow_auth_oauth_sso_token');
				removeStorageItem('forgot_flow_auth_oauth_flow_token_for_redirect');

				// auto sign into GR
				dispatch(signIn(result.email, password, null, AuthConsumer.Greenroom, true));
				// if sign in is successful
				// navigate to email form, autocomplete and submit
				history.push(`/authentication/presenter/sign-in/${result.email}`);
				return;
			}
			// then redirect to presenter sign in,
			// else just sign in below
			// will also need to figure out how/when to remove them

			// sign in user (hard code platform since GR presenter redirects require a lot of param/token updates)
			dispatch(signIn(result.email, password, null, AuthConsumer.Platform, false));
		} catch (e) {
			console.error(e);
			dispatch(updatePasswordError('Unable to send password update. Please check your inputs and try again.'));
		} finally {
			dispatch(updatePasswordLoading(false));
		}
	};
}

export const SET_USER = 'SET_USER';
type SetUserAction = DispatchedAction<typeof SET_USER, {
	token: string,
	idToken?: string,
	refreshToken?: string,
	accessToken?: string,
}>;
export function setUser(payload: {
	token: string,
	idToken?: string,
	refreshToken?: string,
	accessToken?: string,
}): SetUserAction {
	return {
		type: SET_USER,
		payload: payload,
	};
}

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
type RefreshTokenAction = DispatchedAsyncAction<typeof REFRESH_TOKEN, { token: string }>;
export function refreshToken(token: string): RefreshTokenAction {
	return {
		type: REFRESH_TOKEN,
		promise: RefreshToken(token),
	};
}

export const SIGN_OUT = 'SIGN_OUT';
type SignOutAction = DispatchedAsyncAction<typeof SIGN_OUT, void>;
export function signOut(): SignOutAction {
	return {
		type: SIGN_OUT,
		promise: blAuth.signOut(),
	};
}

export const GET_ADMIN_CHANNELS = 'GET_ADMIN_CHANNELS';
type GetAdminChannelsAction = DispatchedAsyncAction<typeof GET_ADMIN_CHANNELS, Channel[]>;
export function getAdminChannels(token: string): GetAdminChannelsAction {
	return {
		type: GET_ADMIN_CHANNELS,
		promise: GetChannels(token),
	};
}

export const SET_CHANGING_CHANNELS = 'SET_CHANGING_CHANNELS';
type SetChangingChannelsAction = DispatchedAction<typeof SET_CHANGING_CHANNELS, boolean>;
export function setChangingChannels(isChangingChannels: boolean): SetChangingChannelsAction {
	return {
		type: SET_CHANGING_CHANNELS,
		payload: isChangingChannels,
	};
}

export const UPDATE_CHANNEL = "UPDATE_CHANNEL";
type UpdateChannelAction = DispatchedAsyncAction<typeof UPDATE_CHANNEL, Channel>;
export function updateChannel(token: string, channel: Channel): UpdateChannelAction {
	return {
		type: UPDATE_CHANNEL,
		promise: UpdateChannel(token, channel)
	};
}

export const SET_ADMIN_PASSWORD = "SET_ADMIN_PASSWORD";
type SetAdminPasswordAction = DispatchedAsyncAction<typeof SET_ADMIN_PASSWORD, unknown>;
export function setAdminPassword(password: string, code: string, cb: () => void): SetAdminPasswordAction {
	return {
		type: SET_ADMIN_PASSWORD,
		promise: SetAdminPassword(password, code, cb)
	};
}

export const ADD_CHECKLIST_ITEM = 'ADD_CHECKLIST_ITEM';
type AddChecklistItemAction = DispatchedAsyncAction<typeof ADD_CHECKLIST_ITEM, string[]>;
export function addChecklistItem(token: string, id: number, onboarding: string[], newItem: string): AddChecklistItemAction {
	return {
		type: ADD_CHECKLIST_ITEM,
		promise: UpdateOnboarding(token, id, onboarding, newItem)
	};
}

export const CLEAR_CHECKLIST = 'CLEAR_CHECKLIST';
type ClearChecklistAction = DispatchedAction<typeof CLEAR_CHECKLIST, boolean>;
export function clearChecklist(): ClearChecklistAction {
	return {
		type: CLEAR_CHECKLIST,
		payload: true
	};
}

export const COMPLETE_WELCOME_ONBOARDING = 'COMPLETE_WELCOME_ONBOARDING';
type CompleteWelcomeOnboardingAction = DispatchedAsyncAction<typeof COMPLETE_WELCOME_ONBOARDING, string[]>;
export function completeWelcomeOnboarding(token: string, id: number, onboarding: string[]): CompleteWelcomeOnboardingAction {
	return {
		type: COMPLETE_WELCOME_ONBOARDING,
		promise: UpdateOnboarding(token, id, onboarding, "welcome")
	};
}

export const UPDATE_ADMIN_PROFILE = 'UPDATE_ADMIN_PROFILE';
type UpdateAdminProfileAction = DispatchedAction<typeof UPDATE_ADMIN_PROFILE, Profile>;
export function updateAdminProfile(profile: Profile): UpdateAdminProfileAction {
	return {
		type: UPDATE_ADMIN_PROFILE,
		payload: profile
	};
}
//TODO: create password from email invite

export const GET_CHANNELS_INFO_FOR_ADMIN = 'GET_CHANNELS_INFO_FOR_ADMIN';
type GetChannelsInfoForAdminAction = DispatchedAsyncAction<typeof GET_CHANNELS_INFO_FOR_ADMIN, Channel[]>;
export function getChannelsInfo(token: string): GetChannelsInfoForAdminAction {
	return {
		type: GET_CHANNELS_INFO_FOR_ADMIN,
		promise: GetChannelsFromStoredSession(token)
	};
}

export const GET_AUTH_TYPE_FOR_PRESENTER = 'GET_AUTH_TYPE_FOR_PRESENTER';
type GetAuthTypeForPresenterAction = DispatchedAsyncAction<typeof GET_AUTH_TYPE_FOR_PRESENTER, PresenterAuthType>;
export function getAuthTypeForPresenter(email: string, channel?: number): GetAuthTypeForPresenterAction {
	return {
		type: GET_AUTH_TYPE_FOR_PRESENTER,
		promise: GetAuthTypeForPresenter(email, channel)
	};
}

export const SET_AUTH_TYPE_FOR_PRESENTER = 'SET_AUTH_TYPE_FOR_PRESENTER';
type SetAuthTypeForPresenterAction = DispatchedAction<typeof SET_AUTH_TYPE_FOR_PRESENTER, PresenterAuthType | null>;
export function setAuthTypeForPresenter(presenterAuthType: PresenterAuthType | null): SetAuthTypeForPresenterAction {
	return {
		type: SET_AUTH_TYPE_FOR_PRESENTER,
		payload: presenterAuthType
	};
}

export const REFORMAT_OAUTH_TOKEN = 'REFORMAT_OAUTH_TOKEN';
type ReformatOAuthTokenAction = DispatchedAsyncAction<typeof REFORMAT_OAUTH_TOKEN, { token: string }>;
export function reformatOAuthToken(
	token: string,
	targetAuthConsumer: AuthConsumer,
): ReformatOAuthTokenAction {
	return {
		type: REFORMAT_OAUTH_TOKEN,
		promise: ReformatOAuthToken(token, targetAuthConsumer),
	};
}

export const REFRESH_OAUTH_TOKEN = 'REFRESH_OAUTH_TOKEN';
type RefreshOAuthTokenAction = DispatchedAsyncAction<typeof REFRESH_OAUTH_TOKEN, { token: string }>;
export function refreshOAuthToken(
	token: string,
): RefreshOAuthTokenAction {
	return {
		type: REFRESH_OAUTH_TOKEN,
		promise: RefreshOAuthToken(token),
	};
}

export const GET_ACCOUNT_INFO_BY_STORED_TOKEN = 'GET_ACCOUNT_INFO_BY_STORED_TOKEN ';
type GetAccoutnInfoAction = DispatchedAsyncAction<typeof GET_ACCOUNT_INFO_BY_STORED_TOKEN, { account: ReducedUser }>;
export function getAccountInfo(token: string): GetAccoutnInfoAction {
	return {
		type: GET_ACCOUNT_INFO_BY_STORED_TOKEN,
		promise: GetAccountFromStoredSession(token)
	};
}

export const SET_CHANNEL_SSO_INFO = 'SET_CHANNEL_SSO_INFO';
type SetChannelSSOAction = DispatchedAction<typeof SET_CHANNEL_SSO_INFO, Channel | ChannelAuthIntegrationOnly>;
export function setChannelSSO(ssoConfiguration: Channel | ChannelAuthIntegrationOnly): SetChannelSSOAction {
	return {
		type: SET_CHANNEL_SSO_INFO,
		payload: ssoConfiguration
	};
}

export const GREENROOM_LOGIN_ERROR = "GREENROOM_LOGIN_ERROR";
type SetGreenroomLoginErrorAction = DispatchedAction<typeof GREENROOM_LOGIN_ERROR, boolean>;
export function setGreenroomLoginError(hasError: boolean): SetGreenroomLoginErrorAction {
	return {
		type: GREENROOM_LOGIN_ERROR,
		payload: hasError,
	};
}

export const EXCHANGE_REFRESH_TOKEN = "EXCHANGE_REFRESH_TOKEN";
type ExchangeRefreshTokenAction = DispatchedAsyncAction<
	typeof EXCHANGE_REFRESH_TOKEN,
	{
		id_token: string;
		access_token?: string;
	},
	{
		refreshToken: string;
	}
>;

export const EXCHANGE_CODE_FOR_TOKENS = "EXCHANGE_CODE_FOR_TOKENS";
type ExchangeCodeForTokensAction = DispatchedAsyncAction<typeof EXCHANGE_CODE_FOR_TOKENS, { id_token: string; refresh_token: string; access_token?: string }>;
export function exchangeCodeForTokens(code: string): ExchangeCodeForTokensAction {
	return {
		type: EXCHANGE_CODE_FOR_TOKENS,
		promise: GetAccessTokenFromCode(code)
	};
}

export const SET_AUTH_TOKENS = "SET_AUTH_TOKENS";
type SetAuthTokensAction = DispatchedAction<typeof SET_AUTH_TOKENS, { id_token: string; refresh_token: string; access_token?: string }>;
export function setAuthTokens(id_token: string, refresh_token: string, access_token?: string): SetAuthTokensAction {
	return {
		type: SET_AUTH_TOKENS,
		payload: { id_token, refresh_token, access_token }
	};
}

export const UPDATE_ECDN_CONFIGURATION = "UPDATE_ECDN_CONFIGURATION";
type UpdateECDNConfiguration = DispatchedAction<typeof UPDATE_ECDN_CONFIGURATION, { channel: number, ecdn_configuration: { [key in eCDNTypes]: KollectiveConfiguration } }>;
export function updateECDNConfiguration(channel: number, ecdn_configuration: { [key in eCDNTypes]: KollectiveConfiguration }): UpdateECDNConfiguration {
	return {
		type: UPDATE_ECDN_CONFIGURATION,
		payload: { channel, ecdn_configuration }
	};
}

export const ACKNOWLEDGE_DMA = 'ACKNOWLEDGE_DMA';
type AcknowledgeDmaAction = DispatchedAction<typeof ACKNOWLEDGE_DMA, string>;
export function acknowledgeDma(acknowledgementDate: string): AcknowledgeDmaAction {
	return {
		type: ACKNOWLEDGE_DMA,
		payload: acknowledgementDate
	};
}

export const USING_UNIFIED_AUTH = 'USING_UNIFIED_AUTH';
type UnifiedAuthResetAction = DispatchedAction<typeof USING_UNIFIED_AUTH, void>;
export function usingUnifiedAuth(): UnifiedAuthResetAction {
	return {
		type: USING_UNIFIED_AUTH,
		payload: undefined
	};
}

export const UPDATE_CURRENT_CHANNEL_FEATURES = 'UPDATE_CURRENT_CHANNEL_FEATURES';
type UpdateChannelFeaturesAction = DispatchedAction<typeof UPDATE_CURRENT_CHANNEL_FEATURES, ChannelFeatures>;
export function updateChannelFeaturesAction(channelFeatures: ChannelFeatures): UpdateChannelFeaturesAction {
	return {
		type: UPDATE_CURRENT_CHANNEL_FEATURES,
		payload: channelFeatures
	};
}

export const CHANNEL_ACCESS_REVOKED = 'CHANNEL_ACCESS_REVOKED';
type ChannelAccessRevokedAction = DispatchedAction<typeof CHANNEL_ACCESS_REVOKED, number>;
export function channelAccessRevoked(channel: number): ChannelAccessRevokedAction {
	return {
		type: CHANNEL_ACCESS_REVOKED,
		payload: channel
	};
}

export type AuthenticationAction =
	SetGreenroomLoginErrorAction |
	GetAccoutnInfoAction |
	SetChannelSSOAction |
	RefreshOAuthTokenAction |
	ReformatOAuthTokenAction |
	GetAuthTypeForPresenterAction |
	GetChannelsInfoForAdminAction |
	UpdateAdminProfileAction |
	CompleteWelcomeOnboardingAction |
	ClearChecklistAction |
	AddChecklistItemAction |
	SetAdminPasswordAction |
	UpdateAdminProfileAction |
	UpdateChannelAction |
	SetChangingChannelsAction |
	GetAdminChannelsAction |
	SignOutAction |
	RefreshTokenAction |
	SetUserAction |
	UpdatePasswordErrorAction |
	UpdatePasswordLoadingAction |
	ForgotPasswordAction |
	ConfirmCodeAction |
	SetTempNumberAction |
	SignInAction |
	SetAuthTypeForPresenterAction |
	ExchangeCodeForTokensAction |
	ExchangeRefreshTokenAction |
	SetAuthTokensAction |
	UpdateECDNConfiguration |
	AcknowledgeDmaAction |
	UnifiedAuthResetAction |
	ChannelAccessRevokedAction |
	UpdateChannelFeaturesAction;
