import { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTypedSelector } from '../../store/reducers/use-typed-selector';
import { BrandliveEvent, EventNavbar, HomepageFooter, HomepageHeader, ISocketCommentProps, PageModule, Session, BlProfile, LanguagesAbbr, SelectedBrandliveEvent, SessionPreview, PageModuleType } from '../../types/working-model';
import { getStorageItem } from "../../utils/local-storage";
import { ParamsProps } from './live-event';

export interface stylingOverrideReturns {
	backgroundColor: string;
	backgroundImg: { backgroundImage: string } | { backgroundImage?: undefined };
	titleOverrides: string;
	descriptionOverrides: string;
	customHeadingOverrides: string;
	moduleTitleOverrides: string;
	moduleDescriptionOverrides: string;
	moduleCustomHeadingOverrides: string;
	primaryButtonOverrides: string;
	secondaryButtonOverrides: string;
	dropdownOverrides: string;
	startTimeOverrides: string;
	regPageStreamDateOverrides: string;
	regPageSpeakersLineupOverrides: string;
}
export function getStylingOverrides(
	styling_overrides: PageModule['styling_overrides']
): stylingOverrideReturns {
	return useMemo(() => {
		const backgroundOverrides = styling_overrides?.background;
		const contentOverrides = styling_overrides?.content;
		const backgroundColor = styling_overrides?.background?.color ?? '';
		const backgroundImg = backgroundOverrides?.image.is_on
			? { backgroundImage: `url(${backgroundOverrides.image.src})` }
			: {};
		const titleOverrides = Object.values(contentOverrides?.title ?? {}).join(
			' '
		);
		const descriptionOverrides = Object.values(
			contentOverrides?.description ?? {}
		).join(' ');
		const customHeadingOverrides = Object.values(
			contentOverrides?.custom_heading ?? {}
		).join(' ');
		const moduleTitleOverrides = Object.values(
			contentOverrides?.modules?.title ?? {}
		).join(' ');
		const moduleDescriptionOverrides = Object.values(
			contentOverrides?.modules?.description ?? {}
		).join(' ');
		const moduleCustomHeadingOverrides = Object.values(
			contentOverrides?.modules?.custom_heading ?? {}
		).join(' ');
		const primaryButtonOverrides = Object.values(
			contentOverrides?.modules?.primary_button ?? {}
		).join(' ');
		const secondaryButtonOverrides = Object.values(
			contentOverrides?.modules?.secondary_button ?? {}
		).join(' ');
		const dropdownOverrides = Object.values(
			contentOverrides?.modules?.dropdowns ?? {}
		).join(' ');
		const startTimeOverrides = Object.values(
			contentOverrides?.start_time?.color ?? {}
		).join('');
		const regPageStreamDateOverrides = Object.values(
			contentOverrides?.registration_page_stream_date ?? {}
		).join('');
		const regPageSpeakersLineupOverrides = Object.values(
			contentOverrides?.registration_page_speakers_lineup ?? {}
		).join('');

		//Aside from backgroundImg these will all be inserted into the classnames of the element they reference

		// <div
		//      className={classNames("speakers", `speakers-${layout_type}`, template, backgroundColor)}
		//      style={backgroundImg}
		// >
		//      <div className="module-container">
		//          <p className={classNames("speakers-custom_heading", customHeadingOverrides)}>{content.custom_heading[language]}</p>
		//          <h2 className={classNames("speakers-title", titleOverrides)}>{content.title[language]}</h2>
		//          <p className={classNames("speakers-description", descriptionOverrides)}>{content.description[language]}</p>
		return {
			backgroundColor, //main module background color
			backgroundImg, //main module background image (goes under style attr -> style={backgroundImg})
			titleOverrides, // main module title
			descriptionOverrides, //main module description
			customHeadingOverrides, //main module aside or secondary title
			moduleTitleOverrides, //list item or card, main heading (e.g. SpeakerCard name element)
			moduleDescriptionOverrides, //card description
			moduleCustomHeadingOverrides, //card aside/price/time
			primaryButtonOverrides, //main button
			secondaryButtonOverrides, //alternate button
			dropdownOverrides, //dropdown and misc ui
			startTimeOverrides, //color of text for the start time
			regPageStreamDateOverrides, //color of text for the registration page stream date
			regPageSpeakersLineupOverrides, //color of text for the registration page speakers lineup
		};
	}, [styling_overrides]);
}

interface IHeaderStylingOverridesReturn {
	backgroundColor: string;
	backgroundImg: { backgroundImage: string } | { backgroundImage?: undefined };
	mainTextOverrides?: string;
	mainButtonOverrides: string;
}

export function getHeaderStylingOverrides(
	styling_overrides: HomepageHeader['styling_overrides']
): IHeaderStylingOverridesReturn {
	return useMemo(() => {
		const backgroundOverrides = styling_overrides?.background;
		const contentOverrides = styling_overrides?.content;
		const backgroundColor = styling_overrides?.background?.color ?? '';
		const backgroundImg = backgroundOverrides?.image.is_on
			? { backgroundImage: `url(${backgroundOverrides.image.src})` }
			: {};
		const mainTextOverrides = Object.values(contentOverrides?.navItems ?? {}).join(
			' '
		);
		const mainButtonOverrides = Object.values(
			contentOverrides?.callToAction ?? {}
		).join(' ');

		return {
			backgroundColor, //main module background color
			backgroundImg, //main module background image (goes under style attr -> style={backgroundImg})
			mainTextOverrides, // navItem overrides
			mainButtonOverrides // call to action overrides
		};
	}, [styling_overrides]);
}


interface IFooterStylingOverridesReturn {
	backgroundColor: string;
	backgroundImg: { backgroundImage: string; } | { backgroundImage?: undefined; };
	mainTextOverrides?: string;
	blLogoColor: string;
}

export function getFooterStylingOverrides(styling_overrides: HomepageFooter['styling_overrides']): IFooterStylingOverridesReturn {
	return useMemo(() => {
		const backgroundOverrides = styling_overrides?.background;
		const backgroundColor = backgroundOverrides?.color ?? '';
		const backgroundImg = backgroundOverrides?.image.is_on
			? { backgroundImage: `url(${backgroundOverrides.image.src})` }
			: {};

		const contentOverrides = styling_overrides?.content;
		const mainTextOverrides = Object.values(contentOverrides?.navItems ?? {}).join(' ');
		const blLogoColor = contentOverrides?.blLogoColor ?? '';

		return {
			backgroundColor, //main module background color
			backgroundImg, //main module background image (goes under style attr -> style={backgroundImg})
			mainTextOverrides, // navItem overrides
			blLogoColor // logo color overrides
		};
	}, [styling_overrides]);
}


export function getSessionStylingOverrides(styling_overrides: Session['layout']['styling_overrides']): Omit<IHeaderStylingOverridesReturn, 'mainButtonOverrides' | 'mainTextOverrides'> {

	const backgroundColor = styling_overrides?.background?.color ?? '';
	const backgroundImg = styling_overrides?.background?.image?.is_on
		? { backgroundImage: `url(${styling_overrides?.background?.image.src})`, backgroundSize: 'cover' }
		: {};

	return {
		backgroundColor, //main module background color
		backgroundImg, //main module background image (goes under style attr -> style={backgroundImg})
	};
}


export function eventPath(eventName?: string, uuid?: string): string {
	if (eventName) return eventName;
	else return `event/${uuid}`;
}

// FUTURE DEV: useParams is not correctly pulling in the eventName on brandlive-dev.com
// including this function for now to generate the correct url when forwarding to registration
// should remove once we figure out why params are not working
export function getEventNameURL(eventBundle: BrandliveEvent): string {
	return eventBundle?.name.toLowerCase().replace(/[^0-9a-z]/g, '-') ?? '';
}

export const blankStyleOverrides: stylingOverrideReturns = {
	backgroundColor: '',
	backgroundImg: { backgroundImage: '' },
	titleOverrides: '',
	descriptionOverrides: '',
	customHeadingOverrides: '',
	moduleTitleOverrides: '',
	moduleDescriptionOverrides: '',
	moduleCustomHeadingOverrides: '',
	primaryButtonOverrides: '',
	secondaryButtonOverrides: '',
	dropdownOverrides: '',
	startTimeOverrides: '',
	regPageStreamDateOverrides: '',
	regPageSpeakersLineupOverrides: '',
};

//
export const useRedirectToHome = (shouldRedirect: boolean): void => {
	const eventBundle = useTypedSelector(state => state.LiveEventReducer?.eventBundle);

	const history = useHistory();
	const params = useParams<ParamsProps>();
	const hasHomepage = !!eventBundle?.homepage;

	useEffect(() => {
		if (shouldRedirect && eventBundle) {
			const eventName = params?.eventName;
			const eventUuid = params?.uuid;
			const language = params?.language || getDefaultLanguage(eventBundle);
			const basePath = eventPath(eventName, eventUuid);
			const url = `/${basePath}/${language}${hasHomepage ? '/home' : ''}`;
			history.replace(url);
		}
	}, [
		shouldRedirect,
		eventBundle,
		params,
		hasHomepage,
		history,
	]);
};

interface IEventNavbarStylingReturn {
	backgroundColor: string;
	backgroundImg: { backgroundImage: string; } | { backgroundImage?: undefined; };
	navItemColor: string;
	navItemActiveAccent: string;
}

export const getEventNavbarStyling = (styling: EventNavbar['styling_overrides']): IEventNavbarStylingReturn => {
	const backgroundColor: string = styling?.background?.color ?? '';
	const backgroundImg: React.CSSProperties = styling?.background?.image.is_on ? { backgroundImage: `url(${styling.background?.image.src})`, backgroundSize: 'cover' } : {};
	const navItemColor: string = styling?.content.navItems ?? '';
	const navItemActiveAccent: string = `${navItemColor.split('-')[0]}-background` ?? '';

	return { backgroundColor, backgroundImg, navItemColor, navItemActiveAccent };
};

export const getAvatarForComment = (channel: number, displayAvatar: boolean, comment: ISocketCommentProps): string => {
	return channel && displayAvatar && (comment?.registered_profile?.[channel]?.avatar || comment?.non_registered_profile?.[channel]?.avatar || comment?.profile?.[channel]?.avatar) || comment?.avatar || '';
};

// returns true if the registered or unregistered profile from the chat message matches the user blProfile
export function matchChatSender(blProfileUser: BlProfile | null, blProfile: number | undefined, blProfileNonReg: number | undefined): boolean {
	// profiles should be numbers but some chat functions are returning them as strings - using Number as safeguard
	return Number(blProfileUser?.bl_profile) === Number(blProfileUser?.non_registered ? blProfileNonReg : blProfile);
}

export const getIsPrevUser = (
	prevComment: ISocketCommentProps,
	comment: ISocketCommentProps
): boolean => {
	if (!prevComment) return false;

	if (
		prevComment.bl_profile === comment.bl_profile &&
		prevComment.moderator_profile?.first_name === comment.moderator_profile?.first_name &&
		prevComment.moderator_profile?.last_name === comment.moderator_profile?.last_name &&
		prevComment.is_moderator === comment.is_moderator
	) {
		return true;
	}

	if (
		prevComment.bl_profile_non_registered &&
		prevComment.bl_profile_non_registered === comment.bl_profile_non_registered &&
		prevComment.is_moderator === comment.is_moderator
	) {
		return true;
	}

	return false;
};

export const getAllAvailableLanguagesForSelectedEvent = (eventBundle: SelectedBrandliveEvent): string[] => {
	return getAllAvailableLanguages({
		...eventBundle,
		homepage: eventBundle._homepage,
		sessionsPreviews: (eventBundle._sessions as unknown as SessionPreview[]) ?? undefined
	});
};

export const getAllAvailableLanguages = (eventBundle: BrandliveEvent): string[] => {
	return Array.from(new Set([
		eventBundle.settings?.i18n?.base,
		...(eventBundle.homepage?.languages ?? []),
		...(eventBundle.sessionsPreviews?.flatMap(session => session.languages) ?? [])
	])).filter<string>((lang): lang is string => typeof lang === 'string');
};

export const getDefaultLanguage = (eventBundle: BrandliveEvent): LanguagesAbbr => {
	// return user-set default language, if unset use first language from the homepage, if unset use first language from the first session
	return (eventBundle.settings?.i18n?.base ?? eventBundle.homepage?.languages?.[0] ?? eventBundle.sessionsPreviews?.[0]?.languages?.[0] ?? 'en') as LanguagesAbbr;
};

export const getAttendeeLocalLanguage = (eventBundle: BrandliveEvent | null) => {
	if (eventBundle) {
		const lang = getStorageItem(`event.${eventBundle.event}.language`) || window.navigator.language.substring(0, 2);

		if (getAllAvailableLanguages(eventBundle).includes(lang)) {
			return lang;
		}

		return getDefaultLanguage(eventBundle);
	}
};

// This is to safely handle URL casing. User's can technically have capital letters (or in the case of zh-TW, mixed case)
// in the URL and it causes some problems.
export const useLanguageParam = () => {
	const params = useParams<{ language?: LanguagesAbbr; lang?: LanguagesAbbr; }>();
	const language = params.language || params.lang || '';
	return useMemo(() => language.toLowerCase().replace('-tw', '-TW').replace('-cn', '-CN'), [language]);
};

export const useAttendeeIdParam = () => {
	const attendeeId = useParams<{ attendeeId?: string }>()?.attendeeId || '';

	return useMemo(() => attendeeId, [attendeeId]);
};

export const findAnswerableModule = (module: PageModule | undefined): boolean => {
	return !!module && (module.type === PageModuleType.questions || module.type === PageModuleType.comment_box_prompt);
};


export const checkPasswordGatingPasswordRequirement = (_password: string) => {
	// Follows OWASP guidelines
	// https://cheatsheetseries.owasp.org/cheatsheets/Authentication_Cheat_Sheet.html#implement-proper-password-strength-controls
	const password = _password.trim();

	return password.length >= 8 && password.length <= 64;
};
