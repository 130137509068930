import { forwardRef, PropsWithChildren, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

type IPortalProps = PropsWithChildren<{
	className?: string;
}>

const Portal = forwardRef<HTMLDivElement, IPortalProps>(({ children, className = 'custom-portal' }, ref) => {
	const containerRef = useRef(document.createElement('div'));
	containerRef.current.classList.add(...className.split(" "));

	useEffect(() => {
		document.body.appendChild(containerRef.current);

		const containerRefCurrent = containerRef.current;

		if (ref) {
			if (typeof ref === 'function') {
				ref(containerRef.current);
			} else {
				ref.current = containerRef.current;
			}
		}
		return () => {
			document.body.removeChild(containerRefCurrent);
		};
	}, [containerRef, ref]);

	// ensure container gets the className prop
	// this is necessary because the container won't re-render with the className prop
	// so we need to manually set it whenever the className prop changes
	useEffect(() => {
		if (containerRef) {
			containerRef.current.className = className || '';
		}
		// only re-run when className changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [className]);

	return ReactDOM.createPortal(children, containerRef.current);
});

export default Portal;
