import { Get, Post } from './helpers';
import { PasscodeListMemberType } from '../types/working-model';

export const GetMagicLinksForEvent = async (token: string, channel: number, list_id: number, event_uuid: string): Promise<any> => {
	return Get((event_uuid ?
		`/v3/admin/events/${channel}/${list_id}/magic-links/${event_uuid}` :
		`/v3/admin/events/${channel}/${list_id}/magic-links`), token, {}, true)
		.then(async res => {
			if (res.ok) {
				const results = await res.json();
				return results;
			}

			throw new Error(`Unable to load magic links from channel - ${channel} audience list - ${list_id} event -${event_uuid}`);
		});
};

export const InvalidateMagicLinksForEvent = async (token: string, channel: number, audience_list: number, event_uuid: string, member_ids?: number[]): Promise<any> => {
	return Post(`/v3/admin/events/magic-links/invalidate`, token, { list_id: audience_list, member_ids, event_uuid }, false, {}, true)
		.then(async res => {
			if (res.ok) {
				const result = await res.json();
				return result;
			}
			throw new Error(`Unable to invalidate magic links from channel - ${channel} audience list - ${audience_list} event -${event_uuid}`);
		});
};


export const GenerateMagicLinksForEvent = async (token: string, channel: number, audience_list: number, event_uuid: string, selectedMembers?: PasscodeListMemberType[]): Promise<any> => {
	return Post(`/v3/admin/events/magic-links/generate`, token, { channel, audience_list, event_uuid, selectedMembers }, false, {}, true)
		.then(async res => {
			if (res.ok) {
				return await res.json();
			}
			throw new Error(`Unable to generate magic links from channel - ${channel} audience list - ${audience_list} event -${event_uuid}`);
		});
};

export const GenerateMagicLinksForList = async (
	token: string,
	channel: number,
	audienceList: number,
	eventReturnFilter?: string,
): Promise<any> => {
	return Post(
		`/v3/admin/channel/${channel}/magic-links/${audienceList}/generate`,
		token,
		{
			event_return_filter: eventReturnFilter,
		},
		false,
		{},
		true,
	)
		.then(async res => {
			if (res.ok) {
				return await res.json();
			}
			throw new Error(`Unable to generate magic links from channel - ${channel} audience list - ${audienceList}`);
		});
};

