import {
	BrandliveEvent,
	CreateEventRequest,
	CreateEventResponse,
	CreateSession,
	EventHomepage,
	EventPreview,
	EventSettings,
	GreenroomProducerMeeting,
	IHomepageMainNavItems,
	IFeedbackSurveyText,
	PageModule,
	Registration,
	RegistrationStep,
	Session,
	SocialSettings,
	TemplateEventInfo,
	GetEventsResponse
} from "../types/working-model";
import { createQueryString } from "../utils/utils";
import { Put, Post, Delete, Get, PostAbortable, Patch, FetchError } from "./helpers";

export const CreateEvent = async (event: CreateEventRequest, token: string): Promise<CreateEventResponse> => {
	return Post('/v3/admin/event', token, event, false, {}, true)
		.then(async res => {
			if (res.ok) {
				return await res.json();
			}

			throw new FetchError({ error: res.status, status: res.status });
		});
};

export function SaveEvent(event: BrandliveEvent, token: string): Promise<{ homepage: EventHomepage }> {
	return Put("/v3/admin/event", token, { event }, true);
}

export const LoadEvent = async (uuid: string, token: string, queryParams = {}): Promise<BrandliveEvent & { error: number }> => {
	const query = createQueryString(queryParams);
	return Get(`/v3/admin/event/${uuid}?${query}`, token, {}, true)
		.then(async res => {
			if (res.ok) {
				return await res.json();
			}

			throw new FetchError({ error: res.status, status: res.status });
		});
};

export function AddHomepageToEvent(uuid: string, token: string, mulitpleSessions?: 'multi'): Promise<EventHomepage> {
	return Get(`/v3/admin/events/homepage/add-event-homepage/${uuid}${mulitpleSessions ? '?is_multi=' + mulitpleSessions : ''}`, token);
}

export const GetChannelTracks = async (channel: number, token: string): Promise<string[]> => {
	return Get(`/v3/admin/events/tracks/${channel}`, token, {}, true)
		.then(async res => {
			if (res.ok) {
				return await res.json();
			}

			throw new Error(`Unable to load channel tracks: ${channel}`);
		});
};

export const EditChannelTracks = async (channel: number, tracks: { [key: string]: string }, token: string): Promise<{ [key: string]: string }> => {
	return Put(`/v3/admin/events/tracks/${channel}`, token, { tracks }, false, true)
		.then(async res => await res.json());
};

export const AddNewSession = async (
	session: CreateSession,
	event: number,
	eventUuid: string,
	channel: number,
	token: string,
	cb?: (error?: boolean) => void,
): Promise<Session> => {
	return Post('/v3/admin/event/session', token, { session, event, channel, eventUuid }, false, {}, true)
		.then(async res => {
			if (cb) cb(res.ok);

			if (res.ok) {
				return await res.json();
			}

			throw new FetchError({ error: res.status, status: res.status });
		});
};

export const SaveSession = async (session: Session, token: string): Promise<Session> => {
	return Put('/v3/admin/event/session', token, { session }, false, true)
		.then(async res => {
			return await res.json();
		});
};

export async function SaveSessionList(sessionList: Session[], token: string): Promise<Session[]> {
	return await Promise.all(sessionList.map(async s => await UpdateSessionInfo(s, token)));
}

export function DuplicateSession(
	session: Session,
	event: number,
	eventUuid: string,
	token: string
): Promise<Session> {
	return Post("/v3/admin/event/session/duplicate", token, { session, event, eventUuid });
}

export function UpdateSessionInfo(session: Session, token: string): Promise<Session> {
	return Put("/v3/admin/event/session", token, { session }, true);
}

export function UpdateSessionCardInfo(session: Session, token: string): Promise<Session> {
	return Put("/v3/admin/event/session-info", token, { session }, true);
}

export function DeleteSession(
	sessionID: number,
	eventUuid: string,
	token: string
): Promise<{ session: number }> {
	return Delete(`/v3/admin/events/session`, token, { sessionID, eventUuid }, true);
}

export function UpdatePublishedUrl(
	token: string,
	eventUuid: string,
	newUrl: string
): Promise<any> {
	return Put('/v3/admin/events/update-url', token, { eventUuid, newUrl });
}

export function CheckEventPublishedStatus(token: string, uuid: string): Promise<{ status: number, url: string }> {
	return Get(`/v3/admin/events/published-status/${uuid}`, token);
}

export function CheckEventUrl(
	token: string,
	hostname: string,
	pathname: string,
	event: number,
	channel: number
): [AbortController, Promise<{ exists: boolean }>] {
	return PostAbortable("/v3/admin/events/check-url", token, { hostname, pathname, event, channel });
}

export function CheckEventName(
	token: string,
	channel: number,
	eventName: string,
	eventId: number | null
): [AbortController, Promise<{ exists: boolean }>] {
	return PostAbortable("/v3/admin/events/check-event-name", token, { eventName, event: eventId, channel });
}

export function PublishEvent(token: string, uuid: string, url: string): Promise<{ uuid: string }> {
	return Post("/v3/admin/events/publish", token, { uuid, url }).then(result => {
		if ('error' in result) {
			throw new Error('Unable to publish');
		} else {
			return result;
		}
	});
}

export function PublishEventPreview(token: string, preview_event_uuid: string, base_event_uuid: string, url: string): Promise<{ url: string, uuid: string }> {
	return Post("/v3/admin/events/preview/publish", token, { preview_event_uuid, base_event_uuid, url }).then(result => {
		if ('error' in result) {
			throw new Error('Unable to publish');
		} else {
			return result;
		}
	});
}

export function UpdateRegistrationSteps(token: string, event_uuid: string, updatedSteps: RegistrationStep[]): Promise<RegistrationStep[]> {
	const updated_steps = updatedSteps.map(step => {
		if (!step?.questions?.length) return step;
		else {
			return {
				...step,
				questions: step.questions.map(q => q.registration_question)
			};
		}
	});
	return Put("/v3/admin/events/registration-steps", token, { event_uuid, updated_steps });
}

export function UpdateRegistrationSettings(token: string, event_uuid: string, settings: Registration): Promise<Registration> {
	return Put("/v3/admin/events/registration-settings", token, { event_uuid, settings }, true);
}

export function UpdateEventSettings(token: string, event_uuid: string, settings: EventSettings): Promise<EventSettings> {
	return Put("/v3/admin/events/settings", token, { event_uuid, settings: settings }, true);
}

export function AddPostRegisterHomePageModuleToEvent(token: string, homepage: number, page_module: PageModule): Promise<PageModule> {
	return Post(`/v3/admin/events/post-register-home-module/${homepage}`, token, { page_module });
}

export function UpdateEventFavicon(token: string, url: string, event_uuid: string): Promise<{ favicon: string }> {
	return Post(`/v3/admin/events/favicon/${event_uuid}`, token, { favicon: url });
}

export function DeleteEventFavicon(token: string, event_uuid: string): Promise<null> {
	return Delete(`/v3/admin/events/favicon/${event_uuid}`, token);
}

export function DeleteEvent(eventUuid: string, token: string): Promise<BrandliveEvent> {
	return Delete(`/v3/admin/events/event/${eventUuid}`, token);
}

export function SetSocialSettings(data: SocialSettings, eventUuid: string, token: string): Promise<SocialSettings> {
	return Post(`/v3/admin/events/set-socials-data/${eventUuid}`, token, data);
}

export function ResetRegistrations(eventId: number, token: string): Promise<void> {
	return Put('/v3/admin/events/reset-registrations', token, { eventId });
}

export function HideRegistrations(registrations: string[], eventUuid: string, token: string): Promise<any> {
	return Patch('/v3/admin/events/hide-registrations', token, { registrations, eventUuid });
}

export function UnhideRegistrations(registrations: string[], token: string): Promise<any> {
	return Patch('/v3/admin/events/unhide-registrations', token, { registrations });
}

export function UpdateEventPreviewPassword(eventPreviewUuid: string, token: string): Promise<{ password: string }> {
	return Put(`/v3/admin/events/preview-password/${eventPreviewUuid}`, token, {}, true);
}

export function SetupEventPreview(token: string, eventUuid: string, channel: number): Promise<EventPreview> {
	return Post(`/v3/admin/event/${eventUuid}/preview`, token, { channel });
}

export function UpdateSessionFeedbackSurveyText(token: string, eventUuid: string, feedbackSurveyText: IFeedbackSurveyText, languages: string[]): Promise<IFeedbackSurveyText> {
	return Put(`/v3/admin/events/settings/feedback-survey-text/${eventUuid}`, token, { feedbackSurveyText, languages }, true);
}

export function FetchGreenroomProducerMeeting(token: string, streamsSessionUuid: string): Promise<GreenroomProducerMeeting> {
	return Get(
		`/v2/greenroom/meeting/by-streams-id/${streamsSessionUuid}`,
		token,
		{},
		undefined,
		true
	);
}

export function GreenroomLogin(token: string): Promise<{ token: string }> {
	return Post(
		`/v2/greenroom/producer/login/streams`,
		token,
		undefined,
		undefined,
		{},
		undefined,
		undefined,
		true
	);
}

export const AddLeaderboardNavItem = async (token: string, eventUuid: string, homepageId: number): Promise<IHomepageMainNavItems> => {
	return Put(
		`/v3/admin/leaderboard/event/${eventUuid}/homepage/${homepageId}/navitem/create`,
		token,
		undefined,
		true,
	);
};

export const CreateTemplateEvent = async (token: string, channel: number, eventUUID: string, templateInfo: TemplateEventInfo): Promise<GetEventsResponse> => {
	return Put(`/v3/admin/channel/${channel}/template-event/${eventUUID}`, token, templateInfo, true, false, true)
		.then(res => res)
		.catch(e => { throw new Error(e); });
};

export const ValidateEventName = async (token: string, channel: number, eventName: string): Promise<{ exists: boolean }> => {
	return Post(`/v3/admin/channel/${channel}/template-event-name`, token, { name: eventName }, true, false, true, true)
		.then(res => res)
		.catch(e => { throw new Error(e); });
};
