import { useMemo } from 'react';
import { useTypedSelector } from 'store/reducers/use-typed-selector';
import { Session, SessionPlaybackVideo, SessionTypesEnum } from 'types/working-model';
import { useIsTestBroadcast } from './session-hooks';
import { useLanguageParam } from 'components/live-event/utils';
import useTimestampStatus, { ETimestampStatus } from 'utils/use-timestamp-status';
import { StreamState } from 'types/live-state';

export const useCurrentDynamicVideo = (session: Session): [SessionPlaybackVideo | undefined, string | undefined] => {
	// redux
	const liveStates = useTypedSelector(state => state.LiveEventReducer.liveStates);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const moderatorVideos = useTypedSelector(state => state.ModeratorReducer.moderatorVideos);
	// custom hooks
	const isTestBroadcast = useIsTestBroadcast(session);
	const language = useLanguageParam();
	const timestampStatus = useTimestampStatus(session);

	// setup variables
	const event = eventBundle || workingEvent;
	const prelive = timestampStatus === ETimestampStatus.preLive;
	const singleStream = session.streaming_options.single_stream;
	const defaultLanguage = session.default_language;
	const isOnDemand = session.session_type === SessionTypesEnum.onDemand;
	const sessionVideos = event?.sessionVideos ?? moderatorVideos;
	const replayOn = session.replay_on;

	// session streams for this session and language (or all)
	const sessionStreams = useMemo(() => {
		return sessionVideos?.filter(stream => {
			// if the stream is from this session
			if (stream.session_uuid === session.uuid) {
				// if singleStream is on, return videos that match the default language
				// OR are videos set to "all"
				if (singleStream) {
					return stream.language === defaultLanguage || stream.language === 'all';
				} else {
					// otherwise return videos matching the language or set to "all"
					return stream.language === language || stream.language === 'all';
				}
			}
		});
	}, [sessionVideos, session.uuid, language, singleStream, defaultLanguage]);

	// playback url for the live stream
	const livePlayback = useMemo(() => {
		return sessionStreams?.find(stream => stream.type === 'live_stream');
	}, [sessionStreams]);

	// playback url for the replay stream
	const replayPlayback = useMemo(() => {
		return sessionStreams?.find(stream => stream.type === 'replay');
	}, [sessionStreams]);

	// playback url for the user-set placeholder video
	const placeholderPlayback = useMemo(() => {
		return sessionStreams?.find(stream => stream.type === 'placeholder_video');
	}, [sessionStreams]);

	// this is an on-demand session - return the on-demand video ONLY
	if (isOnDemand) {
		return [undefined, undefined];
	}

	// live states are currently updated via the useLive hook at the session level
	// to prevent having multiple hooks fetching this since it's hit so frequently,
	// we will just grab the live state from the store here and assume that this
	// is being updated elsewhere. 
	const liveState = livePlayback?.playback_url ?
		(liveStates[livePlayback.playback_url]?.state ?? StreamState.offline) :
		StreamState.offline;
	const isLive = liveState === StreamState.live;

	// order of precedence: live, replace, placeholder
	// if the event is live and not a test broadcast, return the live playback url
	if (isLive && !isTestBroadcast) {
		return [livePlayback, livePlayback?.playback_url];
	}

	// if this is not the prelive period, return the replay URL
	if (!prelive && replayOn) {
		return [replayPlayback, livePlayback?.playback_url];
	}

	// if there is a placeholder video, return that
	return [placeholderPlayback, livePlayback?.playback_url];
};