import classNames from "classnames";
import { Suspense, useMemo, lazy } from "react";
import { useHistory } from "react-router";
import { useIsSingleSessionWithoutHome } from "../../../../../hooks/session.hooks";
import { useTranslate } from "../../../../../i18n/useTranslationModules";
import { TemplateClassNames, TemplateNames } from "../../../../../types/template-layouts";
import { BrandliveEvent, EBroadcastTypes, Session, SessionTypesEnum } from "../../../../../types/working-model";
import { OptionalComponent } from "../../../../../utils/optional-component";
import useTimestampStatus from "../../../../../utils/use-timestamp-status";
import { getTemplateClassName } from "../../../../../utils/utils";
import { ICONS } from "../../../../general-ui/icon";
import { linker } from "../../../hooks/use-route-map";
import useTranslationRoute from "../../../hooks/use-translation-route";
import { getAsset } from "../../../modules/assets/asset-map";
import SessionCountdown from "../session-countdown";
import NavigationToggle from "../../../marketing-page/navigation-v2/navigation-toggle";
import { VideoTitle } from "./components/titles";
import { ButtonIcon, ControlButton } from "./controls";
import { useScreenMediaQuery } from "../../../../../utils/use-screen-media-query";
import { StreamsActionButton } from "./components/streams-action-button";
import ActionBar from "../../session-modules/action-bar/action-bar";
import InPersonAttendeeMessage from "../in-person-attendee-message/in-person-attendee-message";
import images from '../../../../../images';
import { useParams } from "react-router-dom";

const EditBannerColor = lazy(() => import("../../../../admin/create/session/editor/edit-banner-color"));

const PrePostLivePlaceholder: React.FC<{
	isAdmin: boolean,
	session: Session,
	event: BrandliveEvent,
	moderatorView?: boolean,
	chatClosed: boolean;
	onChatClose?: (close: boolean) => void;
	isFullscreen: boolean;
	hideChatButton?: boolean;
}> = ({
	isAdmin,
	session,
	event,
	moderatorView,
	chatClosed,
	onChatClose,
	isFullscreen,
	hideChatButton = false
}) => {
		const namespace = useTranslationRoute();
		const { t } = useTranslate([namespace, "homepage", "landing", "session"]);
		const history = useHistory();
		const params = useParams();
		const { isLessThan640, isLessThan1024 } = useScreenMediaQuery();
		const isDesktop = !isLessThan1024;

		const timestampStatus = useTimestampStatus(session);
		const template = getTemplateClassName(event.template.name);
		const disableSessionThumbnails = event.settings?.disable_session_thumbnails === true;
		const streamPlaceholderBGColor = session?.layout?.styling_overrides?.content?.stream_placeholder_background_color;
		const isSingleSessionNoHomepage = useIsSingleSessionWithoutHome();

		const placeholderStyle = useMemo(() => {
			// if thumbnails are toggled off
			if (disableSessionThumbnails) {
				// use custom placeholder bg color if available
				if (streamPlaceholderBGColor?.length) {
					return { background: streamPlaceholderBGColor[0] };
				}
				// else just return empty because bg color will default to secondaryBackgroundColor via css
				return {};
			}

			const isFuture = session.timestamp && session.timestamp > Date.now();

			if (session.post_broadcast_thumbnail && !isFuture) {
				return { backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), url(${session.post_broadcast_thumbnail})` };
			} else if (session.pre_broadcast_thumbnail) {
				return { backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), url(${session.pre_broadcast_thumbnail})` };
			} else {
				const isCustomTemplate = !TemplateClassNames[event?.template.name ?? 'Apollo'];
				const defaultTemplateAsset = isCustomTemplate ? TemplateNames.Apollo : event?.template.name ?? 'Apollo';
				const isLimelight = event?.template.name === 'Limelight';
				return { backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), url(${isLimelight ? images.LimelightBanner : getAsset(defaultTemplateAsset, 'video_background')})` };
			}
		}, [
			streamPlaceholderBGColor,
			disableSessionThumbnails,
			session.timestamp,
			session.post_broadcast_thumbnail,
			session.pre_broadcast_thumbnail,
			event?.template.name
		]);

		const isFutureSession = session.timestamp && session.timestamp > Date.now().valueOf();

		const isOnDemand = session.session_type === SessionTypesEnum.onDemand;

		const lessThan1024EmbedVideo = isLessThan1024 && session?.broadcast_type === EBroadcastTypes.embed;

		if (!session) return null;
		return (
			<div
				className={
					classNames(
						"session-video-placeholder version-2",
						{
							[timestampStatus]: timestampStatus,
							'no-image': disableSessionThumbnails,
							'is-on-demand': isOnDemand,
						}
					)
				}
				style={placeholderStyle}
			>
				<OptionalComponent display={isAdmin && disableSessionThumbnails}>
					<Suspense fallback="">
						<EditBannerColor />
					</Suspense>
				</OptionalComponent>
				<OptionalComponent display={!isOnDemand}>
					<div className="session-live-card-container">
						<InPersonAttendeeMessage />

						<OptionalComponent display={!!isFutureSession}>
							<div className="live-in-card-v2">
								<SessionCountdown
									timestamp={session.timestamp}
									end_timestamp={session.end_timestamp}
									replay_on={session.replay_on}
								/>
							</div>
						</OptionalComponent>

						<VideoTitle session={session} />

						{!moderatorView && (
							<ActionBar
								session={session}
								event={event}
								template={template}
								className="session-live-card-actions"
								forceDark={true}
								isFullscreen={isFullscreen}
								includeJoinStage
							/>
						)}
					</div>
				</OptionalComponent>
				<OptionalComponent display={!moderatorView}>
					<OptionalComponent display={session.session_type === SessionTypesEnum.onDemand}>
						<InPersonAttendeeMessage className="is-on-demand" />
					</OptionalComponent>

					<div className="upper">
						<div className="left">
							<NavigationToggle
								hideToggle={false}
								isDesktop={isDesktop}
								forceOn={true}
							/>
							<OptionalComponent display={!isSingleSessionNoHomepage}>
								<ControlButton
									onClick={() => {
										history.push(linker.getLink('Home', params));
									}}
								>
									{ButtonIcon(ICONS.ARROW_LEFT_SHADOW)}
								</ControlButton>
							</OptionalComponent>
						</div>
					</div>
				</OptionalComponent>
				<OptionalComponent display={!hideChatButton && session?.session_chat_enabled !== false && !isLessThan640 && !lessThan1024EmbedVideo}>
					<div className="floating-chat-toggle">
						<StreamsActionButton
							onClick={() => {
								onChatClose?.(!chatClosed);
							}}
							icon={chatClosed ? ICONS.CHAT_DIALOG : ICONS.CHAT_DIALOG_EXIT}
							popupText={chatClosed ? t('session:Show Chat', 'Show Chat') : t('session:Hide Chat', 'Hide Chat')}
							darkIcon={false}
						/>
					</div>
				</OptionalComponent>
			</div>
		);
	};

export default PrePostLivePlaceholder;
