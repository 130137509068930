import Icon, { ICONS, COLORS } from "@general-ui/icon";
import Switch from "@general-ui/switch/switch";
import TagSelectInput from "@general-ui/tag-select/tag-select";
import { Tooltip } from "@general-ui/tooltip/tooltip";
import React, { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { BLAdmin, CreateSession } from "types/working-model";
import { OptionalComponent } from "utils/optional-component";
import { PrimaryTooltip } from "../session-modal";
import { useCanEnableMeetFiresides } from "hooks/channel.hooks";
import { useGoogleLoginHook } from "hooks/google-meet.hooks";
import { getHostUrl } from "utils/utils";

type Props = {
	workingSession: CreateSession;
	setWorkingSession: Dispatch<SetStateAction<CreateSession>>;
	handleChangeFiresideHosts: (hosts: string[]) => void;
	admins: BLAdmin[];
}
const FiresideHostsSection: React.FC<Props> = ({
	workingSession,
	handleChangeFiresideHosts,
	setWorkingSession,
	admins
}) => {
	const canEnableMeetFiresides = useCanEnableMeetFiresides();

	const {
		connectGoogleMeet,
		googleMeetCode,
		googleMeetDisabled,
		setGoogleMeetDisabled,
		googleMeetUseServiceAccount
	} = useGoogleLoginHook({
		initialMeetCode: workingSession.fireside_session_settings?.settings?.google_meet_code,
		setUseThirdPartyProvider: (isOn: boolean) => toggleFiresidesUseGoogleMeet('', isOn)
	});

	useEffect(() => {
		setWorkingSession(workingSession => ({
			...workingSession,
			fireside_session_settings: {
				...(workingSession.fireside_session_settings ?? {}),
				settings: {
					...(workingSession.fireside_session_settings ?? {})?.settings,
					google_meet_code: googleMeetCode,
					google_meet_site_url: getHostUrl(),
					google_meet_use_service_account: googleMeetUseServiceAccount
				}
			}
		}));
	}, [googleMeetCode, setWorkingSession, googleMeetUseServiceAccount]);

	const toggleFiresidesUseGoogleMeet = useCallback((_: string, isOn: boolean) => {
		if (isOn && canEnableMeetFiresides) {
			connectGoogleMeet();
			handleChangeFiresideHosts([]);
		} else {
			setGoogleMeetDisabled(true);
			handleChangeFiresideHosts(admins.map(admin => admin.email));
		}

		setWorkingSession(workingSession => ({
			...workingSession,
			fireside_session_settings: {
				...(workingSession.fireside_session_settings ?? {}),
				settings: {
					...(workingSession.fireside_session_settings ?? {})?.settings,
					use_google_meet: isOn
				}
			}
		}));
	}, [canEnableMeetFiresides, setWorkingSession, connectGoogleMeet, handleChangeFiresideHosts, setGoogleMeetDisabled, admins]);

	return (
		<>
			<OptionalComponent display={canEnableMeetFiresides}>
				<div className="single-stream-toggle left-align">
					<Switch
						value={'enable-google-meet'}
						onClick={toggleFiresidesUseGoogleMeet}
						on={!!workingSession?.fireside_session_settings?.settings?.use_google_meet}
					/>
					<label>Use Google Meet</label>
					<Tooltip
						textClassName="enable-custom-url-tooltip"
						tooltip={<>Enables Google Meet for this session. Only invited hosts will have access to start the broadcasts. Other users will be able to view the meet.</>}
						position="right"
					>
						<Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12} />
					</Tooltip>
				</div>
			</OptionalComponent>
			<div style={{ marginTop: '24px' }}>
				<TagSelectInput
					label={"Session Hosts *"}
					tooltip={[PrimaryTooltip, 'Hosts will have the ability to moderate and end sessions. Channel Admins and Moderators have host permissions by default.']}
					placeholder={"Enter host emails"}
					onChange={hosts => handleChangeFiresideHosts(hosts)}
					tags={workingSession.firesides_hosts}
					capSensitive
					noWhitespace
					errorMessage={workingSession.firesides_hosts?.length || !admins?.length ? undefined : "At least one session host required"}
					validateEmail={true}
					initializeScrolledToBottom={true}
					loading={!admins?.length && !workingSession.firesides_hosts?.length}
					lowercaseTags
				/>
			</div>
		</>
	);
};

export default FiresideHostsSection;
