import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { useDispatch } from 'react-redux';

import { useTypedSelector } from '../../../../../store/reducers/use-typed-selector';
import { BreakoutRoom, BreakoutSession, ChannelFeaturesEnum, LanguagesAbbr } from '../../../../../types/working-model';
import ButtonPlain from '../../../../general-ui/button-plain/button-plain';
import Icon, { COLORS, ICONS } from '../../../../general-ui/icon';
import Modal from '../../../../general-ui/modal/modal';
import Switch from '../../../../general-ui/switch/switch';
import TagSelectInput from '../../../../general-ui/tag-select/tag-select';
import { BreakoutRooms } from './breakout-chat-rooms/breakout-chat-rooms';
import { attendees, attendeesGoogleMeet, defaultRooms } from './breakout-chat-room-modals/breakout-chat-room-modals.config';
import WaitingIndicator from '../../../../general-ui/waiting-indicator/waiting-indicator';
import { getAdmins } from '../../../../../store/actions/admin/bl-admin';
import { SaveGoogleRefreshForBreakoutSession, removeGoogleRefreshForBreakoutSession } from '../../../../../connection/sessions';
import { getHostUrl, updateTranslateKey } from '../../../../../utils/utils';
import { GetDefaultTranslateString } from '../../../../../store/utils/create-event';
import { getDefaultLanguage } from '../../../../live-event/utils';
import { useGoogleLoginHook, useGoogleMeetPhase1 } from 'hooks/google-meet.hooks';

import './breakout-session-modal.scss';

interface Props {
	title?: string;
	onClose: (done: boolean, breakoutSession?: BreakoutSession) => void;
	initialValues?: BreakoutSession;
	isSaving?: boolean;
	language?: LanguagesAbbr;
	propsDefaultLanguage?: LanguagesAbbr;
}

const BreakoutSessionsModal: React.FC<Props> = ({
	title = 'Set breakout rooms',
	onClose,
	initialValues,
	isSaving = false,
	language,
	propsDefaultLanguage,
}: Props) => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const session_id = workingSession?.session;
	const { editingSessionAgenda } = useTypedSelector(state => state.CreateEventReducer);
	const { admins } = useTypedSelector(state => state.ChannelAdminReducer);
	const { token, channels, user } = useTypedSelector(state => state.AuthReducer);

	const [useThirdPartyBreakoutProvider, setUseThirdPartyBreakoutProvider] = useState<boolean>(initialValues?.use_google_meet || false);
	const [rooms, setRooms] = useState<BreakoutRoom[]>(initialValues?.rooms || []);
	const [sessionHosts, setSessionHosts] = useState<string[]>(initialValues?.hosts || []);
	const [createNewRoomWhenFull, setCreateNewRoomWhenFull] = useState<boolean>(initialValues ? !!initialValues.create_room_when_full : true);
	// const [googleMeetCode, setGoogleMeetCode] = useState<string | null>(initialValues?.google_meet_code || null);
	// const [googleMeetDisabled, setGoogleMeetDisabled] = useState<boolean>(false); // Track dirty state for delete trigger
	const [saveClicked, setSaveClicked] = useState<boolean>(false);
	const [scrollModalBodyToBottom, setScrollModalBodyToBottom] = useState<boolean>(false);
	const [creatingBreakouts, setCreatingBreakouts] = useState(true);

	const enableGoogleMeetBreakouts = useMemo(() => {
		if (!channels || !user?.active_channel) return false;
		const currentChannel = channels.find((channel) => channel.channel === user?.active_channel);
		return currentChannel?.enabled_features?.includes(ChannelFeaturesEnum.enable_google_meet_breakouts) ?? false;
	}, [channels, user?.active_channel]);

	const dispatch = useDispatch();

	const googleMeetPhase1Enabled = useGoogleMeetPhase1();

	const {
		googleLogin,
		googleMeetCode,
		googleMeetDisabled,
		setGoogleMeetDisabled,
		handleGoogleNoAuth
	} = useGoogleLoginHook({
		initialMeetCode: initialValues?.google_meet_code,
		setUseThirdPartyProvider: setUseThirdPartyBreakoutProvider
	});

	const removeGoogleLogin = async () => {
		if (session_id && token) {
			await removeGoogleRefreshForBreakoutSession(session_id, token);
		} else {
			console.error('no session id or token');
		}
	};

	const initBreakouts = useCallback(async () => {
		if (!rooms.length && !editingSessionAgenda?.breakout_session?.rooms) {
			setRooms(await defaultRooms(uuidV4()));
		}
		setCreatingBreakouts(false);
	}, [rooms, editingSessionAgenda]);

	useEffect(() => {
		initBreakouts();
	}, [initBreakouts]);

	useEffect(() => {
		// Channel admins, moderators, and owners are session hosts by default and start populated in the hosts input
		if (!initialValues) {
			// If the browser is refreshed during create session flow, admins aren't loaded yet (because they are loaded in events-main)
			// so load them here
			if (admins.length === 0) {
				if (token && user) {
					dispatch(getAdmins(token, user?.active_channel));
				}
			} else {
				setSessionHosts(admins.map(admin => admin.email));
			}
		}
	}, [admins, initialValues, token, user, dispatch]);

	useEffect(() => {
		if (editingSessionAgenda?.breakout_session?.rooms) {
			setRooms(editingSessionAgenda?.breakout_session?.rooms);
		}
		if (editingSessionAgenda?.breakout_session?.hosts) {
			setSessionHosts(editingSessionAgenda?.breakout_session?.hosts);
		}
		if (editingSessionAgenda?.breakout_session?.create_room_when_full) {
			setCreateNewRoomWhenFull(editingSessionAgenda?.breakout_session?.create_room_when_full);
		}
		if (editingSessionAgenda?.breakout_session?.use_google_meet) {
			setUseThirdPartyBreakoutProvider(true);
		}
	}, [editingSessionAgenda]);

	function mandatoryFieldsFilledOut() {
		// 1+ session hosts required
		if (sessionHosts.length === 0) {
			return false;
		}
		// Verify rooms created have all mandatory fields filled out
		for (let i = 0; i < rooms.length; i++) {
			if (rooms[i].name?.length === 0) {
				return false;
			}
		}
		return true;
	}

	const handleSave = async () => {
		if (mandatoryFieldsFilledOut()) {
			const breakoutSession = {} as BreakoutSession;
			breakoutSession.create_room_when_full = createNewRoomWhenFull;
			breakoutSession.hosts = sessionHosts;
			breakoutSession.rooms = rooms;
			// Google Meet Powered Breakout is allowed for enablement
			if (enableGoogleMeetBreakouts) {
				breakoutSession.use_google_meet = useThirdPartyBreakoutProvider;

				// Phase1 uses a service account rather than a user oauth token
				// No googleMeetCode will be present when phase 1 is enabled.
				// Still need to let the API know what is happening so it doesn't
				//  kick out when no user code is found.
				if (useThirdPartyBreakoutProvider && googleMeetPhase1Enabled) {
					breakoutSession.google_meet_use_service_account = true;
				}

				// BP-2155 block can be removed once phase 0 is complete
				// Removes refresh_token so it's not stored in the DB.
				if (googleMeetDisabled) { removeGoogleLogin(); }

				// Use set code that needs decoding on back end to get refresh_token
				if (googleMeetCode) {
					// Set to allow decode after save
					breakoutSession.google_meet_code = googleMeetCode;
					breakoutSession.google_meet_site_url = getHostUrl();

					// If session_id (edit, not create) just save now
					// Allow better error handling
					if (session_id && token) {
						try {
							// Save refresh now to ensure it decodes properly
							// This does not enable google meet, it only saves the token
							await SaveGoogleRefreshForBreakoutSession(
								session_id,
								googleMeetCode,
								token,
							);
						} catch (e) {
							const userMsg = 'Problem saving refresh token to server';
							const errMsg = `${userMsg}: session_id: ${session_id}`;
							console.error(errMsg);
							console.error(e);
							handleGoogleNoAuth(userMsg);

							return; // stop saving until corrected
						}
					}
				}
				// END BP-2155 block
			} else {
				breakoutSession.use_google_meet = false;
			}

			onClose(true, breakoutSession);
		} else {
			setSaveClicked(true);
		}
	};

	const PrimaryTooltip = (): JSX.Element => <Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12}></Icon>;

	const handleAdd = useCallback(async () => {
		setCreatingBreakouts(true);
		const [newRoom] = await defaultRooms(uuidV4());
		setRooms(prev => ([
			...prev,
			newRoom
		]));
		// Flipping this boolean fires a prop change in the <Modal> which scrolls the modal body to the bottom (which contains just the list of rooms)
		// therefore brining the newly added room into view
		setScrollModalBodyToBottom(prev => !prev);
		setCreatingBreakouts(false);
	}, []);

	function handleRemove(room: BreakoutRoom) {
		setRooms(prev => prev.filter(f => f.uuid !== room.uuid));
	}

	const handleAttendeesChange = useCallback((attendeesCount: string, roomIndex: number) => {
		const newRooms = [...rooms];
		newRooms[roomIndex] = {
			...newRooms[roomIndex],
			max_users: parseInt(attendeesCount)
		};
		setRooms([...newRooms]);
	}, [rooms]);

	const defaultLanguageFn = useCallback(() => workingEvent && getDefaultLanguage(workingEvent), [workingEvent]);

	const handleNameChange = (value: string, room: BreakoutRoom) => {
		const defaultLanguage = defaultLanguageFn();

		setRooms(prev => prev.map(prevRoom => ({
			...prevRoom,
			name: prevRoom?.uuid === room.uuid ? value.trim() : prevRoom.name,
			name_translations: prevRoom?.uuid === room.uuid
				? updateTranslateKey({
					translateString: prevRoom.name_translations || GetDefaultTranslateString(),
					input: value,
					baseLanguage: propsDefaultLanguage || defaultLanguage || '',
					language: language || defaultLanguage || '',
				})
				: prevRoom.name_translations,
		})));
	};

	const handleDescriptionChange = (value: string, room: BreakoutRoom) => {
		const defaultLanguage = defaultLanguageFn();
		setRooms(prev => prev.map(prevRoom => ({
			...prevRoom,
			description: prevRoom?.uuid === room.uuid ? value : prevRoom.description,
			description_translations: prevRoom?.uuid === room.uuid
				? updateTranslateKey({
					translateString: prevRoom.description_translations || GetDefaultTranslateString(),
					input: value,
					baseLanguage: propsDefaultLanguage || defaultLanguage || '',
					language: language || defaultLanguage || '',
				})
				: prevRoom.description_translations,
		})));
	};

	const handleImageChange = (value: string, room: BreakoutRoom) => {
		setRooms(prev => prev.map(prevRoom => ({
			...prevRoom,
			image: prevRoom?.uuid === room.uuid ? value : prevRoom.image,
		})));
	};

	const handleClearImage = (room: BreakoutRoom) => {
		setRooms(prev => prev.map(prevRoom => ({
			...prevRoom,
			image: prevRoom?.uuid === room.uuid ? "" : prevRoom.image,
		})));
	};

	const handleImageUploading = (uploading: boolean, room: BreakoutRoom) => {
		setRooms(prev => prev.map(prevRoom => ({
			...prevRoom,
			imageUploading: prevRoom?.uuid === room.uuid ? uploading : prevRoom.imageUploading,
		})));
	};

	const handleNameFocused = (value: boolean, room: BreakoutRoom) => {
		setRooms(prev => prev.map(prevRoom => ({
			...prevRoom,
			nameFocused: prevRoom?.uuid === room.uuid ? value : prevRoom.nameFocused,
		})));
	};

	const handleMaxUsersChange = (value: number, room: BreakoutRoom) => {
		setRooms(prev => prev.map(prevRoom => ({
			...prevRoom,
			max_users: prevRoom?.uuid === room.uuid ? value : prevRoom.max_users,
		})));
	};

	const handleToggleUseThirdParty = async (_: unknown, enableThirdParty: boolean) => {
		// Google Meet Phase 0 requires an admin to provide refresh token to create rooms.
		// Phase 1 uses a Service Account and does not require attendees to be in the same Workspace.
		// Simply enable the toggle when in Phase 1, skipping the special auth handling.
		if (!googleMeetPhase1Enabled) {
			// Defer setting third party breakout until user accepts scope when google integration is enabled.
			if (enableGoogleMeetBreakouts && enableThirdParty) {
				googleLogin();
				return;
			}

			// When disabling and google enabled, remove tokens
			if (enableGoogleMeetBreakouts) { setGoogleMeetDisabled(true); }
		}

		// Just update the state
		setUseThirdPartyBreakoutProvider(enableThirdParty);
		if (enableThirdParty) {
			// This control gets hidden, need to also update the value for consistancy
			setCreateNewRoomWhenFull(false);
		}
	};

	const scrollToBottom = () => {
		setTimeout(() => {
			setScrollModalBodyToBottom(!scrollModalBodyToBottom);
		});
	};

	const displayMaxUsers = () => {
		if (!useThirdPartyBreakoutProvider) return true;
		if (enableGoogleMeetBreakouts) return true;
		return false;
	};
	const attendeeNumberDisplay = () => {
		if (useThirdPartyBreakoutProvider && enableGoogleMeetBreakouts) return attendeesGoogleMeet;
		return attendees;
	};

	return (
		<Modal
			className="breakout-session-modal"
			open
			onRequestClose={() => onClose(false, undefined)}
			title={title}
			cancellable={false}
			closeable={false}
			scrollBodyToBottom={scrollModalBodyToBottom}
			topRightToggle={enableGoogleMeetBreakouts}
			toggleLabel="Google Meet Powered Rooms"
			toggleValue={useThirdPartyBreakoutProvider}
			onToggleClicked={handleToggleUseThirdParty}
			topRightToggleTooltipText="Toggling on Meet-Powered Rooms creates a unique Meet space for each of your breakout rooms. Attendees will have their breakout experience using Google Meet."
			// This header probably contains more here than you would expect in your average modal header. The reason being is that we have 2 dynamically height
			// sized elements in this modal (session hosts and rooms). So I moved the max height calculation into the modal as a whole (instead of the body) 
			// to allow just the modal body to be the scrollable section. The modal body contains just the rooms, nothing else.
			header={
				<div className="header">
					{(!useThirdPartyBreakoutProvider || enableGoogleMeetBreakouts) && (
						<>
							<TagSelectInput
								label={"Session Hosts *"}
								tooltip={[PrimaryTooltip, 'Hosts will have the ability to moderate and end breakout sessions. Channel Admins and Moderators have host permissions by default.']}
								placeholder={"Enter host emails"}
								onChange={hosts => setSessionHosts(hosts)}
								tags={sessionHosts}
								dropdownContainerStyles={{
									maxWidth: 'calc(100% - 80px)',
									margin: '0 auto',
								}}
								capSensitive
								noWhitespace
								errorMessage={saveClicked && sessionHosts.length === 0 ? "At least one session host required" : undefined}
								validateEmail
								initializeScrolledToBottom
								scrollbarOutsideContainer
								allowClearAll
								lowercaseTags
							/>
							<div className="divider" />
						</>
					)}
					<div className="breakout-rooms-header">
						<div className="breakout-rooms-header-title">Breakout rooms</div>
						<div className="breakout-rooms-header-buttons">
							<ButtonPlain
								icon={ICONS.ADD}
								onclick={handleAdd}
								iconSize={14}
								className="breakout-rooms-button"
							>
								Add room
							</ButtonPlain>
						</div>
					</div>

				</div>
			}
			footer={
				<div className="footer">
					<div>
						<button onClick={() => onClose(false, undefined)} style={{ marginRight: 16 }}>
							Cancel
						</button>
						<button disabled={isSaving} onClick={handleSave} className="lemonade">
							{isSaving ? <WaitingIndicator /> : 'Save'}
						</button>
					</div>
					{!useThirdPartyBreakoutProvider && (
						<div className="toggle">
							<Switch
								on={createNewRoomWhenFull}
								onClick={() => setCreateNewRoomWhenFull(!createNewRoomWhenFull)}
								value={'allow-retake'}
							/>
							<span>Create new room once room is full</span>
						</div>
					)}
				</div>
			}
		>
			<BreakoutRooms
				attendees={attendeeNumberDisplay()}
				rooms={rooms}
				onRemove={handleRemove}
				onAttendeeChange={handleAttendeesChange}
				selectInputLabel="Max. users *"
				onNameChange={handleNameChange}
				onDescriptionChange={handleDescriptionChange}
				onImageChange={handleImageChange}
				onMaxUsersChange={handleMaxUsersChange}
				onImageUploading={handleImageUploading}
				onNameFocused={handleNameFocused}
				saveClicked={saveClicked}
				lastMaxUsersOpen={scrollToBottom}
				creatingBreakouts={creatingBreakouts}
				onClearImage={handleClearImage}
				displayMaxUsers={displayMaxUsers()}
			/>
		</Modal>
	);
};
export default BreakoutSessionsModal;
