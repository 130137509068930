import { batch } from "react-redux";
import { UniqueIdentifier } from "@dnd-kit/core";

import indexedDb from 'utils/datastore/indexed-db';
import { DeleteColors, GetColors, DeleteColorsReturn } from "../../../connection/colors";
import {
	AddNewSession,
	DeleteSession,
	DuplicateSession,
	LoadEvent,
	SaveEvent,
	UpdateSessionInfo,
	UpdateSessionCardInfo,
	PublishEvent,
	PublishEventPreview,
	UpdateRegistrationSettings,
	AddPostRegisterHomePageModuleToEvent,
	AddHomepageToEvent,
	CheckEventPublishedStatus,
	UpdateEventFavicon,
	DeleteEventFavicon,
	SetSocialSettings,
	ResetRegistrations,
	UpdateEventSettings,
	UpdateEventPreviewPassword,
	SetupEventPreview,
	AddLeaderboardNavItem,
	UpdateSessionFeedbackSurveyText,
	CreateTemplateEvent,
	EditChannelTracks
} from "../../../connection/create-event";
import { DeleteFonts, GetFonts, DeleteFontsReturn } from "../../../connection/fonts";
import { GetTemplates } from "../../../connection/templates";
import { DispatchedAction, DispatchedAsyncAction } from "../../../types/actions";
import {
	BrandliveEvent,
	CreateSession,
	DesignShape,
	DesignStyle,
	HomepageFooter,
	HomepageHeader,
	IHomepageMainNavItems,
	IHomepageProfileNavItems,
	PageModule,
	RegistrationQuestion,
	Requirement,
	Session,
	Template,
	GateTypes,
	RegistrationStep,
	RegistrationStepType,
	Registration,
	SessionTabsLayout,
	TicketingSet,
	TranslateString,
	BLAdmin,
	EventHomepage,
	EventNavbar,
	CustomPage,
	SocialSettings,
	ProfileDisplayType,
	EventSettings,
	DirectoryFilter,
	IEventChatChannel,
	IScheduledEmail,
	IInsertScheduledEmailRequest,
	IEventTag,
	ChannelCustomizedIcon,
	IFeedbackSurveyText,
	MarketingOptInTypes,
	TemplateEventInfo,
	EventListSortType,
	IUpdateSessionsTracks,
	EventType,
	ENavbarTypes,
	TranslationsUpdateRequest,
	Language,
	LanguagesAbbr,
	CreateEventRequest,
	GlobalCurrency,
	EventPreview,
	EPreviewTypes,
	EventsTotals,
	GetEventsResponse,
	SessionPlaybackVideo,
} from "../../../types/working-model";
import { GetEmptyEvent } from "../../utils/create-event";
import { getEvents, UPDATE_EVENTS_NAME } from "./events";
import { GetGlobalCurrencies } from "../../../connection/integrations";
import { CreateScheduledEmail, DeleteScheduledEmail, EditScheduledEmail, GetChannelScheduledEmails, GetEventScheduledEmails } from "../../../connection/scheduled-emails";
import { AppThunk } from "../../reducers/use-typed-selector";
import { DeleteCustomPage } from "../../../connection/custom-page";
import { GetEvents, UpdateEventType, UpdateSessionsTracks, UpdateEventImage, UpdateEventPublishSettings, GetRSVPEventLink, ApproveRSVPEventLink } from "../../../connection/events";
import { TEMPLATE_EVENTS_PAGE_COUNT } from "../../../types/template-events";
import { RSVPLinkSync } from "types/rsvp";
import { showAlert } from "@general-ui/alert/alert-service";
import { EPaletteModes, FontPack, SafeFonts, ThemePack } from "types/theme-packs";

export const CREATE_NEW_EVENT = "CREATE_NEW_EVENT";
type createNewEventAction = DispatchedAction<typeof CREATE_NEW_EVENT, CreateEventRequest>;
export function createNewEvent(channel: number, template: Template): createNewEventAction {
	return {
		type: CREATE_NEW_EVENT,
		payload: GetEmptyEvent(channel, template)
	};
}

export const SET_EVENT_NAME = "SET_EVENT_NAME";
type setEventNameAction = DispatchedAction<typeof SET_EVENT_NAME, string>;
export function setEventName(name: string): setEventNameAction {
	return {
		type: SET_EVENT_NAME,
		payload: name
	};
}

export const LOAD_TEMPLATES = "LOAD_TEMPLATES";
type loadTemplatesAction = DispatchedAsyncAction<typeof LOAD_TEMPLATES, Template[]>;
export function loadTemplates(channel: number, token: string): loadTemplatesAction {
	return {
		type: LOAD_TEMPLATES,
		promise: GetTemplates(channel, token)
	};
}

export const ADD_EVENT_TEMPLATE = "ADD_EVENT_TEMPLATE";
type addEventTemplateAction = DispatchedAction<typeof ADD_EVENT_TEMPLATE, Template>;
export function addEventTemplate(template: Template): addEventTemplateAction {
	return {
		type: ADD_EVENT_TEMPLATE,
		payload: template,
	};
}

export const UPDATE_EVENT_TEMPLATE = "UPDATE_EVENT_TEMPLATE";
type updateEventTemplateAction = DispatchedAction<typeof UPDATE_EVENT_TEMPLATE, Template>;
export function updateEventTemplate(template: Template): updateEventTemplateAction {
	return {
		type: UPDATE_EVENT_TEMPLATE,
		payload: template,
	};
}

export const SET_EVENT_TEMPLATE = "SET_EVENT_TEMPLATE";
type setEventTemplateAction = DispatchedAction<typeof SET_EVENT_TEMPLATE, number>;
export function setEventTemplate(template_id: number): setEventTemplateAction {
	return {
		type: SET_EVENT_TEMPLATE,
		payload: template_id
	};
}

export const REMOVE_CUSTOM_THEMES = "REMOVE_CUSTOM_THEMES";
type removeEventCustomThemesAction = DispatchedAction<typeof REMOVE_CUSTOM_THEMES, number[]>;
export function removeEventCustomThemes(themes: number[]): removeEventCustomThemesAction {
	return {
		type: REMOVE_CUSTOM_THEMES,
		payload: themes,
	};
}

export const REMOVE_CUSTOM_TEMPLATES = "REMOVE_CUSTOM_TEMPLATES";
type removeEventCustomTemplatesAction = DispatchedAction<typeof REMOVE_CUSTOM_TEMPLATES, string[]>;
export function removeEventCustomTemplates(templates: string[]): removeEventCustomTemplatesAction {
	return {
		type: REMOVE_CUSTOM_TEMPLATES,
		payload: templates,
	};
}

export const REMOVE_CUSTOM_TEMPLATES_IS_UPDATING = "REMOVE_CUSTOM_TEMPLATES_IS_UPDATING";
type isUpdatingEventCustomTemplatesAction = DispatchedAction<typeof REMOVE_CUSTOM_TEMPLATES_IS_UPDATING, boolean>;
export function isUpdatingEventCustomTemplates(isUpdating: boolean): isUpdatingEventCustomTemplatesAction {
	return {
		type: REMOVE_CUSTOM_TEMPLATES_IS_UPDATING,
		payload: isUpdating,
	};
}

export const CREATE_TEMPLATE_EVENT = "CREATE_TEMPLATE_EVENT";
type createTemplateEventAction = DispatchedAsyncAction<typeof CREATE_TEMPLATE_EVENT, GetEventsResponse & { message?: string }>;
export function createTemplateEvent(token: string, channel: number, eventUUID: string, templateInfo: TemplateEventInfo): createTemplateEventAction {
	return {
		type: CREATE_TEMPLATE_EVENT,
		promise: CreateTemplateEvent(token, channel, eventUUID, templateInfo),
	};
}

interface ICreateNewEventSession {
	session: CreateSession;
	workingEvent: BrandliveEvent;
	active_channel: number;
	token: string;
	mulitpleSessions?: 'multi';
	user: BLAdmin;
}

// we're using redux thunk here to be able to wait for multiple calls before dispatching,
// otherwise (with redux pack and the way we're handling updates) we end up with time races between dispatches and things can be overwritten
export const ADD_HOMEPAGE_TO_WORKING_EVENT = "ADD_HOMEPAGE_TO_WORKING_EVENT";
type addHomepageToWorkingEventAction = DispatchedAction<typeof ADD_HOMEPAGE_TO_WORKING_EVENT, EventHomepage>;
export const ADD_NEW_SESSION_TO_STORE = "ADD_NEW_SESSION_TO_STORE";
type addNewSessionToStoreAction = DispatchedAction<typeof ADD_NEW_SESSION_TO_STORE, Session>;
export const createNewEventSession = (data: ICreateNewEventSession, cb?: (error?: boolean) => void): AppThunk => async (dispatch): Promise<void> => {
	try {
		const {
			session,
			workingEvent,
			active_channel,
			token,
			mulitpleSessions,
			user
		} = data;
		if (workingEvent?.event) {
			try {
				const newSession = await AddNewSession(session, workingEvent.event, workingEvent.uuid, active_channel, token);
				let homepage: EventHomepage;
				if (!workingEvent?.homepage) {
					homepage = await AddHomepageToEvent(workingEvent.uuid, token, mulitpleSessions);
				}
				// dispatch all calls to update redux in various places
				batch(() => {
					if (homepage) {
						dispatch({
							type: ADD_HOMEPAGE_TO_WORKING_EVENT,
							payload: homepage,
						});
					}
					dispatch({
						type: ADD_NEW_SESSION_TO_STORE,
						payload: newSession,
					});
					dispatch(getEvents(user.active_channel, token));
				});
			} catch (e) {
				console.error(e);
			}
		}
	} catch (e) {
		console.error(e);
	} finally {
		if (cb) cb();
	}
};

export const CREATE_NEW_SESSION = "CREATE_NEW_SESSION";
type createNewSessionAction = DispatchedAction<typeof CREATE_NEW_SESSION, CreateSession>;
export function createNewSession(newSession: CreateSession): createNewSessionAction {
	return {
		type: CREATE_NEW_SESSION,
		payload: newSession
	};
}

export const ADD_NEW_SESSION = "ADD_NEW_SESSION";
type addNewSessionAction = DispatchedAsyncAction<typeof ADD_NEW_SESSION, Session>;
export function addNewSession(
	newSession: CreateSession,
	event: number,
	eventUuid: string,
	channel: number,
	token: string,
	cb?: (success?: boolean) => void,
): addNewSessionAction {
	return {
		type: ADD_NEW_SESSION,
		promise: AddNewSession(newSession, event, eventUuid, channel, token, cb)
	};
}

export const DUPLICATE_SESSION = "DUPLICATE_SESSION";
type duplicateSessionAction = DispatchedAsyncAction<typeof DUPLICATE_SESSION, Session>;
export function duplicateSession(
	session: Session,
	event: number,
	eventUuid: string,
	token: string
): duplicateSessionAction {
	return {
		type: DUPLICATE_SESSION,
		promise: DuplicateSession(session, event, eventUuid, token)
	};
}

export const DELETE_SESSION = "DELETE_SESSION";
type deleteSessionAction = DispatchedAsyncAction<typeof DELETE_SESSION, { session: number }>;
export function deleteSession(
	sessionID: number,
	eventUuid: string,
	token: string
): deleteSessionAction {
	return {
		type: DELETE_SESSION,
		promise: DeleteSession(sessionID, eventUuid, token)
	};
}

export const REMOVE_SESSION = "REMOVE_SESSION";
type removeSessionFromCreateEventReducerAction = DispatchedAction<typeof REMOVE_SESSION, { session: number, eventUuid: string }>;
export function removeSessionFromCreateEventReducer(sessionID: number, eventUuid: string): removeSessionFromCreateEventReducerAction {
	return {
		type: REMOVE_SESSION,
		payload: { session: sessionID, eventUuid }
	};
}

export const REMOVE_EVENT = "REMOVE_EVENT";
type removeEventFromCreateEventReducerAction = DispatchedAction<typeof REMOVE_EVENT, string>;
export function removeEventFromCreateEventReducer(eventUuid: string): removeEventFromCreateEventReducerAction {
	return {
		type: REMOVE_EVENT,
		payload: eventUuid
	};
}

export const UPDATE_SESSION_INFO = "UPDATE_SESSION_INFO";
type updateSessionAction = DispatchedAsyncAction<typeof UPDATE_SESSION_INFO, Session> | DispatchedAsyncAction<typeof UPDATE_SESSION_CARD, Session>;
export function updateSessionInfo(
	session: Session,
	token: string
): updateSessionAction {
	return {
		type: UPDATE_SESSION_INFO,
		promise: UpdateSessionInfo(session, token)
	};
}

// stripped down alternative to updateSessionInfo for quicker processing of the basics
// doesn't reload the event after saving, doesn't deal with session modules, only updates limited session info (currently title, description, image)
export const UPDATE_SESSION_CARD = "UPDATE_SESSION_CARD";
type updateSessionCardAction = DispatchedAsyncAction<typeof UPDATE_SESSION_CARD, Session>;
export function updateSessionCard(
	session: Session,
	token: string
): updateSessionAction {
	return {
		type: UPDATE_SESSION_CARD,
		promise: UpdateSessionCardInfo(session, token)
	};
}

export const SET_WORKING_EVENT = "SET_WORKING_EVENT";
type setWorkingEventAction = DispatchedAction<typeof SET_WORKING_EVENT, BrandliveEvent>;
export function setWorkingEvent(newEvent: BrandliveEvent): setWorkingEventAction {
	return {
		type: SET_WORKING_EVENT,
		payload: newEvent
	};
}

export const SET_SELECTED_EVENT = "SET_SELECTED_EVENT";
type setSelectedEventAction = DispatchedAction<typeof SET_SELECTED_EVENT, BrandliveEvent>;
export function setSelectedEvent(event: BrandliveEvent): setSelectedEventAction {
	return {
		type: SET_SELECTED_EVENT,
		payload: event
	};
}

export const CLEAR_SELECTED_EVENT = "CLEAR_SELECTED_EVENT";
type clearSelectedEventAction = DispatchedAction<typeof CLEAR_SELECTED_EVENT, null>;
export function clearSelectedEvent(): clearSelectedEventAction {
	return {
		type: CLEAR_SELECTED_EVENT,
		payload: null
	};
}

export const CLEAR_WORKING_EVENT = "CLEAR_WORKING_EVENT";
type clearWorkingEventAction = DispatchedAction<typeof CLEAR_WORKING_EVENT, null>;
export function clearWorkingEvent(): clearWorkingEventAction {
	return {
		type: CLEAR_WORKING_EVENT,
		payload: null
	};
}

export const CLEAR_PUBLISHED_URL = "CLEAR_PUBLISHED_URL";
type clearPublishedUrlAction = DispatchedAction<typeof CLEAR_PUBLISHED_URL, null>;
export function clearPublishedUrl(): clearPublishedUrlAction {
	return {
		type: CLEAR_PUBLISHED_URL,
		payload: null,
	};
}

export const BLUR_EDITOR = "BLUR_EDITOR";
type blurEditorAction = DispatchedAction<typeof BLUR_EDITOR, { shouldBlur: boolean }>;
export function blurEditor(shouldBlur: boolean): blurEditorAction {
	return {
		type: BLUR_EDITOR,
		payload: { shouldBlur },
	};
}

export const MODAL_IN_EDITOR = "MODAL_IN_EDITOR";
type modalInEditorAction = DispatchedAction<typeof MODAL_IN_EDITOR, boolean>;
export function modalInEditor(modalInEditor: boolean): modalInEditorAction {
	return {
		type: MODAL_IN_EDITOR,
		payload: modalInEditor
	};
}

export const LOAD_WORKING_EVENT = "LOAD_WORKING_EVENT";
type loadWorkingEventAction = DispatchedAsyncAction<typeof LOAD_WORKING_EVENT, BrandliveEvent & { error?: number }>;
export function loadWorkingEvent(uuid: string, token: string): loadWorkingEventAction {
	return {
		type: LOAD_WORKING_EVENT,
		promise: LoadEvent(uuid, token),
	};
}

export const ABORT_WORKING_EVENT_LOAD = "ABORT_WORKING_EVENT_LOAD";
type abortWorkingEventLoadAction = DispatchedAction<typeof ABORT_WORKING_EVENT_LOAD, undefined>;
export function abortWorkingEventLoad(): abortWorkingEventLoadAction {
	return {
		type: ABORT_WORKING_EVENT_LOAD,
		payload: undefined
	};
}

export const IS_UPDATING_SETTINGS = 'IS_UPDATING_SETTINGS';
type isUpdatingSettingsAction = DispatchedAction<typeof IS_UPDATING_SETTINGS, boolean>;
export function isUpdatingSettings(is_updating: boolean): isUpdatingSettingsAction {
	return {
		type: IS_UPDATING_SETTINGS,
		payload: is_updating
	};
}

// refactored this to use thunk instead of adding `UPDATE_EVENT_SETTINGS` to the reducer save list
// because there are a few places that rely on the saving isUpdatingSettings and isSavingEvent and it's just
// easier to update this one one action rather than all the files that use the functionality
export const UPDATE_EVENT_SETTINGS = "UPDATE_EVENT_SETTINGS";
type updateEventSettingsAction = DispatchedAction<typeof UPDATE_EVENT_SETTINGS, EventSettings>;
export function updateEventSettings(token: string, event_uuid: string, settings: EventSettings): AppThunk {
	return async function(dispatch): Promise<void> {
		try {
			batch(() => {
				dispatch(isUpdatingSettings(true));
				dispatch(isSavingEvent(true));
			});
			const result = await UpdateEventSettings(token, event_uuid, settings);
			dispatch({
				type: UPDATE_EVENT_SETTINGS,
				payload: result,
			});
		} catch (e) {
			console.error(e);
		} finally {
			batch(() => {
				dispatch(isUpdatingSettings(false));
				dispatch(isSavingEvent(false));
			});
		}
	};
}

export const UPDATE_NATIVE_REGISTRATION = "UPDATE_NATIVE_REGISTRATION";
type updateNativeRegistrationAction = DispatchedAction<typeof UPDATE_NATIVE_REGISTRATION, { isOn: boolean, url: string | null }>;
export function updateNativeRegistration(isOn: boolean, url: string | null): updateNativeRegistrationAction {
	return {
		type: UPDATE_NATIVE_REGISTRATION,
		payload: { isOn, url }
	};
}

export const UPDATE_REGISTRATION_LIMIT = "UPDATE_REGISTRATION_LIMIT";
type updateRegistrationLimitAction = DispatchedAction<typeof UPDATE_REGISTRATION_LIMIT, { isOn: boolean, limit: number }>;
export function updateRegistrationLimit(isOn: boolean, limit: number): updateRegistrationLimitAction {
	return {
		type: UPDATE_REGISTRATION_LIMIT,
		payload: { isOn, limit }
	};
}

export const SET_REGISTRATION_ON = "SET_REGISTRATION_ON";
type setRegistrationOnAction = DispatchedAction<typeof SET_REGISTRATION_ON, boolean>;
export function setRegistrationOn(on: boolean): setRegistrationOnAction {
	return {
		type: SET_REGISTRATION_ON,
		payload: on
	};
}

export const SET_REGISTRATION_TYPE = "SET_REGISTRATION_TYPE";
type setRegistrationTypeAction = DispatchedAction<typeof SET_REGISTRATION_TYPE, number>;
export function setRegistrationType(type: number): setRegistrationTypeAction {
	return {
		type: SET_REGISTRATION_TYPE,
		payload: type
	};
}

export const UPDATE_REGISTRATION_SETTINGS = "UPDATE_REGISTRATION_SETTINGS";
type updateRegistrationSettingsAction = DispatchedAsyncAction<typeof UPDATE_REGISTRATION_SETTINGS, Registration>;
export function updateRegistrationSettings(token: string, event_uuid: string, settings: Registration): updateRegistrationSettingsAction {
	return {
		type: UPDATE_REGISTRATION_SETTINGS,
		promise: UpdateRegistrationSettings(token, event_uuid, settings)
	};
}

export const ADD_REGISTRATION_REQUIREMENT = "ADD_REGISTRATION_REQUIREMENT";
type addRegistrationRequirementAction = DispatchedAction<typeof ADD_REGISTRATION_REQUIREMENT, { type: GateTypes, newRequirement: Requirement }>;
export function addRegistrationRequirement(type: GateTypes, newRequirement: Requirement): addRegistrationRequirementAction {
	return {
		type: ADD_REGISTRATION_REQUIREMENT,
		payload: {
			type,
			newRequirement,
		}
	};
}

export const REMOVE_REGISTRATION_REQUIREMENT = "REMOVE_REGISTRATION_REQUIREMENT";
type removeRegistrationRequirementAction = DispatchedAction<typeof REMOVE_REGISTRATION_REQUIREMENT, { type: GateTypes, passcodeListIndex: number | undefined }>;
export function removeRegistrationRequirement(type: GateTypes, passcodeListIndex?: number): removeRegistrationRequirementAction {
	return {
		type: REMOVE_REGISTRATION_REQUIREMENT,
		payload: {
			type,
			passcodeListIndex
		}
	};
}

export const UPDATE_REGISTRATION_REQUIREMENT = "UPDATE_REGISTRATION_REQUIREMENT";
type updateRegistrationRequirementAction = DispatchedAction<typeof UPDATE_REGISTRATION_REQUIREMENT, { type: GateTypes, updatedRequirement: Requirement, passcodeListIndex?: number }>;
export function updateRegistrationRequirement(
	type: GateTypes,
	updatedRequirement: Requirement,
	passcodeListIndex?: number,
): updateRegistrationRequirementAction {
	return {
		type: UPDATE_REGISTRATION_REQUIREMENT,
		payload: { type, passcodeListIndex, updatedRequirement }
	};
}

export const TOGGLE_PASSWORD_GATING = "TOGGLE_PASSWORD_GATING";
type togglePasswordGatingAction = DispatchedAction<typeof TOGGLE_PASSWORD_GATING, boolean>;
export function togglePasswordGating(isOn: boolean): togglePasswordGatingAction {
	return {
		type: TOGGLE_PASSWORD_GATING,
		payload: isOn
	};
}

export const SET_REGISTRATION_FEATURE = "SET_REGISTRATION_FEATURE";
type setRegistrationFeatureAction = DispatchedAction<typeof SET_REGISTRATION_FEATURE, { feature: string, on: boolean }>;
export function setRegistrationFeature(feature: string, on: boolean): setRegistrationFeatureAction {
	return {
		type: SET_REGISTRATION_FEATURE,
		payload: { feature, on }
	};
}

export const SET_CUSTOM_PASSCODE_LABEL = "SET_CUSTOM_PASSCODE_LABEL";
type setCustomPasscodeLabelAction = DispatchedAction<typeof SET_CUSTOM_PASSCODE_LABEL, TranslateString>;
export function setCustomPasscodeLabel(label: TranslateString): setCustomPasscodeLabelAction {
	return {
		type: SET_CUSTOM_PASSCODE_LABEL,
		payload: label
	};
}

export const SET_REJECTED_PASSCODE_MESSAGE = "SET_REJECTED_PASSCODE_MESSAGE";
type setRejectedPasscodeMessageAction = DispatchedAction<typeof SET_REJECTED_PASSCODE_MESSAGE, TranslateString>;
export function setRejectedPasscodeMessage(label: TranslateString): setRejectedPasscodeMessageAction {
	return {
		type: SET_REJECTED_PASSCODE_MESSAGE,
		payload: label
	};
}

export const SET_REGISTRATION_SINGLE_SIGN_ON = "SET_REGISTRATION_SINGLE_SIGN_ON";
type setRegistrationSingleSignOnAction = DispatchedAction<typeof SET_REGISTRATION_SINGLE_SIGN_ON, boolean>;
export function setRegistrationSingleSignOn(enabled: boolean): setRegistrationSingleSignOnAction {
	return {
		type: SET_REGISTRATION_SINGLE_SIGN_ON,
		payload: enabled
	};
}

export const CHECK_REGISTRATION_SINGLE_SIGN_ON = "CHECK_REGISTRATION_SINGLE_SIGN_ON";
type checkRegistrationSingleSignOnAction = DispatchedAction<typeof CHECK_REGISTRATION_SINGLE_SIGN_ON, undefined>;
export function checkRegistrationSingleSignOn(): checkRegistrationSingleSignOnAction {
	return {
		type: CHECK_REGISTRATION_SINGLE_SIGN_ON,
		payload: undefined
	};
}

export const SET_REGISTRATION_SSO_DISPLAY_TOP = "SET_REGISTRATION_SSO_DISPLAY_TOP";
type setRegistrationSSODisplayTopAction = DispatchedAction<typeof SET_REGISTRATION_SSO_DISPLAY_TOP, boolean>;
export function setRegistrationSSODisplayTop(isOn: boolean): setRegistrationSSODisplayTopAction {
	return {
		type: SET_REGISTRATION_SSO_DISPLAY_TOP,
		payload: isOn
	};
}

export const SET_REGISTRATION_SINGLE_SIGN_ON_TYPES = "SET_REGISTRATION_SINGLE_SIGN_ON_TYPES";
type setRegistrationSingleSignOnTypesAction = DispatchedAction<typeof SET_REGISTRATION_SINGLE_SIGN_ON_TYPES, string[]>;
export function setRegistrationSingleSignOnTypes(singleSignOns: string[]): setRegistrationSingleSignOnTypesAction {
	return {
		type: SET_REGISTRATION_SINGLE_SIGN_ON_TYPES,
		payload: singleSignOns
	};
}

export const TOGGLE_REGISTRATION_QUESTION_REQUIRED = "TOGGLE_REGISTRATION_QUESTION_REQUIRED";
type toggleRegistrationQuestionRequiredAction = DispatchedAction<typeof TOGGLE_REGISTRATION_QUESTION_REQUIRED, { question: number, required: boolean }>;
export function toggleRegistrationQuestionRequired(question: number, required: boolean): toggleRegistrationQuestionRequiredAction {
	return {
		type: TOGGLE_REGISTRATION_QUESTION_REQUIRED,
		payload: { question, required }
	};
}

export const SET_REGISTRATION_QUESTIONS = "SET_REGISTRATION_QUESTIONS";
type setRegistrationQuestionsAction = DispatchedAction<typeof SET_REGISTRATION_QUESTIONS, { questions: RegistrationQuestion[], required_questions: number[] }>;
export function setRegistrationQuestions(questions: RegistrationQuestion[], required_questions: number[]): setRegistrationQuestionsAction {
	return {
		type: SET_REGISTRATION_QUESTIONS,
		payload: { questions, required_questions }
	};
}

export const CHOOSE_REGISTRATION_STEP = "CHOOSE_REGISTRATION_STEP";
type chooseRegistrationStepAction = DispatchedAction<typeof CHOOSE_REGISTRATION_STEP, RegistrationStepType>;
export function chooseRegistrationStep(step: RegistrationStepType): chooseRegistrationStepAction {
	return {
		type: CHOOSE_REGISTRATION_STEP,
		payload: step
	};
}

export const TOGGLE_CUSTOMIZE_FORM_MODAL = "OPEN_CUSTOMIZE_FORM_MODAL";
type toggleCustomizeFormModalAction = DispatchedAction<typeof TOGGLE_CUSTOMIZE_FORM_MODAL, boolean>;
export function toggleCustomizeFormModal(open: boolean): toggleCustomizeFormModalAction {
	return {
		type: TOGGLE_CUSTOMIZE_FORM_MODAL,
		payload: open
	};
}

export const UPDATE_REGISTRATION_STEPS = "UPDATE_REGISTRATION_STEPS";
interface IUpdateRegStepsOptions {
	makeNameOptional?: boolean;
	requiredQuestions?: number[];
	registrationQuestionsToAppend?: {
		step: RegistrationStepType;
		questions: RegistrationQuestion[];
	};
}
type updateRegistrationStepsAction = DispatchedAction<typeof UPDATE_REGISTRATION_STEPS, { steps: RegistrationStep[], options?: IUpdateRegStepsOptions }>;
export function updateRegistrationSteps(steps: RegistrationStep[], options?: IUpdateRegStepsOptions): updateRegistrationStepsAction {
	return {
		type: UPDATE_REGISTRATION_STEPS,
		payload: { steps, options }
	};
}

export const UPDATE_REQUIRED_REGISTRATION_QUESTIONS = "UPDATE_REQUIRED_REGISTRATION_QUESTIONS";
type updateRequiredQuestionsAction = DispatchedAction<typeof UPDATE_REQUIRED_REGISTRATION_QUESTIONS, number[]>;
export function updateRequiredQuestions(requiredQuestions: number[]): updateRequiredQuestionsAction {
	return {
		type: UPDATE_REQUIRED_REGISTRATION_QUESTIONS,
		payload: requiredQuestions
	};
}

export const LOAD_FONT_PACKS = "LOAD_FONT_PACKS";
type loadFontPacksAction = DispatchedAsyncAction<typeof LOAD_FONT_PACKS, FontPack[]>;
export function loadFontPacks(channel: number, token: string): loadFontPacksAction {
	return {
		type: LOAD_FONT_PACKS,
		promise: GetFonts(channel, token)
	};
}

export const SET_SELECTED_FONT = "SET_SELECTED_FONT";
type setSelectedFontAction = DispatchedAction<typeof SET_SELECTED_FONT, FontPack>;
export function setSelectedFont(font: FontPack): setSelectedFontAction {
	return {
		type: SET_SELECTED_FONT,
		payload: font
	};
}

export const ADD_NEW_FONT_PACK = "ADD_NEW_FONT_PACK";
type addNewFontPackAction = DispatchedAction<typeof ADD_NEW_FONT_PACK, FontPack>;
export function addNewFontPack(font: FontPack): addNewFontPackAction {
	return {
		type: ADD_NEW_FONT_PACK,
		payload: font
	};
}

export const DELETE_FONT_PACKS = "DELETE_FONT_PACKS";
type deleteFontsAction = DispatchedAsyncAction<typeof DELETE_FONT_PACKS, DeleteFontsReturn>;
export function deleteFonts(fontPackIDs: number[], token: string, channel: number): deleteFontsAction {
	return {
		type: DELETE_FONT_PACKS,
		promise: DeleteFonts(fontPackIDs, token, channel)
	};
}

export const LOAD_COLOR_PACKS = "LOAD_COLOR_PACKS";
type loadColorPacksAction = DispatchedAsyncAction<typeof LOAD_COLOR_PACKS, ThemePack[]>;
export function loadColorPacks(token: string, channel: number): loadColorPacksAction {
	return {
		type: LOAD_COLOR_PACKS,
		promise: GetColors(token, channel)
	};
}

export const SET_COLOR_PACKS = "SET_COLOR_PACKS";
type setColorPacksAction = DispatchedAction<typeof SET_COLOR_PACKS, ThemePack[]>;
export function setColorPacks(colorPacks: ThemePack[]): setColorPacksAction {
	return {
		type: SET_COLOR_PACKS,
		payload: colorPacks,
	};
}

export const SET_SELECTED_COLOR = "SET_SELECTED_COLOR";
type setSelectedColorAction = DispatchedAction<typeof SET_SELECTED_COLOR, ThemePack>;
export function setSelectedColor(color: ThemePack): setSelectedColorAction {
	return {
		type: SET_SELECTED_COLOR,
		payload: color
	};
}

export const UPDATE_EMAIL_COLOR = "UPDATE_EMAIL_COLOR";
type updateEmailColorAction = DispatchedAction<typeof UPDATE_EMAIL_COLOR, ThemePack>;
export function updateEmailColor(color: ThemePack): updateEmailColorAction {
	return {
		type: UPDATE_EMAIL_COLOR,
		payload: color
	};
}

export const UPDATE_EMAIL_FONT = "UPDATE_EMAIL_FONT";
type updateEmailFontAction = DispatchedAction<typeof UPDATE_EMAIL_FONT, SafeFonts>;
export function updateEmailFont(font: SafeFonts): updateEmailFontAction {
	return {
		type: UPDATE_EMAIL_FONT,
		payload: font,
	};
}

export const ADD_NEW_COLOR_PACK = "ADD_NEW_COLOR_PACK";
type addNewColorPackAction = DispatchedAction<typeof ADD_NEW_COLOR_PACK, ThemePack>;
export function addNewColorPack(color: ThemePack): addNewColorPackAction {
	return {
		type: ADD_NEW_COLOR_PACK,
		payload: color
	};
}

export const SAVE_COLOR_PACK = "SAVE_COLOR_PACK";
type saveColorPackAction = DispatchedAction<typeof SAVE_COLOR_PACK, ThemePack>;
export function saveColorPack(color: ThemePack): saveColorPackAction {
	return {
		type: SAVE_COLOR_PACK,
		payload: color
	};
}

export const DELETE_COLOR_PACKS = "DELETE_COLOR_PACKS";
type deleteColorsAction = DispatchedAsyncAction<typeof DELETE_COLOR_PACKS, DeleteColorsReturn>;
export function deleteColors(colorPackIDs: number[], token: string, channel: number): deleteColorsAction {
	return {
		type: DELETE_COLOR_PACKS,
		promise: DeleteColors(colorPackIDs, token, channel)
	};
}

export const SET_DESIGN_STYLE = "SET_DESIGN_STYLE";
type setDesignStyleAction = DispatchedAction<typeof SET_DESIGN_STYLE, DesignStyle>;
export function setDesignStyle(style: DesignStyle): setDesignStyleAction {
	return {
		type: SET_DESIGN_STYLE,
		payload: style
	};
}

export const SET_DESIGN_SHAPE = "SET_DESIGN_SHAPE";
type setDesignShapeAction = DispatchedAction<typeof SET_DESIGN_SHAPE, DesignShape>;
export function setDesignShape(shape: DesignShape): setDesignShapeAction {
	return {
		type: SET_DESIGN_SHAPE,
		payload: shape
	};
}

export const SET_DESIGN_COLORS = "SET_DESIGN_COLORS";
type setDesignColorsAction = DispatchedAction<typeof SET_DESIGN_COLORS, ThemePack>;
export function setDesignColors(colors: ThemePack): setDesignColorsAction {
	return {
		type: SET_DESIGN_COLORS,
		payload: colors
	};
}

export const SET_CUSTOM_CSS = "SET_CUSTOM_CSS";
type setCustomCSSAction = DispatchedAction<typeof SET_CUSTOM_CSS, string>;
export function setCustomCSS(css: string): setCustomCSSAction {
	return {
		type: SET_CUSTOM_CSS,
		payload: css
	};
}

export const TOGGLE_HOMEPAGE_MODULE = "TOGGLE_HOMEPAGE_MODULE";
type toggleHomepageModuleAction = DispatchedAction<typeof TOGGLE_HOMEPAGE_MODULE, PageModule>;
export function toggleHomepageModule(module: PageModule): toggleHomepageModuleAction {
	return {
		type: TOGGLE_HOMEPAGE_MODULE,
		payload: module
	};
}

export const TOGGLE_POST_REGISTER_HOME_MODULE = "TOGGLE_POST_REGISTER_HOME_MODULE";
type togglePostRegisterPreviewHomeModuleAction = DispatchedAction<typeof TOGGLE_POST_REGISTER_HOME_MODULE, { moduleId: number, is_on: boolean }>;
export function togglePostRegisterPreviewHomeModule(moduleId: number, is_on: boolean): togglePostRegisterPreviewHomeModuleAction {
	return {
		type: TOGGLE_POST_REGISTER_HOME_MODULE,
		payload: { moduleId, is_on },
	};
}

export const SET_HOMEPAGE_MODULES = "SET_HOMEPAGE_MODULES";
type setHomepageModulesAction = DispatchedAction<typeof SET_HOMEPAGE_MODULES, PageModule[]>;
export function setHomepageModules(modules: PageModule[]): setHomepageModulesAction {
	return {
		type: SET_HOMEPAGE_MODULES,
		payload: modules
	};
}

export const RESET_HOMEPAGE_MODULES = "RESET_HOMEPAGE_MODULES";
type resetHomepageModulesAction = DispatchedAction<typeof RESET_HOMEPAGE_MODULES, PageModule[]>;
export function resetHomepageModules(modules: PageModule[]): resetHomepageModulesAction {
	return {
		type: RESET_HOMEPAGE_MODULES,
		payload: modules
	};
}

export const SET_MODULE_ORDER = "SET_MODULE_ORDER";
type setModuleOrderAction = DispatchedAction<typeof SET_MODULE_ORDER, number[]>;
export function setModuleOrder(newOrder: number[]): setModuleOrderAction {
	return {
		type: SET_MODULE_ORDER,
		payload: newOrder
	};
}

export const UPDATE_HOMEPAGE_MODULE = "UPDATE_HOMEPAGE_MODULE";
type updateHomepageModuleAction = DispatchedAction<typeof UPDATE_HOMEPAGE_MODULE, PageModule>;
export function updateHomepageModule(module: PageModule): updateHomepageModuleAction {
	return {
		type: UPDATE_HOMEPAGE_MODULE,
		payload: module
	};
}

export const UPDATE_HOMEPAGE_HEADER = "UPDATE_HOMEPAGE_HEADER";
type updateHomepageHeaderAction = DispatchedAction<typeof UPDATE_HOMEPAGE_HEADER, HomepageHeader>;
export function updateHomepageHeader(header: HomepageHeader): updateHomepageHeaderAction {
	return {
		type: UPDATE_HOMEPAGE_HEADER,
		payload: header
	};
}

export const UPDATE_HOMEPAGE_MAIN_NAV = "UPDATE_HOMEPAGE_MAIN_NAV";
type updateHomepageMainNavAction = DispatchedAction<typeof UPDATE_HOMEPAGE_MAIN_NAV, IHomepageMainNavItems[]>;
export function updateHomepageMainNav(navItems: IHomepageMainNavItems[]): updateHomepageMainNavAction {
	return {
		type: UPDATE_HOMEPAGE_MAIN_NAV,
		payload: navItems
	};
}

export const UPDATE_HOMEPAGE_PROFILE_NAV = "UPDATE_HOMEPAGE_PROFILE_NAV";
type updateHomepageProfileNavAction = DispatchedAction<typeof UPDATE_HOMEPAGE_PROFILE_NAV, IHomepageProfileNavItems[]>;
export function updateHomepageProfileNav(navItems: IHomepageProfileNavItems[]): updateHomepageProfileNavAction {
	return {
		type: UPDATE_HOMEPAGE_PROFILE_NAV,
		payload: navItems
	};
}

export const UPDATE_NAVBAR_TYPE = "UPDATE_NAVBAR_TYPE";
type updateNavbarTypeAction = DispatchedAction<typeof UPDATE_NAVBAR_TYPE, ENavbarTypes>;
export function updateNavbarType(navbarType: ENavbarTypes): updateNavbarTypeAction {
	return {
		type: UPDATE_NAVBAR_TYPE,
		payload: navbarType
	};
}

export const UPDATE_NAVBAR_IS_STICKY = "UPDATE_NAVBAR_IS_STICKY";
type updateNavbarIsStickyAction = DispatchedAction<typeof UPDATE_NAVBAR_IS_STICKY, boolean>;
export function updateNavbarIsSticky(isSticky: boolean): updateNavbarIsStickyAction {
	return {
		type: UPDATE_NAVBAR_IS_STICKY,
		payload: isSticky
	};
}

export const UPDATE_NAVBAR_BLUR_LEVEL = "UPDATE_NAVBAR_BLUR_LEVEL";
type updateNavbarBlurLevelAction = DispatchedAction<typeof UPDATE_NAVBAR_BLUR_LEVEL, number>;
export function updateNavbarBlurLevel(blurLevel: number): updateNavbarBlurLevelAction {
	return {
		type: UPDATE_NAVBAR_BLUR_LEVEL,
		payload: blurLevel
	};
}

export const UPDATE_POST_REGISTER_HOME_MODULES = "UPDATE_POST_REGISTER_HOME_MODULES";
type updatePostRegisterHomeModulesAction = DispatchedAction<typeof UPDATE_POST_REGISTER_HOME_MODULES, PageModule[]>;
export function updatePostRegisterHomeModules(postRegisterHomepageModules: PageModule[]): updatePostRegisterHomeModulesAction {
	return {
		type: UPDATE_POST_REGISTER_HOME_MODULES,
		payload: postRegisterHomepageModules,
	};
}

export const SET_BLOCKED_DOMAIN_EMAILS = "SET_BLOCKED_DOMAIN_EMAILS";
type setBlockedDomainEmailsAction = DispatchedAction<typeof SET_BLOCKED_DOMAIN_EMAILS, string[]>;
export function setBlockedDomainEmails(domains: string[]): setBlockedDomainEmailsAction {
	return {
		type: SET_BLOCKED_DOMAIN_EMAILS,
		payload: domains
	};
}

export const UPDATE_HOMEPAGE_FOOTER = "UPDATE_HOMEPAGE_FOOTER";
type updateHomepageFooterAction = DispatchedAction<typeof UPDATE_HOMEPAGE_FOOTER, HomepageFooter>;
export function updateHomepageFooter(footer: HomepageFooter): updateHomepageFooterAction {
	return {
		type: UPDATE_HOMEPAGE_FOOTER,
		payload: footer
	};
}

export const ADD_PAGE_MODULE = "ADD_PAGE_MODULE";
type addPageModuleAction = DispatchedAction<typeof ADD_PAGE_MODULE, PageModule>;
export function addPageModule(page_module: PageModule): addPageModuleAction {
	return {
		type: ADD_PAGE_MODULE,
		payload: page_module
	};
}

export const ADD_POST_REGISTER_HOME_PAGE_MODULE = "ADD_POST_REGISTER_HOME_PAGE_MODULE";
type addPostRegisterHomePageModuleAction = DispatchedAsyncAction<typeof ADD_POST_REGISTER_HOME_PAGE_MODULE, PageModule>;
export function addPostRegisterHomePageModule(token: string, homepage: number, page_module: PageModule): addPostRegisterHomePageModuleAction {
	return {
		type: ADD_POST_REGISTER_HOME_PAGE_MODULE,
		promise: AddPostRegisterHomePageModuleToEvent(token, homepage, page_module),
	};
}

export const SET_REGISTRATION_HEADER = "SET_REGISTRATION_HEADER";
type setRegistrationHeaderAction = DispatchedAction<typeof SET_REGISTRATION_HEADER, TranslateString>;
export function setRegistrationHeader(text: TranslateString): setRegistrationHeaderAction {
	return {
		type: SET_REGISTRATION_HEADER,
		payload: text
	};
}

export const SET_REGISTRATION_DESCRIPTION = "SET_REGISTRATION_DESCRIPTION";
type setRegistrationDescriptionAction = DispatchedAction<typeof SET_REGISTRATION_DESCRIPTION, TranslateString>;
export function setRegistrationDescription(text: TranslateString): setRegistrationDescriptionAction {
	return {
		type: SET_REGISTRATION_DESCRIPTION,
		payload: text
	};
}

export const SET_REGISTRATION_IMAGE = "SET_REGISTRATION_IMAGE";
type setRegistrationImageAction = DispatchedAction<typeof SET_REGISTRATION_IMAGE, string>;
export function setRegistrationImage(url: string): setRegistrationImageAction {
	return {
		type: SET_REGISTRATION_IMAGE,
		payload: url
	};
}

export const SET_REGISTRATION_LOGO = "SET_REGISTRATION_LOGO";
type setRegistrationLogoAction = DispatchedAction<typeof SET_REGISTRATION_LOGO, string>;
export function setRegistrationLogo(url: string): setRegistrationLogoAction {
	return {
		type: SET_REGISTRATION_LOGO,
		payload: url
	};
}

export const REMOVE_IN_CREATION_PROGRESS_SESSION = "REMOVE_IN_CREATION_PROGRESS_SESSION";
type removeInCreationProgressSessionAction = DispatchedAction<typeof REMOVE_IN_CREATION_PROGRESS_SESSION, string | null>;
export function removeInCreationProgressSession(uuid: string | null): removeInCreationProgressSessionAction {
	return {
		type: REMOVE_IN_CREATION_PROGRESS_SESSION,
		payload: uuid
	};
}

export const EDIT_SESSION_AGENDA = "EDIT_SESSION_AGENDA";
type editSessionAgendaAction = DispatchedAction<typeof EDIT_SESSION_AGENDA, string | null>;
export function editSessionAgenda(uuid: string | null): editSessionAgendaAction {
	return {
		type: EDIT_SESSION_AGENDA,
		payload: uuid
	};
}

export const UPDATE_EDITING_SESSION_BY_UID = "UPDATE_EDITING_SESSION_BY_UID";
type updateEditingSessionByUidAction = DispatchedAction<typeof UPDATE_EDITING_SESSION_BY_UID, CreateSession>;
export function updateEditingSessionByUid(payload: CreateSession): updateEditingSessionByUidAction {
	return {
		type: UPDATE_EDITING_SESSION_BY_UID,
		payload
	};
}

export const CLEAR_NEW_EVENT_REQUEST = "CLEAR_NEW_EVENT_REQUEST";
type clearNewEventRequestAction = DispatchedAction<typeof CLEAR_NEW_EVENT_REQUEST, null>;
export function clearNewEventRequest(payload: null): clearNewEventRequestAction {
	return {
		type: CLEAR_NEW_EVENT_REQUEST,
		payload
	};
}

export const UPDATE_WORKING_EVENT = "UPDATE_WORKING_EVENT";
type updateWorkingEventAction = DispatchedAction<typeof UPDATE_WORKING_EVENT, BrandliveEvent> | DispatchedAction<typeof UPDATE_EVENTS_NAME, BrandliveEvent>;
export function updateWorkingEvent(
	event: BrandliveEvent,
	token: string,
	optimistic = false,
	cb?: ((error?: unknown, resetStore?: ((event: BrandliveEvent) => void)) => void),
): AppThunk {
	return async function(dispatch): Promise<void> {
		try {
			dispatch(isSavingEvent(true));

			// if optimistic, update the working event and the event name in the events list before saving
			if (optimistic) {
				batch(() => {
					dispatch({
						type: UPDATE_WORKING_EVENT,
						payload: event
					});
					dispatch({
						type: UPDATE_EVENTS_NAME,
						payload: event
					});
				});
				await SaveEvent(event, token);

				batch(() => {
					dispatch(isSavingEvent(false));
					dispatch(setShowEditorLoader(false));
				});
				// if not optimistic, save the event first then update the working event and the event name in the events list
			} else {
				await SaveEvent(event, token);
				batch(() => {
					dispatch({
						type: UPDATE_WORKING_EVENT,
						payload: event
					});
					dispatch({
						type: UPDATE_EVENTS_NAME,
						payload: event
					});
					dispatch(isSavingEvent(false));
					dispatch(setShowEditorLoader(false));
				});
			}
			cb?.();
		} catch (e) {
			console.error(e);
			dispatch(setShowEditorLoader(false));

			if (!cb) {
				dispatch(isSavingEvent(false));
			}

			cb?.(e, (event: BrandliveEvent) => {
				batch(() => {
					dispatch({
						type: UPDATE_WORKING_EVENT,
						payload: event
					});
					dispatch({
						type: UPDATE_EVENTS_NAME,
						payload: event
					});
					dispatch(isSavingEvent(false));

					dispatch(setShowEditorLoader(false));
				});
			});
			showAlert({
				message: "Update failed",
				description: 'There was an error updating the event. Please refresh your page try again.',
				duration: 7000,
				type: "error"
			});
		}
	};
}

export const MODULE_LAYOUT_UPDATE = 'MODULE_LAYOUT_UPDATE';
type moduleLayoutUpdateAction = DispatchedAction<typeof MODULE_LAYOUT_UPDATE, null>;
export function moduleLayoutUpdate(): moduleLayoutUpdateAction {
	return {
		type: MODULE_LAYOUT_UPDATE,
		payload: null,
	};
}

export const ADD_WORKING_EVENT_HOMEPAGE = "ADD_WORKING_EVENT_HOMEPAGE";
type addWorkingEventHomepageAction = DispatchedAsyncAction<typeof ADD_WORKING_EVENT_HOMEPAGE, EventHomepage>;
export function addWorkingEventHomepage(uuid: string, token: string, mulitpleSessions?: 'multi'): addWorkingEventHomepageAction {
	return {
		type: ADD_WORKING_EVENT_HOMEPAGE,
		promise: AddHomepageToEvent(uuid, token, mulitpleSessions),
	};
}

export const IS_SAVING = "IS_SAVING";
type isSavingEventAction = DispatchedAction<typeof IS_SAVING, boolean>;
export function isSavingEvent(isSaving: boolean): isSavingEventAction {
	return {
		type: IS_SAVING,
		payload: isSaving
	};
}

export const SET_AGENDA_IMAGE = "SET_AGENDA_IMAGE";
type setAgendaImageAction = DispatchedAction<typeof SET_AGENDA_IMAGE, { url: string | null, session: number }>;
export function setAgendaImage(url: string | null, session: number): setAgendaImageAction {
	return {
		type: SET_AGENDA_IMAGE,
		payload: { url, session }
	};
}

export const PUBLISH_EVENT = "PUBLISH_EVENT";
type publishEventAction = DispatchedAsyncAction<typeof PUBLISH_EVENT, { uuid: string }>;
export function publishEvent(token: string, uuid: string, url: string): publishEventAction {
	return {
		type: PUBLISH_EVENT,
		promise: PublishEvent(token, uuid, url)
	};
}

export const PUBLISH_EVENT_PREVIEW = "PUBLISH_EVENT_PREVIEW";
type publishEventPreviewAction = DispatchedAsyncAction<typeof PUBLISH_EVENT_PREVIEW, { url: string, uuid: string }>;
export function publishEventPreview(token: string, preview_event_uuid: string, base_event_uuid: string, url: string): publishEventPreviewAction {
	return {
		type: PUBLISH_EVENT_PREVIEW,
		promise: PublishEventPreview(token, preview_event_uuid, base_event_uuid, url)
	};
}

export const UPDATE_ALL_EVENT_SESSIONS_OVERLAY_CHAT = "UPDATE_ALL_EVENT_SESSIONS_OVERLAY_CHAT";
type updateAllEventSessionsOverlaChatAction = DispatchedAction<typeof UPDATE_ALL_EVENT_SESSIONS_OVERLAY_CHAT, boolean>;
export function updateAllEventSessionsOverlaChat(isOverlay: boolean): updateAllEventSessionsOverlaChatAction {
	return {
		type: UPDATE_ALL_EVENT_SESSIONS_OVERLAY_CHAT,
		payload: isOverlay
	};
}

export const UPDATE_ALL_EVENT_SESSIONS_LAYOUT_TABS = "UPDATE_ALL_EVENT_SESSIONS_LAYOUT_TABS";
type updateAllEventSessionsLayoutTabsAction = DispatchedAction<typeof UPDATE_ALL_EVENT_SESSIONS_LAYOUT_TABS, SessionTabsLayout>;
export function updateAllEventSessionsLayoutTabs(layout: SessionTabsLayout): updateAllEventSessionsLayoutTabsAction {
	return {
		type: UPDATE_ALL_EVENT_SESSIONS_LAYOUT_TABS,
		payload: layout
	};
}

export const UPDATE_WORKING_EVENT_SESSION = "UPDATE_WORKING_EVENT_SESSION";
type updateWorkingEventSessionAction = DispatchedAction<typeof UPDATE_WORKING_EVENT_SESSION, Session>;
export function updateWorkingEventSession(session: Session): updateWorkingEventSessionAction {
	return {
		type: UPDATE_WORKING_EVENT_SESSION,
		payload: session
	};
}

export const ADD_REGISTRATION_STEP = "ADD_REGISTRATION_STEP";
type addRegistrationStepAction = DispatchedAction<typeof ADD_REGISTRATION_STEP, RegistrationStep>;
export function addRegistrationStep(step: RegistrationStep): addRegistrationStepAction {
	return {
		type: ADD_REGISTRATION_STEP,
		payload: step
	};
}

export const ADD_TICKETING_SET = "ADD_TICKETING_SET";
type addTicketingSetAction = DispatchedAction<typeof ADD_TICKETING_SET, TicketingSet>;
export function addTicketingSet(set: TicketingSet): addTicketingSetAction {
	return {
		type: ADD_TICKETING_SET,
		payload: set
	};
}

export const REMOVE_TICKETING_SET = "REMOVE_TICKETING_SET";
type removeTicketingSetAction = DispatchedAction<typeof REMOVE_TICKETING_SET, number>;
export function removeTicketingSet(index: number): removeTicketingSetAction {
	return {
		type: REMOVE_TICKETING_SET,
		payload: index
	};
}

export const UPDATE_TICKETING_SET = "UPDATE_TICKETING_SET";
type updateTicketingSetAction = DispatchedAction<typeof UPDATE_TICKETING_SET, { set: TicketingSet, index: number }>;
export function updateTicketingSet(set: TicketingSet, index: number): updateTicketingSetAction {
	return {
		type: UPDATE_TICKETING_SET,
		payload: { set, index }
	};
}

export const GET_GLOBAL_CURRENCIES = "GET_GLOBAL_CURRENCIES";
type getGlobalCurrenciesAction = DispatchedAsyncAction<typeof GET_GLOBAL_CURRENCIES, GlobalCurrency[]>;
export function getGlobalCurrencies(token: string): getGlobalCurrenciesAction {
	return {
		type: GET_GLOBAL_CURRENCIES,
		promise: GetGlobalCurrencies(token)
	};
}

export const SET_EDITOR_SIZE = "SET_EDITOR_SIZE";
type setEditorSizeAction = DispatchedAction<typeof SET_EDITOR_SIZE, 'desktop' | 'tablet' | 'mobile'>;
export function setEditorSize(size: 'desktop' | 'tablet' | 'mobile'): setEditorSizeAction {
	return {
		type: SET_EDITOR_SIZE,
		payload: size
	};
}

export const TOGGLE_PREVIEW_MODE = "TOGGLE_PREVIEW_MODE";
type toggleEventPreviewModeAction = DispatchedAction<typeof TOGGLE_PREVIEW_MODE, EPreviewTypes | null>;
export function toggleEventPreviewMode(previewMode: EPreviewTypes | null): toggleEventPreviewModeAction {
	return {
		type: TOGGLE_PREVIEW_MODE,
		payload: previewMode
	};
}

export const SET_EVENT_CUSTOM_CSS = "SET_EVENT_CUSTOM_CSS";
type setEventCustomCssAction = DispatchedAction<typeof SET_EVENT_CUSTOM_CSS, string>;
export function setEventCustomCss(css: string): setEventCustomCssAction {
	return {
		type: SET_EVENT_CUSTOM_CSS,
		payload: css
	};
}

export const SET_EVENT_CUSTOM_COMPILED_CSS = "SET_EVENT_CUSTOM_COMPILED_CSS";
type setEventCustomCompiledCssAction = DispatchedAction<typeof SET_EVENT_CUSTOM_COMPILED_CSS, string | undefined>;
export function setEventCustomCompiledCss(css?: string): setEventCustomCompiledCssAction {
	return {
		type: SET_EVENT_CUSTOM_COMPILED_CSS,
		payload: css
	};
}

export const SET_EVENT_USE_SAFE_CSS = "SET_EVENT_USE_SAFE_CSS";
type setEventUseSafeCssAction = DispatchedAction<typeof SET_EVENT_USE_SAFE_CSS, boolean>;
export function setEventUseSafeCss(on: boolean): setEventUseSafeCssAction {
	return {
		type: SET_EVENT_USE_SAFE_CSS,
		payload: on
	};
}

export const SET_SOCIAL_SHARING = "SET_SOCIAL_SHARING";
type setSocialSharingAction = DispatchedAction<typeof SET_SOCIAL_SHARING, { image: string, description: string }>;
export function setSocialSharing(socialSharing: { image: string, description: string }): setSocialSharingAction {
	return {
		type: SET_SOCIAL_SHARING,
		payload: socialSharing
	};
}

export const GET_PUBLISHED_EVENT_URL = "GET_PUBLISHED_EVENT_URL";
type getPublishedEventUrlAction = DispatchedAsyncAction<typeof GET_PUBLISHED_EVENT_URL, { status: number, url: string }>;
export function getPublishedEventUrl(token: string, eventUuid: string): getPublishedEventUrlAction {
	return {
		type: GET_PUBLISHED_EVENT_URL,
		promise: CheckEventPublishedStatus(token, eventUuid)
	};
}

export const UPDATE_PUBLISHED_EVENT_URL = "UPDATE_PUBLISHED_EVENT_URL";
type updatePublishedUrlAction = DispatchedAction<typeof UPDATE_PUBLISHED_EVENT_URL, string>;
export function updatePublishedUrl(url: string): updatePublishedUrlAction {
	return {
		type: UPDATE_PUBLISHED_EVENT_URL,
		payload: url
	};
}

export const UPDATE_EVENT_FAVICON = "UPDATE_EVENT_FAVICON";
type updateEventFaviconAction = DispatchedAsyncAction<typeof UPDATE_EVENT_FAVICON, { favicon: string }>;
export function updateEventFavicon(token: string, url: string, event_uuid: string): updateEventFaviconAction {
	return {
		type: UPDATE_EVENT_FAVICON,
		promise: UpdateEventFavicon(token, url, event_uuid),
	};
}

export const DELETE_EVENT_FAVICON = "DELETE_EVENT_FAVICON";
type deleteEventFaviconAction = DispatchedAsyncAction<typeof DELETE_EVENT_FAVICON, null>;
export function deleteEventFavicon(token: string, event_uuid: string): deleteEventFaviconAction {
	return {
		type: DELETE_EVENT_FAVICON,
		promise: DeleteEventFavicon(token, event_uuid),
	};
}

export const UPDATE_CURRENT_POST_REGISTER_PAGE = "UPDATE_CURRENT_POST_REGISTER_PAGE";
type updateCurrentPostRegisterPageAction = DispatchedAction<typeof UPDATE_CURRENT_POST_REGISTER_PAGE, string | null>;
export function updateCurrentPostRegisterPage(page: string | null): updateCurrentPostRegisterPageAction {
	return {
		type: UPDATE_CURRENT_POST_REGISTER_PAGE,
		payload: page
	};
}

export const TOGGLE_EMAIL_SETTINGS_HIDE_BRANDING = "TOGGLE_EMAIL_SETTINGS_HIDE_BRANDING";
type toggleEmailSettingsHideBrandingAction = DispatchedAction<typeof TOGGLE_EMAIL_SETTINGS_HIDE_BRANDING, boolean>;
export function toggleEmailSettingsHideBranding(shouldHide: boolean): toggleEmailSettingsHideBrandingAction {
	return {
		type: TOGGLE_EMAIL_SETTINGS_HIDE_BRANDING,
		payload: shouldHide
	};
}

export const UPDATE_EVENT_NAVBAR = "UPDATE_EVENT_NAVBAR";
type updateEventNavbarAction = DispatchedAction<typeof UPDATE_EVENT_NAVBAR, EventNavbar>;
export function updateEventNavbar(navbar: EventNavbar): updateEventNavbarAction {
	return {
		type: UPDATE_EVENT_NAVBAR,
		payload: navbar,
	};
}

export const TOGGLE_DISPLAY_SESSION_FOOTER = "TOGGLE_DISPLAY_SESSION_FOOTER";
type toggleDisplaySessionFooterAction = DispatchedAction<typeof TOGGLE_DISPLAY_SESSION_FOOTER, boolean>;
export function toggleDisplaySessionFooter(isOn: boolean): toggleDisplaySessionFooterAction {
	return {
		type: TOGGLE_DISPLAY_SESSION_FOOTER,
		payload: isOn
	};
}

export const TOGGLE_DISPLAY_SESSIONS = "TOGGLE_DISPLAY_SESSIONS";
type toggleDisplaySessionsAction = DispatchedAction<typeof TOGGLE_DISPLAY_SESSIONS, boolean>;
export function toggleDisplaySessions(isOn: boolean): toggleDisplaySessionsAction {
	return {
		type: TOGGLE_DISPLAY_SESSIONS,
		payload: isOn
	};
}

export const TOGGLE_DISPLAY_LANDING_PAGE = "TOGGLE_DISPLAY_LANDING_PAGE";
type toggleDisplayLandingPageAction = DispatchedAction<typeof TOGGLE_DISPLAY_LANDING_PAGE, boolean>;
export function toggleDisplayLandingPage(isOn: boolean): toggleDisplayLandingPageAction {
	return {
		type: TOGGLE_DISPLAY_LANDING_PAGE,
		payload: isOn
	};
}

export const TOGGLE_DISPLAY_HOMEPAGE = "TOGGLE_DISPLAY_HOMEPAGE";
type toggleDisplayHomepageAction = DispatchedAction<typeof TOGGLE_DISPLAY_HOMEPAGE, boolean>;
export function toggleDisplayHomepage(isOn: boolean): toggleDisplayHomepageAction {
	return {
		type: TOGGLE_DISPLAY_HOMEPAGE,
		payload: isOn
	};
}

export const TOGGLE_DISPLAY_DIRECTORY = "TOGGLE_DISPLAY_DIRECTORY";
type toggleDisplayDirectoryAction = DispatchedAction<typeof TOGGLE_DISPLAY_DIRECTORY, boolean>;
export function toggleDisplayDirectory(isOn: boolean): toggleDisplayDirectoryAction {
	return {
		type: TOGGLE_DISPLAY_DIRECTORY,
		payload: isOn
	};
}

export const TOGGLE_DISPLAY_PROFILES = "TOGGLE_DISPLAY_PROFILES";
type toggleDisplayProfilesAction = DispatchedAction<typeof TOGGLE_DISPLAY_PROFILES, boolean>;
export function toggleDisplayProfiles(isOn: boolean): toggleDisplayProfilesAction {
	return {
		type: TOGGLE_DISPLAY_PROFILES,
		payload: isOn
	};
}

export const TOGGLE_PROFILE_APPEARANCE = "TOGGLE_PROFILE_APPEARANCE";
type toggleProfileAppearanceAction = DispatchedAction<typeof TOGGLE_PROFILE_APPEARANCE, ProfileDisplayType>;
export function toggleProfileAppearance(appearance: ProfileDisplayType): toggleProfileAppearanceAction {
	return {
		type: TOGGLE_PROFILE_APPEARANCE,
		payload: appearance
	};
}

export const TOGGLE_BOOKMARK_ATTENDEES = "TOGGLE_BOOKMARK_ATTENDEES";
type toggleBookmarkAttendeesAction = DispatchedAction<typeof TOGGLE_BOOKMARK_ATTENDEES, boolean>;
export function toggleBookmarkAttendees(isOn: boolean): toggleBookmarkAttendeesAction {
	return {
		type: TOGGLE_BOOKMARK_ATTENDEES,
		payload: isOn
	};
}

export const TOGGLE_FILTERS = "TOGGLE_FILTERS";
type toggleFiltersAction = DispatchedAction<typeof TOGGLE_FILTERS, boolean>;
export function toggleFilters(isOn: boolean): toggleFiltersAction {
	return {
		type: TOGGLE_FILTERS,
		payload: isOn
	};
}

export const SET_FILTERS = "SET_FILTERS";
type setFiltersAction = DispatchedAction<typeof SET_FILTERS, DirectoryFilter[]>;
export function setFilters(filters: DirectoryFilter[]): setFiltersAction {
	return {
		type: SET_FILTERS,
		payload: filters
	};
}

export const TOGGLE_MESSAGING = "TOGGLE_MESSAGING";
type toggleMessagingAction = DispatchedAction<typeof TOGGLE_MESSAGING, boolean>;
export function toggleMessaging(isOn: boolean): toggleMessagingAction {
	return {
		type: TOGGLE_MESSAGING,
		payload: isOn
	};
}

export const ADD_CUSTOM_PAGE = "ADD_CUSTOM_PAGE";
type addCustomPageAction = DispatchedAction<typeof ADD_CUSTOM_PAGE, CustomPage>;
export function addCustomPage(page: CustomPage): addCustomPageAction {
	return {
		type: ADD_CUSTOM_PAGE,
		payload: page
	};
}

export const UPDATE_CUSTOM_PAGES = "UPDATE_CUSTOM_PAGES";
type updateCustomPagesActions = DispatchedAction<typeof UPDATE_CUSTOM_PAGES, CustomPage[]>;
export function updateCustomPages(customPages: CustomPage[]): updateCustomPagesActions {
	return {
		type: UPDATE_CUSTOM_PAGES,
		payload: customPages
	};
}

export const UPDATE_CUSTOM_PAGE = "UPDATE_CUSTOM_PAGE";
type updateCustomPageAction = DispatchedAction<typeof UPDATE_CUSTOM_PAGE, CustomPage>;
export function updateCustomPage(customPage: CustomPage): updateCustomPageAction {
	return {
		type: UPDATE_CUSTOM_PAGE,
		payload: customPage
	};
}

export const DELETE_CUSTOM_PAGE = "DELETE_CUSTOM_PAGE";
type deleteCustomPageAction = DispatchedAsyncAction<typeof DELETE_CUSTOM_PAGE, CustomPage>;
export function deleteCustomPage(customPage: CustomPage, token: string): deleteCustomPageAction {
	return {
		type: DELETE_CUSTOM_PAGE,
		promise: DeleteCustomPage(customPage, token)
	};
}

export const TOGGLE_CUSTOM_PAGE_MODULE = "TOGGLE_CUSTOM_PAGE_MODULE";
type toggleCustomPageModuleAction = DispatchedAction<typeof TOGGLE_CUSTOM_PAGE_MODULE, { pageId: number, moduleId: number, is_on: boolean }>;
export function toggleCustomPageModule(pageId: number, moduleId: number, is_on: boolean): toggleCustomPageModuleAction {
	return {
		type: TOGGLE_CUSTOM_PAGE_MODULE,
		payload: { pageId, moduleId, is_on }
	};
}

export const UPDATE_CUSTOM_PAGE_MODULE = "UPDATE_CUSTOM_PAGE_MODULE";
type updateCustomPageModuleAction = DispatchedAction<typeof UPDATE_CUSTOM_PAGE_MODULE, { pageId: number, updatedModule: PageModule }>;
export function updateCustomPageModule(pageId: number, updatedModule: PageModule): updateCustomPageModuleAction {
	return {
		type: UPDATE_CUSTOM_PAGE_MODULE,
		payload: { pageId, updatedModule },
	};
}

export const SET_ADD_MODULE_MODAL_OPEN = "SET_ADD_MODULE_MODAL_OPEN";
type setAddModuleModalOpenAction = DispatchedAction<typeof SET_ADD_MODULE_MODAL_OPEN, boolean>;
export function setAddModuleModalOpen(isOpen: boolean): setAddModuleModalOpenAction {
	return {
		type: SET_ADD_MODULE_MODAL_OPEN,
		payload: isOpen
	};
}

// ??? should we rename this since we are also using it to update whenever changes are made?
export const SET_SOCIAL_SETTINGS = "SET_SOCIAL_SETTINGS";
type setSocialSettingsAction = DispatchedAsyncAction<typeof SET_SOCIAL_SETTINGS, SocialSettings>;
export const setSocialSettings = (
	data: SocialSettings,
	eventUuid: string,
	token: string,
): AppThunk => (dispatch, getState) => {

	// TODO: add a fallback for when the api call fails
	// if the api call fails, we should revert the state back to the previous state
	// however, please keep in mind that if there are two concurrent toggles
	// and the first one fails, and the second one doesn't, we should only be reverting
	// the state of the first call. This is a bit too convoluted for an RC fix, which is what
	// this initial change is about.

	// when triggering two different toggles consecutively, the second toggle uses
	// stale data and overrides the first, so we will optimistically update the state
	// before making the api call so that redux is updated immediately and subsequent
	// updates have correct state to pull from
	const socialSettingsState = getState().CreateEventReducer?.workingEvent?.social_settings;
	if (socialSettingsState) {
		const optimisticUpdate = {
			...socialSettingsState,
			...data,
		};
		dispatch({ type: SET_SOCIAL_SETTINGS, promise: Promise.resolve(optimisticUpdate) });
	}

	return {
		type: SET_SOCIAL_SETTINGS,
		promise: SetSocialSettings(data, eventUuid, token),
	};
};

export const SET_SOCIAL_IN_STORE_ONLY = "SET_SOCIAL_IN_STORE_ONLY";
type setSocialsInStoreOnlyAction = DispatchedAction<typeof SET_SOCIAL_IN_STORE_ONLY, SocialSettings>;
export function setSocialsInStoreOnly(data: SocialSettings): setSocialsInStoreOnlyAction {
	return {
		type: SET_SOCIAL_IN_STORE_ONLY,
		payload: data
	};
}

export const RESET_REGISTRATIONS = "RESET_REGISTRATIONS";
type resetRegistrationsAction = DispatchedAsyncAction<typeof RESET_REGISTRATIONS, void>;
export function resetRegistrations(eventId: number, token: string): resetRegistrationsAction {
	return {
		type: RESET_REGISTRATIONS,
		promise: ResetRegistrations(eventId, token)
	};
}

export const TOGGLE_TOPIC_GROUP_CHATS = "TOGGLE_TOPIC_GROUP_CHATS";
type toggleTopicGroupChatsAction = DispatchedAction<typeof TOGGLE_TOPIC_GROUP_CHATS, boolean>;
export function toggleTopicGroupChats(isOn: boolean): toggleTopicGroupChatsAction {
	return {
		type: TOGGLE_TOPIC_GROUP_CHATS,
		payload: isOn
	};
}

export const CREATE_EVENT_CHAT_CHANNEL = "CREATE_EVENT_CHAT_CHANNEL";
type createEventChatChannelAction = DispatchedAction<typeof CREATE_EVENT_CHAT_CHANNEL, IEventChatChannel>;
export function createEventChatChannel(newChannel: IEventChatChannel): createEventChatChannelAction {
	return {
		type: CREATE_EVENT_CHAT_CHANNEL,
		payload: newChannel
	};
}

export const TOGGLE_RECAPTCHA = "TOGGLE_RECAPTCHA";
type toggleRecaptchaAction = DispatchedAction<typeof TOGGLE_RECAPTCHA, boolean>;
export function toggleRecaptcha(isOn: boolean): toggleRecaptchaAction {
	return {
		type: TOGGLE_RECAPTCHA,
		payload: isOn
	};
}

export const TOGGLE_OVERVIEW_CONTENT = "TOGGLE_OVERVIEW_CONTENT";
type toggleOverviewContentAction = DispatchedAction<typeof TOGGLE_OVERVIEW_CONTENT, boolean>;
export function toggleOverviewContent(isOn: boolean): toggleOverviewContentAction {
	return {
		type: TOGGLE_OVERVIEW_CONTENT,
		payload: isOn
	};
}


export const TOGGLE_SPEAKERS = "TOGGLE_SPEAKERS";
type toggleSpeakersAction = DispatchedAction<typeof TOGGLE_SPEAKERS, boolean>;
export function toggleSpeakers(isOn: boolean): toggleSpeakersAction {
	return {
		type: TOGGLE_SPEAKERS,
		payload: isOn
	};
}



export const TOGGLE_PERSONALIZED_ATTENDEE_LIST = "TOGGLE_PERSONALIZED_ATTENDEE_LIST";
type togglePersonalizedAttendeeListAction = DispatchedAction<typeof TOGGLE_PERSONALIZED_ATTENDEE_LIST, boolean>;
export function togglePersonalizedAttendeeList(isOn: boolean): togglePersonalizedAttendeeListAction {
	return {
		type: TOGGLE_PERSONALIZED_ATTENDEE_LIST,
		payload: isOn
	};
}

export const TOGGLE_SHOW_ADD_TO_CAL_BTN = "TOGGLE_SHOW_ADD_TO_CAL_BTN";
type toggleShowAddToCalBtnAction = DispatchedAction<typeof TOGGLE_SHOW_ADD_TO_CAL_BTN, boolean>;
export function toggleShowAddToCalBtn(isOn: boolean): toggleShowAddToCalBtnAction {
	return {
		type: TOGGLE_SHOW_ADD_TO_CAL_BTN,
		payload: isOn
	};
}

export const TOGGLE_DISPLAY_SESSION_LANGUAGE_DROPDOWN = "TOGGLE_DISPLAY_SESSION_LANGUAGE_DROPDOWN";
type toggleDisplaySessionLanguageDropdownAction = DispatchedAction<typeof TOGGLE_DISPLAY_SESSION_LANGUAGE_DROPDOWN, boolean>;
export function toggleDisplaySessionLanguageDropdown(isOn: boolean): toggleDisplaySessionLanguageDropdownAction {
	return {
		type: TOGGLE_DISPLAY_SESSION_LANGUAGE_DROPDOWN,
		payload: isOn
	};
}

export const TOGGLE_TRACK_MODAL = "TOGGLE_TRACK_MODAL";
type toggleAttendeeTrackModalAction = DispatchedAction<typeof TOGGLE_TRACK_MODAL, boolean>;
export function toggleAttendeeTrackModal(isOn: boolean): toggleAttendeeTrackModalAction {
	return {
		type: TOGGLE_TRACK_MODAL,
		payload: isOn
	};
}

export const SET_VOD_ORDER = "SET_VOD_ORDER";
type setVodOrderAction = DispatchedAction<typeof SET_VOD_ORDER, number[]>;
export function setVodOrder(order: number[]): setVodOrderAction {
	return {
		type: SET_VOD_ORDER,
		payload: order
	};
}

export const TOGGLE_DISPLAY_LIVE_EVENT_BANNER = "TOGGLE_DISPLAY_LIVE_EVENT_BANNER";
type toggleDisplayLiveEventBannerAction = DispatchedAction<typeof TOGGLE_DISPLAY_LIVE_EVENT_BANNER, boolean>;
export function toggleDisplayLiveEventBanner(display: boolean): toggleDisplayLiveEventBannerAction {
	return {
		type: TOGGLE_DISPLAY_LIVE_EVENT_BANNER,
		payload: display
	};
}

export const TOGGLE_CLOSE_REGISTRATION = "TOGGLE_CLOSE_REGISTRATION";
type toggleCloseRegistrationAction = DispatchedAction<typeof TOGGLE_CLOSE_REGISTRATION, boolean>;
export function toggleCloseRegistration(isOn: boolean): toggleCloseRegistrationAction {
	return {
		type: TOGGLE_CLOSE_REGISTRATION,
		payload: isOn
	};
}

export const ADMIN_TOGGLE_IN_PERSON_ATTENDEE_MODE = "ADMIN_TOGGLE_IN_PERSON_ATTENDEE_MODE";
type toggleInPersonAttendeeModeAction = DispatchedAction<typeof ADMIN_TOGGLE_IN_PERSON_ATTENDEE_MODE, boolean>;
export function toggleInPersonAttendeeMode(isOn: boolean): toggleInPersonAttendeeModeAction {
	return {
		type: ADMIN_TOGGLE_IN_PERSON_ATTENDEE_MODE,
		payload: isOn
	};
}

export const CLEAR_SCHEDULED_EMAIL_UPDATE_FAILURE = "CLEAR_SCHEDULED_EMAIL_UPDATE_FAILURE";
type clearScheduledEmailUpdateFailureAction = DispatchedAction<typeof CLEAR_SCHEDULED_EMAIL_UPDATE_FAILURE, null>;
export function clearScheduledEmailUpdateFailure(): clearScheduledEmailUpdateFailureAction {
	return {
		type: CLEAR_SCHEDULED_EMAIL_UPDATE_FAILURE,
		payload: null
	};
}

export const CREATE_SCHEDULED_EMAIL = "CREATE_SCHEDULED_EMAIL";
type createScheduledEmailAction = DispatchedAsyncAction<typeof CREATE_SCHEDULED_EMAIL, IScheduledEmail>;
export function createScheduledEmail(token: string, email: IInsertScheduledEmailRequest): createScheduledEmailAction {
	return {
		type: CREATE_SCHEDULED_EMAIL,
		promise: CreateScheduledEmail(token, email)
	};
}

export const EDIT_SCHEDULED_EMAIL = "EDIT_SCHEDULED_EMAIL";
type editScheduledEmailAction = DispatchedAsyncAction<typeof EDIT_SCHEDULED_EMAIL, IScheduledEmail | { error: string | IScheduledEmail; }>;
export function editScheduledEmail(token: string, email: IScheduledEmail): editScheduledEmailAction {
	return {
		type: EDIT_SCHEDULED_EMAIL,
		promise: EditScheduledEmail(token, email)
	};
}

export const EDIT_SCHEDULED_EMAIL_SYNC = "EDIT_SCHEDULED_EMAIL_SYNC";
type editScheduledEmailSyncAction = DispatchedAction<typeof EDIT_SCHEDULED_EMAIL_SYNC, IScheduledEmail>;
export function editScheduledEmailSync(email: IScheduledEmail): editScheduledEmailSyncAction {
	return {
		type: EDIT_SCHEDULED_EMAIL_SYNC,
		payload: email
	};
}

export const EDIT_SCHEDULED_EMAIL_IN_ADMIN = "EDIT_SCHEDULED_EMAIL_IN_ADMIN";
type editScheduledEmailInAdminAction = DispatchedAction<typeof EDIT_SCHEDULED_EMAIL_IN_ADMIN, IScheduledEmail>;
export function editScheduledEmailInAdmin(email: IScheduledEmail): editScheduledEmailInAdminAction {
	return {
		type: EDIT_SCHEDULED_EMAIL_IN_ADMIN,
		payload: email
	};
}

export const DELETE_SCHEDULED_EMAIL = "DELETE_SCHEDULED_EMAIL";
type deleteScheduledEmailAction = DispatchedAsyncAction<typeof DELETE_SCHEDULED_EMAIL, IScheduledEmail>;
export function deleteScheduledEmail(token: string, scheduleUuid: IScheduledEmail['uuid']): deleteScheduledEmailAction {
	return {
		type: DELETE_SCHEDULED_EMAIL,
		promise: DeleteScheduledEmail(token, scheduleUuid)
	};
}

export const GET_EVENT_SCHEDULED_EMAILS = "GET_EVENT_SCHEDULED_EMAILS";
type getEventScheduledEmailsAction = DispatchedAsyncAction<typeof GET_EVENT_SCHEDULED_EMAILS, IScheduledEmail[]>;
export function getEventScheduledEmails(token: string, event_uuid: string): getEventScheduledEmailsAction {
	return {
		type: GET_EVENT_SCHEDULED_EMAILS,
		promise: GetEventScheduledEmails(token, event_uuid)
	};
}

export const GET_CHANNEL_SCHEDULED_EMAILS = "GET_CHANNEL_SCHEDULED_EMAILS";
type getChannelScheduledEmailsAction = DispatchedAsyncAction<typeof GET_CHANNEL_SCHEDULED_EMAILS, IScheduledEmail[]>;
export function getChannelScheduledEmails(token: string, channel: number): getChannelScheduledEmailsAction {
	return {
		type: GET_CHANNEL_SCHEDULED_EMAILS,
		promise: GetChannelScheduledEmails(token, channel)
	};
}

export const CLEAR_CHANNEL_SCHEDULED_EMAILS = "CLEAR_CHANNEL_SCHEDULED_EMAILS";
type clearChannelScheduledEmailsAction = DispatchedAction<typeof CLEAR_CHANNEL_SCHEDULED_EMAILS, null>;
export function clearChannelScheduledEmails(): clearChannelScheduledEmailsAction {
	return {
		type: CLEAR_CHANNEL_SCHEDULED_EMAILS,
		payload: null
	};
}

export const SET_DISPLAY_SCHEDULED_EMAIL = "SET_DISPLAY_SCHEDULED_EMAIL";
type setDisplayScheduledEmailAction = DispatchedAction<typeof SET_DISPLAY_SCHEDULED_EMAIL, string>;
export function setDisplayScheduledEmail(scheduledEmailUuid: IScheduledEmail['uuid']): setDisplayScheduledEmailAction {
	return {
		type: SET_DISPLAY_SCHEDULED_EMAIL,
		payload: scheduledEmailUuid
	};
}

export const CLEAR_SCHEDULED_EMAILS = "CLEAR_SCHEDULED_EMAILS";
type clearScheduledEmailsAction = DispatchedAction<typeof CLEAR_SCHEDULED_EMAILS, null>;
export function clearScheduledEmails(): clearScheduledEmailsAction {
	return {
		type: CLEAR_SCHEDULED_EMAILS,
		payload: null
	};
}

export const TOGGLE_TRANSLATIONS_V2 = "TOGGLE_TRANSLATIONS_V2";
type toggleTranslationsV2Action = DispatchedAction<typeof TOGGLE_TRANSLATIONS_V2, boolean>;
export function toggleTranslationsV2(on: boolean): toggleTranslationsV2Action {
	return {
		type: TOGGLE_TRANSLATIONS_V2,
		payload: on
	};
}

// once it's on, it's on forever
export const TOGGLE_TRANSLATIONS_V3 = "TOGGLE_TRANSLATIONS_V3";
type enableTranslationsV3Action = DispatchedAction<typeof TOGGLE_TRANSLATIONS_V3, boolean>;
export function enableTranslationsV3(): enableTranslationsV3Action {
	return {
		type: TOGGLE_TRANSLATIONS_V3,
		payload: true
	};
}

export const SET_TRANSLATION_MANIFESTS = "SET_TRANSLATION_MANIFESTS";
type setTranslationManifestsAction = DispatchedAction<typeof SET_TRANSLATION_MANIFESTS, Record<string, string>>;
export function setTranslationManifests(manifests: Record<string, string>): setTranslationManifestsAction {
	return {
		type: SET_TRANSLATION_MANIFESTS,
		payload: manifests
	};
}

export const SET_TRANSLATIONS_UPDATING = "SET_TRANSLATIONS_UPDATING";
type setTranslationsUpdatingAction = DispatchedAction<typeof SET_TRANSLATIONS_UPDATING, { req: TranslationsUpdateRequest, currentUserId: number, forceEndUpdating?: boolean }>;
export function setTranslationsUpdating(req: TranslationsUpdateRequest, currentUserId: number, forceEndUpdating?: boolean): setTranslationsUpdatingAction {
	return {
		type: SET_TRANSLATIONS_UPDATING,
		payload: { req, currentUserId, forceEndUpdating }
	};
}

export const SETUP_EVENT_PREVIEW = "SETUP_EVENT_PREVIEW";
type setupEventPreviewAction = DispatchedAsyncAction<typeof SETUP_EVENT_PREVIEW, EventPreview>;
export function setupEventPreview(token: string, eventUuid: string, channel: number): setupEventPreviewAction {
	return {
		type: SETUP_EVENT_PREVIEW,
		promise: SetupEventPreview(token, eventUuid, channel)
	};
}

export const UPDATE_EVENT_PREVIEW_PASSWORD = "UPDATE_EVENT_PREVIEW_PASSWORD";
type updateEventPreviewPasswordAction = DispatchedAsyncAction<typeof UPDATE_EVENT_PREVIEW_PASSWORD, { password: string }>;
export function updateEventPreviewPassword(token: string, eventPreviewUuid: string): updateEventPreviewPasswordAction {
	return {
		type: UPDATE_EVENT_PREVIEW_PASSWORD,
		promise: UpdateEventPreviewPassword(eventPreviewUuid, token)
	};
}

export const SET_EVENT_TAGS = "SET_EVENT_TAGS";
type setEventTagsAction = DispatchedAction<typeof SET_EVENT_TAGS, IEventTag[]>;
export function setEventTags(tags: IEventTag[]): setEventTagsAction {
	return {
		type: SET_EVENT_TAGS,
		payload: tags
	};
}

export const TOGGLE_AUTOMATIC_SESSION_FILTER_BY_USER_LANGUAGE = "TOGGLE_AUTOMATIC_SESSION_FILTER_BY_USER_LANGUAGE";
type toggleAutomaticSessionFilterByUserLanguageAction = DispatchedAction<typeof TOGGLE_AUTOMATIC_SESSION_FILTER_BY_USER_LANGUAGE, boolean>;
export function toggleAutomaticSessionFilterByUserLanguage(isOn: boolean): toggleAutomaticSessionFilterByUserLanguageAction {
	return {
		type: TOGGLE_AUTOMATIC_SESSION_FILTER_BY_USER_LANGUAGE,
		payload: isOn
	};
}

export const UPDATE_CHANNEL_ICON = "UPDATE_CHANNEL_ICON";
type updateChannelIconAction = DispatchedAction<typeof UPDATE_CHANNEL_ICON, ChannelCustomizedIcon[]>;
export function updateChannelIcon(channelIcons: ChannelCustomizedIcon[]): updateChannelIconAction {
	return {
		type: UPDATE_CHANNEL_ICON,
		payload: channelIcons
	};
}

export const SET_EMAIL_LINK_LIMIT = "SET_EMAIL_LINK_LIMIT";
type setEmailLinkLimitAction = DispatchedAction<typeof SET_EMAIL_LINK_LIMIT, number | null>;
export function setEmailLinkLimit(limit: number | null): setEmailLinkLimitAction {
	return {
		type: SET_EMAIL_LINK_LIMIT,
		payload: limit
	};
}

export const UPDATE_SESSION_CUSTOM_URL = "UPDATE_SESSION_CUSTOM_URL";
type updateSessionCustomUrlAction = DispatchedAction<typeof UPDATE_SESSION_CUSTOM_URL, { sessionUuid: string, enabled: boolean, url: string }>;
export function updateSessionCustomUrl(sessionUuid: string, enabled: boolean, url: string): updateSessionCustomUrlAction {
	return {
		type: UPDATE_SESSION_CUSTOM_URL,
		payload: { sessionUuid, enabled, url }
	};
}

export const SET_ACTIVE_ACCORDION_CONTAINER = "SET_ACTIVE_ACCORDION_CONTAINER";
type setActiveAccordionContainerAction = DispatchedAction<typeof SET_ACTIVE_ACCORDION_CONTAINER, UniqueIdentifier | null>;
export function setActiveAccordionContainer(containerId: UniqueIdentifier | null): setActiveAccordionContainerAction {
	return {
		type: SET_ACTIVE_ACCORDION_CONTAINER,
		payload: containerId
	};
}

export const ADD_LEADERBOARD_NAV_ITEM = 'ADD_LEADERBOARD_NAV_ITEM';
type addLeaderboardNavItemAction = DispatchedAsyncAction<typeof ADD_LEADERBOARD_NAV_ITEM, IHomepageMainNavItems>;
export function addLeaderboardNavItem(token: string, eventUuid: string, homepageId: number): addLeaderboardNavItemAction {
	return {
		type: ADD_LEADERBOARD_NAV_ITEM,
		promise: AddLeaderboardNavItem(token, eventUuid, homepageId)
	};
}

export const UPDATE_SESSION_FEEDBACK_SURVEY_TEXT = "UPDATE_SESSION_FEEDBACK_SURVEY_TEXT";
type updateSessionFeedbackSurveyTextAction = DispatchedAsyncAction<typeof UPDATE_SESSION_FEEDBACK_SURVEY_TEXT, IFeedbackSurveyText>;
export function updateSessionFeedbackSurveyText(token: string, eventUuid: string, feedbackSurveyText: IFeedbackSurveyText, languages: string[]): updateSessionFeedbackSurveyTextAction {
	return {
		type: UPDATE_SESSION_FEEDBACK_SURVEY_TEXT,
		promise: UpdateSessionFeedbackSurveyText(token, eventUuid, feedbackSurveyText, languages)
	};
}

export const SET_MARKETING_OPT_IN_TYPE = "SET_MARKETING_OPT_IN_TYPE";
type setMarketingOptInTypeAction = DispatchedAction<typeof SET_MARKETING_OPT_IN_TYPE, MarketingOptInTypes>;
export function setMarketingOptInType(type: MarketingOptInTypes): setMarketingOptInTypeAction {
	return {
		type: SET_MARKETING_OPT_IN_TYPE,
		payload: type
	};
}

export const GET_TEMPLATE_EVENTS = 'GET_TEMPLATE_EVENTS';
type getTemplateEventsAction = DispatchedAsyncAction<typeof GET_TEMPLATE_EVENTS, { events: GetEventsResponse[], totals: EventsTotals, error?: string }>;
export function getTemplateEvents(
	activeChannel: number,
	token: string,
	page?: number,
	pageCount: number = TEMPLATE_EVENTS_PAGE_COUNT,
	search?: string,
	sort: EventListSortType = EventListSortType.alphaAsc,
	selectedEventGroupUuid = ''
): getTemplateEventsAction {
	return {
		type: GET_TEMPLATE_EVENTS,
		promise: GetEvents(
			activeChannel,
			token,
			pageCount,
			Math.max((page || 0) - 1, 0) * pageCount,
			sort,
			search,
			selectedEventGroupUuid,
			undefined,
			true
		),
	};
}

export const CLEAR_TEMPLATE_EVENTS_ERROR = 'CLEAR_TEMPLATE_EVENTS_ERROR';
type clearTemplateEventsErrorAction = DispatchedAction<typeof CLEAR_TEMPLATE_EVENTS_ERROR, null>;
export function clearTemplateEventsError(): clearTemplateEventsErrorAction {
	return {
		type: CLEAR_TEMPLATE_EVENTS_ERROR,
		payload: null
	};
}

export const RESET_TEMPLATE_EVENTS = 'RESET_TEMPLATE_EVENTS';
type resetTemplateEventsAction = DispatchedAction<typeof RESET_TEMPLATE_EVENTS, null>;
export function resetTemplateEvents(): resetTemplateEventsAction {
	return {
		type: RESET_TEMPLATE_EVENTS,
		payload: null
	};
}

export const RESET_FETCH_EVENT_LOADING_STATUS = 'RESET_FETCH_EVENT_LOADING_STATUS';
type resetFetchEventTemplatesLoadingStatusAction = DispatchedAction<typeof RESET_FETCH_EVENT_LOADING_STATUS, null>;
export function resetFetchEventTemplatesLoadingStatus(): resetFetchEventTemplatesLoadingStatusAction {
	return {
		type: RESET_FETCH_EVENT_LOADING_STATUS,
		payload: null
	};
}

export const SET_LIVE_SESSION_IDS = 'SET_LIVE_SESSION_IDS';
type setLiveSessionIdsAction = DispatchedAction<typeof SET_LIVE_SESSION_IDS, { sessionUUID: string; isLive: boolean; }>;
export function setLiveSessionIds(data: { sessionUUID: string; isLive: boolean; }): setLiveSessionIdsAction {
	return {
		type: SET_LIVE_SESSION_IDS,
		payload: data
	};
}

export const UPDATE_SESSION_TITLE_AND_TRACKS = "UPDATE_SESSION_TITLE_AND_TRACKS";
type updateSessionsTracksAction = DispatchedAsyncAction<typeof UPDATE_SESSION_TITLE_AND_TRACKS, { [sessionUUID: string]: IUpdateSessionsTracks }>;
export function updateSessionsTracks(
	token: string,
	eventUUID: string,
	data: { [sessionUUID: string]: IUpdateSessionsTracks }
): updateSessionsTracksAction {
	return {
		type: UPDATE_SESSION_TITLE_AND_TRACKS,
		promise: UpdateSessionsTracks(token, eventUUID, data)
	};
}

export const EDIT_CHANNEL_TRACKS = "EDIT_CHANNEL_TRACKS";
type editChannelTracksAction = DispatchedAsyncAction<typeof EDIT_CHANNEL_TRACKS, { [key: string]: string }>;
export function editChannelTracks(
	token: string,
	activeChannel: number,
	trackEdits: { [key: string]: string }
): editChannelTracksAction {
	return {
		type: EDIT_CHANNEL_TRACKS,
		promise: EditChannelTracks(activeChannel, trackEdits, token)
	};
}

export const SET_EVENT_IMAGE = "SET_EVENT_IMAGE";
type setEventImageAction = DispatchedAction<typeof SET_EVENT_IMAGE, string>;
export function setEventImage(url: string): setEventImageAction {
	return {
		type: SET_EVENT_IMAGE,
		payload: url
	};
}

export const SET_EVENT_TYPE = "SET_EVENT_TYPE";
type setEventTypeAction = DispatchedAction<typeof SET_EVENT_TYPE, EventType>;
export function setEventType(type: EventType): setEventTypeAction {
	return {
		type: SET_EVENT_TYPE,
		payload: type
	};
}

export const SET_EVENT_DESCRIPTION = "SET_EVENT_DESCRIPTION";
type setEventDescriptionAction = DispatchedAction<typeof SET_EVENT_DESCRIPTION, string>;
export function setEventDescription(description: string): setEventDescriptionAction {
	return {
		type: SET_EVENT_DESCRIPTION,
		payload: description
	};
}

export const SET_IS_TEST_EVENT = "SET_IS_TEST_EVENT";
type setIsTestEventAction = DispatchedAction<typeof SET_IS_TEST_EVENT, boolean>;
export function setIsTestEvent(isTestEvent: boolean): setIsTestEventAction {
	return {
		type: SET_IS_TEST_EVENT,
		payload: isTestEvent
	};
}

export const SET_EVENT_DEFAULT_LANGUAGE = "SET_EVENT_DEFAULT_LANGUAGE";
type setEventDefaultLanguageAction = DispatchedAction<typeof SET_EVENT_DEFAULT_LANGUAGE, Language>;
export function setEventDefaultLanguage(lang: Language): setEventDefaultLanguageAction {
	return {
		type: SET_EVENT_DEFAULT_LANGUAGE,
		payload: lang
	};
}

export const UPDATE_EVENT_TYPE = "UPDATE_EVENT_TYPE";
type updateEventTypeAction = DispatchedAsyncAction<typeof UPDATE_EVENT_TYPE, EventType>;
export function updateEventType(
	eventUuid: string,
	eventType: EventType,
	token: string
): updateEventTypeAction {
	return {
		type: UPDATE_EVENT_TYPE,
		promise: UpdateEventType(eventUuid, eventType, token)
	};
}

export const UPDATE_EVENT_IMAGE = "UPDATE_EVENT_IMAGE";
type updateEventImageAction = DispatchedAsyncAction<typeof UPDATE_EVENT_IMAGE, string>;
export function updateEventImage(
	token: string,
	eventUuid: string,
	url: string
): updateEventImageAction {
	return {
		type: UPDATE_EVENT_IMAGE,
		promise: UpdateEventImage(token, eventUuid, url)
	};
}

export const UPDATE_EVENT_PUBLISH_SETTINGS = "UPDATE_EVENT_PUBLISH_SETTINGS";
type updateEventPublishSettingsAction = DispatchedAsyncAction<typeof UPDATE_EVENT_PUBLISH_SETTINGS, { is_publish_locked: boolean; dma_id?: string | null; }>;
export function updateEventPublishSettings(
	token: string,
	eventUuid: string,
	isLocked: boolean,
	dmaID: string | null
): updateEventPublishSettingsAction {
	return {
		type: UPDATE_EVENT_PUBLISH_SETTINGS,
		promise: UpdateEventPublishSettings(token, eventUuid, isLocked, dmaID)
	};
}

export const UPDATE_SELECTED_EVENT_SETTINGS = "UPDATE_SELECTED_EVENT_SETTINGS";
type updateSelectedEventSettingsAction = DispatchedAction<typeof UPDATE_SELECTED_EVENT_SETTINGS, EventSettings>;
export function updateSelectedEventSettings(settings: EventSettings): updateSelectedEventSettingsAction {
	return {
		type: UPDATE_SELECTED_EVENT_SETTINGS,
		payload: settings
	};
}

// This is just a global type of variable to let other components (i.e. modals) know that the user has a dropdown (or similar)
// open and to disable activity in other components (if needed)
export const SET_A_DROPDOWN_IS_OPEN = "SET_A_DROPDOWN_IS_OPEN";
type setADropdownIsOpenAction = DispatchedAction<typeof SET_A_DROPDOWN_IS_OPEN, boolean>;
export function setADropdownIsOpen(isOpen: boolean): setADropdownIsOpenAction {
	return {
		type: SET_A_DROPDOWN_IS_OPEN,
		payload: isOpen
	};
}

export const LOCK_EVENT_SAVE = "LOCK_EVENT_SAVE";
type lockEventSaveAction = DispatchedAction<typeof LOCK_EVENT_SAVE, boolean>;
export function lockEventSave(): lockEventSaveAction {
	return {
		type: LOCK_EVENT_SAVE,
		payload: true
	};
}

export const RELEASE_EVENT_SAVE_LOCK = "RELEASE_EVENT_SAVE_LOCK";
type releaseEventSaveLockAction = DispatchedAction<typeof RELEASE_EVENT_SAVE_LOCK, boolean>;
export function releaseEventSaveLock(): releaseEventSaveLockAction {
	return {
		type: RELEASE_EVENT_SAVE_LOCK,
		payload: true
	};
}

export const SESSION_LANGUAGES_UPDATED = "SESSION_LANGUAGES_UPDATED";
type sessionLanguagesUpdatedAction = DispatchedAction<typeof SESSION_LANGUAGES_UPDATED, [number, LanguagesAbbr[]]>;
export function sessionLanguagesUpdated(sessionId: number, langs: LanguagesAbbr[]): sessionLanguagesUpdatedAction {
	return {
		type: SESSION_LANGUAGES_UPDATED,
		payload: [sessionId, langs]
	};
}

export const GET_RSVP_EVENT_LINK = "GET_RSVP_EVENT_LINK";
type getRSVPEventLinkAction = DispatchedAsyncAction<typeof GET_RSVP_EVENT_LINK, { rsvpLink: RSVPLinkSync }>;
export function getRSVPEventLink(token: string, eventUuid: string): getRSVPEventLinkAction {
	return {
		type: GET_RSVP_EVENT_LINK,
		promise: GetRSVPEventLink(token, eventUuid)
	};
}

export const APPROVE_RSVP_EVENT_LINK = "APPROVE_RSVP_EVENT_LINK";
type approveRSVPEventLinkAction = DispatchedAsyncAction<typeof APPROVE_RSVP_EVENT_LINK, RSVPLinkSync>;
export function approveRSVPEventLink(token: string, linkGroup: string, eventId: number): approveRSVPEventLinkAction {
	return {
		type: APPROVE_RSVP_EVENT_LINK,
		promise: ApproveRSVPEventLink(token, linkGroup, eventId)
	};
}

export const SET_ADMIN_HEADER_HEIGHT = 'SET_ADMIN_HEADER_HEIGHT';
type setAdminHeaderHeightAction = DispatchedAction<typeof SET_ADMIN_HEADER_HEIGHT, number>;
export function setAdminHeaderHeight(height = 72): setAdminHeaderHeightAction {
	return {
		type: SET_ADMIN_HEADER_HEIGHT,
		payload: height
	};
}

export const SET_ADMIN_SUB_PANEL_HEADER_HEIGHT = 'SET_ADMIN_SUB_PANEL_HEADER_HEIGHT';
type setAdminSubPanelHeaderHeightAction = DispatchedAction<typeof SET_ADMIN_SUB_PANEL_HEADER_HEIGHT, number>;
export function setAdminSubPanelHeaderHeight(height = 82): setAdminSubPanelHeaderHeightAction {
	return {
		type: SET_ADMIN_SUB_PANEL_HEADER_HEIGHT,
		payload: height
	};
}

export const SET_EDITING_NAVBAR = "SET_EDITING_NAVBAR";
type setEditingNavbarAction = DispatchedAction<typeof SET_EDITING_NAVBAR, boolean>;
export function setEditingNavbar(isEditing: boolean): setEditingNavbarAction {
	return {
		type: SET_EDITING_NAVBAR,
		payload: isEditing
	};
}

export const SET_TRANASLATION_LOCK = "SET_TRANASLATION_LOCK";
type setTranslationLockAction = DispatchedAction<typeof SET_TRANASLATION_LOCK, boolean>;
export function setTranslationLock(isLocked: boolean): setTranslationLockAction {
	return {
		type: SET_TRANASLATION_LOCK,
		payload: isLocked
	}
}

export const UPDATE_WORKING_THEME_PACK = "UPDATE_WORKING_THEME_PACK";
type IUpdateWorkingThemePack = { themePack?: ThemePack | null, mode?: EPaletteModes | null; };
type updateWorkingThemePackAction = DispatchedAsyncAction<typeof UPDATE_WORKING_THEME_PACK, IUpdateWorkingThemePack>;
export function updateWorkingThemePack(data: IUpdateWorkingThemePack, updateComplete: (() => void)): updateWorkingThemePackAction {
	return {
		type: UPDATE_WORKING_THEME_PACK,
		promise: Promise.resolve(data),
		meta: {
			updateComplete,
		},
	};
}

export const INC_CHECK_THEME_PACK_PROGRESS_COUNT = "INC_CHECK_THEME_PACK_PROGRESS_COUNT";
type incCheckThemePackProgressCountAction = DispatchedAction<typeof INC_CHECK_THEME_PACK_PROGRESS_COUNT, number | undefined>;
export function incCheckThemePackProgressCount(resetNum?: number): incCheckThemePackProgressCountAction {
	return {
		type: INC_CHECK_THEME_PACK_PROGRESS_COUNT,
		payload: resetNum,
	};
}

export const DISABLE_PUBLISH_BUTTON = "DISABLE_PUBLISH_BUTTON";
type setDisablePublishButtonAction = DispatchedAction<typeof DISABLE_PUBLISH_BUTTON, boolean>;
export function setDisablePublishButton(disabled: boolean): setDisablePublishButtonAction {
	return {
		type: DISABLE_PUBLISH_BUTTON,
		payload: disabled,
	};
}

export const SHOW_EDITOR_LOADER = "SHOW_EDITOR_LOADER";
type setShowEditorLoaderAction = DispatchedAction<typeof SHOW_EDITOR_LOADER, boolean>;
export function setShowEditorLoader(show: boolean): setShowEditorLoaderAction {
	return {
		type: SHOW_EDITOR_LOADER,
		payload: show,
	};
}

export const SET_SESSION_PLACEHOLDER_VIDEO = "SET_SESSION_PLACEHOLDER_VIDEO";
type setSessionPlaceholderVideoAction = DispatchedAction<typeof SET_SESSION_PLACEHOLDER_VIDEO, SessionPlaybackVideo>;
export function setSessionPlaceholderVideo(video: SessionPlaybackVideo): setSessionPlaceholderVideoAction {
	return {
		type: SET_SESSION_PLACEHOLDER_VIDEO,
		payload: video,
	};
}

export const ADMIN_ADD_SESSION_VIDEOS_REPLAY = "ADD_SESSION_VIDEOS_REPLAY";
type AdminAddSessionVideosReplay = DispatchedAction<typeof ADMIN_ADD_SESSION_VIDEOS_REPLAY, SessionPlaybackVideo>;
export function adminAddSessionVideosReplay(video: SessionPlaybackVideo): AdminAddSessionVideosReplay {
	return {
		type: ADMIN_ADD_SESSION_VIDEOS_REPLAY,
		payload: video
	};
}

export const ADMIN_ADD_SESSION_VIDEOS_LIVE = "ADD_SESSION_VIDEOS_LIVE";
type AdminAddSessionVideosLive = DispatchedAction<typeof ADMIN_ADD_SESSION_VIDEOS_LIVE, SessionPlaybackVideo>;
export function adminAddSessionVideosLive(video: SessionPlaybackVideo): AdminAddSessionVideosLive {
	return {
		type: ADMIN_ADD_SESSION_VIDEOS_LIVE,
		payload: video
	};
}

export const ADMIN_DELETE_SESSION_VIDEOS_LIVE = "DELETE_SESSION_VIDEOS_LIVE";
type AdminDeleteSessionVideosLive = DispatchedAction<typeof ADMIN_DELETE_SESSION_VIDEOS_LIVE, { sessionUuid: string, language: string }>;
export function adminDeleteLiveSessionVideo(sessionUuid: string, language: string): AdminDeleteSessionVideosLive {
	return {
		type: ADMIN_DELETE_SESSION_VIDEOS_LIVE,
		payload: { sessionUuid, language }
	};
}

export type CreateEventActions =
	| createNewEventAction
	| setEventNameAction
	| loadTemplatesAction
	| addEventTemplateAction
	| setEventTemplateAction
	| updateEventTemplateAction
	| removeEventCustomThemesAction
	| removeEventCustomTemplatesAction
	| isUpdatingEventCustomTemplatesAction
	| createTemplateEventAction
	| createNewSessionAction
	| addNewSessionAction
	| duplicateSessionAction
	| deleteSessionAction
	| removeEventFromCreateEventReducerAction
	| setWorkingEventAction
	| setSelectedEventAction
	| clearSelectedEventAction
	| clearWorkingEventAction
	| clearPublishedUrlAction
	| modalInEditorAction
	| blurEditorAction
	| loadWorkingEventAction
	| abortWorkingEventLoadAction
	| setRegistrationOnAction
	| updateRegistrationSettingsAction
	| removeRegistrationRequirementAction
	| updateRegistrationRequirementAction
	| setRegistrationFeatureAction
	| setCustomPasscodeLabelAction
	| setRejectedPasscodeMessageAction
	| setRegistrationSingleSignOnAction
	| checkRegistrationSingleSignOnAction
	| setRegistrationSSODisplayTopAction
	| setRegistrationSingleSignOnTypesAction
	| toggleRegistrationQuestionRequiredAction
	| setRegistrationQuestionsAction
	| chooseRegistrationStepAction
	| updateRegistrationStepsAction
	| addNewFontPackAction
	| deleteFontsAction
	| loadColorPacksAction
	| setSelectedColorAction
	| updateEmailColorAction
	| updateEmailFontAction
	| addNewColorPackAction
	| saveColorPackAction
	| deleteColorsAction
	| setDesignStyleAction
	| setDesignColorsAction
	| setCustomCSSAction
	| toggleHomepageModuleAction
	| togglePostRegisterPreviewHomeModuleAction
	| setHomepageModulesAction
	| resetHomepageModulesAction
	| setModuleOrderAction
	| updateHomepageModuleAction
	| updateHomepageHeaderAction
	| updateHomepageMainNavAction
	| updateHomepageProfileNavAction
	| updateNavbarTypeAction
	| updateNavbarIsStickyAction
	| updateNavbarBlurLevelAction
	| updatePostRegisterHomeModulesAction
	| setBlockedDomainEmailsAction
	| updateHomepageFooterAction
	| addPageModuleAction
	| addPostRegisterHomePageModuleAction
	| setRegistrationHeaderAction
	| setRegistrationDescriptionAction
	| setRegistrationImageAction
	| setRegistrationLogoAction
	| removeInCreationProgressSessionAction
	| editSessionAgendaAction
	| updateEditingSessionByUidAction
	| clearNewEventRequestAction
	| moduleLayoutUpdateAction
	| addWorkingEventHomepageAction
	| isSavingEventAction
	| setAgendaImageAction
	| publishEventAction
	| publishEventPreviewAction
	| updateAllEventSessionsOverlaChatAction
	| updateAllEventSessionsLayoutTabsAction
	| addRegistrationStepAction
	| addTicketingSetAction
	| removeTicketingSetAction
	| updateTicketingSetAction
	| getGlobalCurrenciesAction
	| setEditorSizeAction
	| toggleEventPreviewModeAction
	| setEventCustomCssAction
	| setEventCustomCompiledCssAction
	| setEventUseSafeCssAction
	| setSocialSharingAction
	| getPublishedEventUrlAction
	| updatePublishedUrlAction
	| updateEventFaviconAction
	| deleteEventFaviconAction
	| updateCurrentPostRegisterPageAction
	| toggleEmailSettingsHideBrandingAction
	| updateEventNavbarAction
	| toggleDisplaySessionFooterAction
	| toggleDisplaySessionsAction
	| toggleDisplayLandingPageAction
	| toggleDisplayHomepageAction
	| toggleDisplayDirectoryAction
	| toggleDisplayProfilesAction
	| toggleBookmarkAttendeesAction
	| toggleFiltersAction
	| setFiltersAction
	| toggleMessagingAction
	| addCustomPageAction
	| updateCustomPagesActions
	| updateCustomPageAction
	| deleteCustomPageAction
	| toggleCustomPageModuleAction
	| updateCustomPageModuleAction
	| setAddModuleModalOpenAction
	| setSocialSettingsAction
	| setSocialsInStoreOnlyAction
	| resetRegistrationsAction
	| toggleTopicGroupChatsAction
	| createEventChatChannelAction
	| toggleRecaptchaAction
	| toggleOverviewContentAction
	| toggleSpeakersAction
	| togglePersonalizedAttendeeListAction
	| toggleShowAddToCalBtnAction
	| toggleDisplaySessionLanguageDropdownAction
	| toggleAttendeeTrackModalAction
	| setVodOrderAction
	| toggleDisplayLiveEventBannerAction
	| toggleCloseRegistrationAction
	| toggleInPersonAttendeeModeAction
	| createScheduledEmailAction
	| editScheduledEmailAction
	| editScheduledEmailInAdminAction
	| deleteScheduledEmailAction
	| getEventScheduledEmailsAction
	| getChannelScheduledEmailsAction
	| clearChannelScheduledEmailsAction
	| setDisplayScheduledEmailAction
	| clearScheduledEmailsAction
	| toggleTranslationsV2Action
	| enableTranslationsV3Action
	| setTranslationManifestsAction
	| setTranslationsUpdatingAction
	| setupEventPreviewAction
	| updateEventPreviewPasswordAction
	| setEventTagsAction
	| removeSessionFromCreateEventReducerAction
	| toggleAutomaticSessionFilterByUserLanguageAction
	| updateChannelIconAction
	| setEmailLinkLimitAction
	| updateSessionCustomUrlAction
	| setActiveAccordionContainerAction
	| addLeaderboardNavItemAction
	| updateSessionFeedbackSurveyTextAction
	| setMarketingOptInTypeAction
	| getTemplateEventsAction
	| clearTemplateEventsErrorAction
	| resetTemplateEventsAction
	| resetFetchEventTemplatesLoadingStatusAction
	| setLiveSessionIdsAction
	| updateSessionsTracksAction
	| setEventImageAction
	| setEventTypeAction
	| setEventDescriptionAction
	| setIsTestEventAction
	| setEventDefaultLanguageAction
	| updateEventTypeAction
	| updateEventPublishSettingsAction
	| setADropdownIsOpenAction
	| lockEventSaveAction
	| releaseEventSaveLockAction
	| sessionLanguagesUpdatedAction
	| getRSVPEventLinkAction
	| approveRSVPEventLinkAction
	| setAdminHeaderHeightAction
	| setAdminSubPanelHeaderHeightAction
	| setEditingNavbarAction
	| isUpdatingSettingsAction
	| setRegistrationTypeAction
	| addRegistrationRequirementAction
	| toggleCustomizeFormModalAction
	| updateRequiredQuestionsAction
	| loadFontPacksAction
	| setSelectedFontAction
	| setDesignShapeAction
	| updateWorkingEventSessionAction
	| toggleProfileAppearanceAction
	| updateSelectedEventSettingsAction
	| updateNativeRegistrationAction
	| updateWorkingEventAction
	| updateSessionAction
	| updateEventSettingsAction
	| addNewSessionToStoreAction
	| addHomepageToWorkingEventAction
	| setColorPacksAction
	| setDisablePublishButtonAction
	| setShowEditorLoaderAction
	| editChannelTracksAction
	| updateRegistrationLimitAction
	| setTranslationLockAction
	| updateWorkingThemePackAction
	| incCheckThemePackProgressCountAction
	| togglePasswordGatingAction
	| editScheduledEmailSyncAction
	| setSessionPlaceholderVideoAction
	| AdminAddSessionVideosReplay
	| AdminAddSessionVideosLive
	| AdminDeleteSessionVideosLive
	| clearScheduledEmailUpdateFailureAction
