import { v4 as uuidV4 } from 'uuid';

import { Action, AsyncAction } from '../../../types/actions';
import { deleteDocumentsFetch, fetchDocuments, fetchUstreamVideos, GetWistiaVideos, updateDocumentName } from '../../../connection/content-connection';
import { UploadAndConvertDocument } from '../../../connection/uploads';
import { BLAdmin, Template } from '../../../types/working-model';
import { DeleteCustomTemplates, DeleteCustomThemes, GetTemplates } from '../../../connection/templates';

export const SWITCH_VIEW = "SWITCH_VIEW-contentReducer";
export function switchView(view: "grid" | "list"): Action {
	return {
		type: SWITCH_VIEW,
		payload: view
	};
}

export const FILTER_CONTENT = "FILTER_CONTENT-contentReducer";
export function filterContent(fileType: number): Action {
	return {
		type: FILTER_CONTENT,
		payload: fileType
	};
}

export const SORT_CONTENT = "SORT_CONTENT-contentReducer";
export function sortContent(sortBy: string): Action {
	return {
		type: SORT_CONTENT,
		payload: sortBy
	};
}

export const SEARCH_CONTENT = "SEARCH_CONTENT-contentReducer";
export function searchContent(input: string): Action {
	return {
		type: SEARCH_CONTENT,
		payload: input
	};
}

export const CHECK_CONTENT = "CHECK_CONTENT-contentReducer";
export function checkContent(id: number | string): Action {
	return {
		type: CHECK_CONTENT,
		payload: id
	};
}
export const CLEAR_CHECKS = "CLEAR_CHECKS-contentReducer";
export const clearChecks = (): Action => ({ type: CLEAR_CHECKS, payload: null });

export const CHECK_ALL = "CHECK_ALL-contentReducer";
export const checkAll = (idList: (number | string)[]): Action => {
	return {
		type: CHECK_ALL,
		payload: idList
	};
};

//Documents
export const GET_DOCUMENTS = "GET_DOCUMENTS-contentReducer";
export function getDocuments(activeClient: number, token: string): AsyncAction {
	return {
		type: GET_DOCUMENTS,
		promise: fetchDocuments(activeClient, token)
	};
}
export const ADD_DOCUMENTS = "ADD_DOCUMENTS-contentReducer";
export function addDocuments(files: File[], user: BLAdmin, token: string): AsyncAction {
	const batchId = uuidV4();
	return {
		type: ADD_DOCUMENTS,
		promise: Promise.all(files.map(file => UploadAndConvertDocument(user, token, file, undefined, { 'batch-id': batchId })))
	};
}
export const DELETE_DOCUMENT = "DELETE_DOCUMENT-contentReducer";
export function deleteDocument(documentIDs: number[], token: string, channel: number): AsyncAction {
	return {
		type: DELETE_DOCUMENT,
		promise: deleteDocumentsFetch(documentIDs, token, channel)
	};
}
export const REMOVE_DOCUMENT_FROM_LIST = "REMOVE_DOCUMENT_FROM_LIST";
export const removeDocumentFromList = (document: number): Action => {
	return {
		type: REMOVE_DOCUMENT_FROM_LIST,
		payload: document
	};
};
export const RENAME_DOCUMENT = "RENAME_DOCUMENT-contentReducer";
export const renameDocument = (newName: string, docId: number, token: string): AsyncAction => {
	return {
		type: RENAME_DOCUMENT,
		promise: updateDocumentName(newName, docId, token)
	};
};

export const REFRESH_DOCUMENT_THUMBNAIL = "REFRESH_DOCUMENT_THUMBNAIL-contentReducer";
export const refreshDocumentThumbnail = (message: { document: number, image: string }): Action => {
	return {
		type: REFRESH_DOCUMENT_THUMBNAIL,
		payload: message
	};
};
export const CLEAR_DOCUMENTS_ERROR = 'CLEAR_DOCUMENTS_ERROR-contentReducer';
export const clearDocumentsError = (): Action => {
	return {
		type: CLEAR_DOCUMENTS_ERROR,
		payload: null
	};
};
export const CLEAR_FAILED_UPLOAD_STATUS = 'CLEAR_FAILED_UPLOAD_STATUS';
export const clearFailedUploadStatus = (): Action => {
	return {
		type: CLEAR_FAILED_UPLOAD_STATUS,
		payload: null
	};
};

// Videos
export const GET_USTREAM_VIDEOS = "GET_USTREAM_VIDEOS-contentReducer";
export function getUstreamVideos(activeClient: number, token: string): AsyncAction {
	return {
		type: GET_USTREAM_VIDEOS,
		promise: fetchUstreamVideos(activeClient, token)
	};
}

export const GET_WISTIA_VIDEOS = "GET_WISTIA_VIDEOS-contentReducer";
export function getWistiaVideos(activeClient: number, token: string): AsyncAction {
	return {
		type: GET_WISTIA_VIDEOS,
		promise: GetWistiaVideos(activeClient, token)
	};
}

// Templates
export const GET_CHANNEL_TEMPLATES = "GET_CHANNEL_TEMPLATES";
export function getChannelTemplates(channel: number, token: string): AsyncAction {
	return {
		type: GET_CHANNEL_TEMPLATES,
		promise: GetTemplates(channel, token)
	};
}

export const ADD_CONTENT_TEMPLATE = "ADD_CONTENT_TEMPLATE";
export function addContentTemplate(template: Template): Action {
	return {
		type: ADD_CONTENT_TEMPLATE,
		payload: template
	};
}

export const UPDATE_CONTENT_TEMPLATE = "UPDATE_CONTENT_TEMPLATE";
export function updateContentTemplate(template: Template): Action {
	return {
		type: UPDATE_CONTENT_TEMPLATE,
		payload: template
	};
}

export const DELETE_CUSTOM_THEMES = "DELETE_CUSTOM_THEMES";
export function deleteContentCustomThemes(token: string, channel: number, themes: number[]): AsyncAction {
	return {
		type: DELETE_CUSTOM_THEMES,
		promise: DeleteCustomThemes(token, channel, themes)
	};
}

export const DELETE_CUSTOM_TEMPLATES = "DELETE_CUSTOM_TEMPLATES";
export function deleteContentCustomTemplates(token: string, channel: number, templates: string[]): AsyncAction {
	return {
		type: DELETE_CUSTOM_TEMPLATES,
		promise: DeleteCustomTemplates(token, channel, templates)
	};
}

export const TOGGLE_NAVIGATION = "TOGGLE_NAVIGATION";
export function toggleNavigation(open: boolean): Action {
	return {
		type: TOGGLE_NAVIGATION,
		payload: open,
	};
}

export const NAVIGATION_VISIBILITY = "NAVIGATION_VISIBILITY";
export function setIsNavigationVisible(open: boolean): Action {
	return {
		type: NAVIGATION_VISIBILITY,
		payload: open,
	};
}
